login-dialog-component {
	display: block;
}
.c-modal-tabs {
	font-family: "Proxima Nova";
	text-align: left;
}
.c-modal-tabs__header {
	display: flex;
	justify-content: center;
}
.c-modal-tabs__header__item {
	width: 50%;
	padding: 15px 20px 14px;
	font-size: 16px;
	font-weight: 800;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	transition: background-color 300ms ease;
	cursor: pointer;
	color: var(--petrol);
}
.c-modal-tabs__header__item.is-full {
	width: 100%;
}
.c-modal-tabs__header__item:hover span:after {
	transform: scale(0.2, 1);
}
.c-modal-tabs__header__item.is-active a {
	text-decoration: none;
}
.c-modal-tabs__header__item.is-active span:after {
	transform: none;
}
.c-modal-tabs__header__item span {
	position: relative;
	display: block;
	padding: 2px 0;
}
.c-modal-tabs__header__item span:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: -4px;
	right: -4px;
	height: 2px;
	background-color: currentColor;
	transform: scale(0, 1);
	transition: transform 300ms ease;
	opacity: 0.5;
}
.c-modal-tabs__body {
	padding: 10px 10px 10px;
	min-height: 100px;
}
.c-modal-tabs__body.no-bottom-pad {
	padding-bottom: 0;
}
.c-modal-reset__back {
	text-align: center;
}
.c-modal-reset__link {
	text-align: right;
}
.c-modal-reset__desc {
	text-align: center;
	margin-top: -15px;
}
.c-modal-reset__desc p {
	margin-bottom: 11px;
}
