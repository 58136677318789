.c-transaction {
    position: relative;
    padding: 20px 0 20px 58px;
    min-height: 60px;
    display: block;
}
.c-transaction:not(:last-child) {
    border-bottom: solid 1px var(--greyColor);
}
.c-transaction__view {
    position: absolute;
    top: 20px;
    left: 0;
}
.c-transaction__view__link {
    position: relative;
    display: flex;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    text-decoration: none !important;
    border: 1px solid #fff;
    color: inherit;
    box-shadow: 0 0 9px -3px #000;
}
.c-transaction__view__avatar {
    width: 100%;
    height: 100%;
}
.c-transaction__caption {
    display: flex;
    font-size: 12px;
    min-height: 40px;
    color: var(--bg-text-color);
}
.c-transaction__caption a {
    color: inherit;
}
.c-transaction__inner {
    flex-grow: 1;
    padding-right: 10px;
}
.c-transaction__status {
    font-size: 14px;
}
.c-transaction__description {
    color: $casal;
    margin-top: 3px;
}
.c-transaction__description a {
    color: currentColor;
}
.c-transaction__date {
    color: var(--accent-dark-color);
    margin-top: 10px;
}
.c-transaction__currency {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.c-transaction:first-child .c-transaction__currency:before {
    top: 10px;
}
.c-transaction:last-child .c-transaction__currency:before {
    bottom: auto;
    height: 20px;
}
.c-transaction__currency b, .c-transaction__currency span {
    display: block;
    text-align: right;
    white-space: nowrap;
}
.c-transaction__currency b {
    position: relative;
    padding: 3px 0 2px 5px;
    border-radius: 5px;
    margin-right: 0;
    font-size: 13px;
    right: 0;
    font-weight: 600;
}
.c-transaction__currency b.is-green {
    color: olive-drab;
}
.c-transaction__currency b.is-charcoal {
    color: var(--bg-text-color);
}
.c-transaction__currency span {
    padding-right: 0;
    font-size: 0.8em;
    color: var(--accent-dark-color);
}
