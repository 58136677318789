$QUEST_ACTIONS_MARGIN_TOP: 2.7rem;
$QUEST_ACTIONS_MARGIN_TOP_SM: 3rem;

.c-dl-my-quests {
	display: block;
	min-height: calc(100vh - 69px);
	padding-top: 12.1rem;
	background: var(--primary-bg);
}
@media (max-width: 768px) {
	.c-dl-my-quests {
		min-height: calc(100vh - 68px);
		padding-top: 120px;
   }
}
.c-dl-my-quests__body.move-top {
	top: 4rem;
}
@media (max-width: 768px) {
	.c-dl-my-quests__body.move-top {
		top: 6rem;
   }
}
.c-dl-my-quests__list {
	padding-top: 15px;
	padding-bottom: 40px;
}
@media (max-width: 769px) {
	.c-dl-my-quests__list {
		padding-left: 0;
		padding-right: 0;
   }
}
.c-dl-my-quests__sticky {
	position: fixed;
	top: 69px;
	left: 0;
	width: 100%;
	z-index: 3;
}
@media (max-width: 768px) {
	.c-dl-my-quests__sticky {
		top: 68px;
   }
}
.c-dl-my-quests__header {
	display: block;
	background-color: $tealDarkColor;
	color: #fff;
}
.c-dl-my-quests__header.is-light-themed {
	background: $petrol-15;
	color: inherit;
	box-shadow: none;
}
@media (max-width: 769px) {
	.c-dl-my-quests__header {
		background-color: var(--accent-color);
   }
}
.c-dl-my-quests__user {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.c-dl-my-quests__user__add {
	background: #fff;
	border-radius: 5px;
	font-weight: 700;
	font-size: 16px;
	position: relative;
	padding: 7px 8px;
	color: var(--outer-space);
	text-transform: uppercase;
	text-decoration: none !important;
	min-width: 160px;
	display: flex;
	align-items: center;
}
@media (max-width: 600px) {
	.c-dl-my-quests__user__add {
		width: auto;
		min-width: auto;
   }
	.c-dl-my-quests__user__add span {
		display: none;
   }
}
.c-dl-my-quests__user__add .icon {
	width: 33px;
	height: 32px;
	margin-right: 12px;
}
@media (max-width: 600px) {
	.c-dl-my-quests__user__add .icon {
		margin-right: 0;
   }
}
.c-dl-my-quests__quest-img {
	width: 45px;
	height: 45px;
	overflow: hidden;
	border-radius: 50%;
	background-size: cover;
	background-repeat: no-repeat;
}
.c-dl-my-quests__quest-img-cont {
	margin-right: 10px;
}
@media (max-width: 600px) {
	.c-dl-my-quests__quest-img-cont {
		display: none;
   }
}
.c-dl-my-quests__profile {
	position: relative;
	padding: 10px 0 10px 90px;
	display: flex;
	align-items: center;
	font-weight: 800;
	font-size: 2.1rem;
	max-width: calc(100% - 11rem);
	text-decoration: none !important;
	color: inherit;
	top: 0;
	height: 90px;
}
.c-dl-my-quests__profile:hover {
	color: inherit;
}
@media (max-width: 769px) {
	.c-dl-my-quests__profile {
		padding: 16px 0 16px 70px;
		max-width: calc(100% - 8rem);
   }
}
.c-dl-my-quests__profile__view {
	position: absolute;
	top: 10px;
	left: 0;
	width: 70px;
	height: 70px;
	font-size: 6.4rem;
	overflow: hidden;
	border-radius: 50%;
	background-color: var(--petrol);
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
@media (max-width: 769px) {
	.c-dl-my-quests__profile__view {
		width: 55px;
		height: 55px;
		top: 18px;
   }
}
.c-dl-my-quests__profile__img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: auto;
	width: auto;
	display: block;
	max-height: 100%;
	max-width: 100%;
}
.c-dl-my-quests__profile__name {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 700;
	font-size: 30px;
	width: 100%;
}
@media (max-width: 769px) {
	.c-dl-my-quests__profile__name {
		white-space: normal;
		overflow: unset;
   }
}
.c-dl-my-quests__filter {
	display: block;
}
@media (max-width: 769px) {
	.c-dl-my-quests__filter > .c-container {
		padding-left: 0;
		padding-right: 0;
   }
}
.c-dl-my-quests__filter__parent {
	background: var(--primary-bg-secondary);
	padding: 10px 0;
	position: relative;
	justify-content: space-between;
}
.c-dl-my-quests__filter__parent::after {
	bottom: 6px;
	left: 0;
	content: '';
	height: 4px;
	z-index: 1;
	width: 100%;
	background: $secondary-bg;
	position: absolute;
}
@media (max-width: 600px) {
	.c-dl-my-quests__filter__parent::after {
		display: none;
   }
}
@media (max-width: 600px) {
	.c-dl-my-quests__filter__parent {
		flex-direction: column;
		justify-content: normal;
		padding-bottom: 0;
   }
	.c-dl-my-quests__filter__parent form {
		position: relative;
   }
	.c-dl-my-quests__filter__parent form::after {
		bottom: -3px;
		left: 0;
		content: '';
		height: 4px;
		z-index: 1;
		width: 100%;
		background: $secondary-bg;
		position: absolute;
   }
}
.c-dl-my-quests__filter__parent .search-filter-parent {
	margin-right: 50px;
}
@media (max-width: 600px) {
	.c-dl-my-quests__filter__parent .search-filter-parent {
		background: primary-bg;
		width: 100%;
		flex: 1;
		padding: 10px;
   }
}
.c-dl-my-quests__filter__parent .search-filter-parent .input-parent {
	position: relative;
}
.c-dl-my-quests__filter__parent .search-filter-parent .input-parent input {
	border-radius: 10px;
	padding-left: 30px;
	color: var(--greyColor);
	height: 36px;
	line-height: 36px;
}
@media (max-width: 600px) {
	.c-dl-my-quests__filter__parent .search-filter-parent .input-parent input {
		background: transparent;
		border: none;
   }
}
.c-dl-my-quests__filter__parent .search-filter-parent .input-parent .search-gray {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 5;
	width: 15px;
	height: 15px;
	transform: rotate(90deg);
}
.c-dl-my-quests__filter__inner {
	background: var(--primary-bg-secondary);
	display: flex;
	align-items: center;
	padding: 0 25px;
	position: relative;
}
@media (max-width: $screen-md-max) {
	.c-dl-my-quests__filter__inner {
		padding: 0 10px;
   }
}
.c-dl-my-quests__filter__item {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 0;
	vertical-align: baseline;
	font-size: 14px;
	line-height: 1;
	font-weight: 700;
	font-family: inherit;
	color: var(--bg-text-color);
	cursor: pointer;
	-webkit-appearance: none;
	appearance: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition-property: background-color, border-color, color;
	transition-duration: 200ms;
	transition-timing-function: ease;
	text-decoration: none !important;
	position: relative;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 10px;
	min-width: 80px;
}
.c-dl-my-quests__filter__item.is-active {
	color: var(--accent-color);
}
.c-dl-my-quests__filter__item.is-active::after {
	height: 4px;
	position: absolute;
	content: '';
	left: 0;
	bottom: -4px;
	background: var(--accent-color);
	width: 100%;
	z-index: 3;
}
.c-dl-my-quests__filter__item > input {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border: 0;
	padding: 0;
	margin: 0;
	visibility: hidden;
	opacity: 0;
}
.c-dl-my-quests__item {
	display: block;
	position: relative;
	width: 100%;
	cursor: pointer;
	transition: opacity 0.25s ease-in-out;
	opacity: 1;
	background: var(--primary-bg-secondary);
	padding: 0 40px;
}
@media (max-width: 540px) {
	.c-dl-my-quests__item {
		padding: 0 25px;
   }
}
@media (max-width: 769px) {
	.c-dl-my-quests__item {
		margin-bottom: 10px;
   }
}
.c-dl-my-quests__item--hidden {
	opacity: 0;
	display: none;
}
.c-dl-my-quests__item--dimmed {
	opacity: 1;
}
.c-dl-my-quests__item__inner {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	-webkit-transition: all ease 0.3s;
	padding: 20px 0 17px 0;
	border-bottom: 0.5px solid $lightGreyColor;
}
@media (max-width: 769px) {
	.c-dl-my-quests__item__inner {
		border-bottom: 0 none;
   }
}
@media (min-width: 701px) {
	.c-dl-my-quests__item__inner {
		transform: none !important;
   }
}
.c-dl-my-quests__item__inner__left {
	flex-grow: 1;
	display: flex;
	align-items: center;
}
.c-dl-my-quests__item__tools {
	height: 100%;
	display: flex;
}
@media (max-width: 700px) {
	.c-dl-my-quests__item__tools {
		display: flex;
		left: 100%;
		right: auto;
   }
}
.c-dl-my-quests__item__tools__arrows {
	margin-top: 0;
	font-size: 2.4rem;
	height: 100%;
	display: none;
	align-items: center;
	padding-left: 1.2rem;
	z-index: 2;
	width: 45px;
}
@media (max-width: 700px) {
	.c-dl-my-quests__item__tools__arrows {
		display: flex;
   }
}
.has-tools-showed .c-dl-my-quests__item__tools__arrows {
	transform: rotate(180deg);
}
.c-dl-my-quests__item__tools__arrows .icon {
	position: relative;
	animation: fadeOut 5s infinite ease-in-out 1200ms;
}
.c-dl-my-quests__item__tools__arrows .icon:nth-child(2) {
	animation-delay: 1100ms;
}
.c-dl-my-quests__item__tools__arrows .icon:nth-child(3) {
	animation-delay: 1000ms;
}
.c-dl-my-quests__item__tool {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 3rem;
	text-decoration: none;
	height: 100%;
	font-size: 1.6rem;
	position: relative;
	z-index: 1;
}
.c-dl-my-quests__item__tool span {
	font-size: 0.8em;
	margin-top: 0.5rem;
	display: none;
}
@media (max-width: 700px) {
	.c-dl-my-quests__item__tool span {
		display: block;
   }
}
@media (max-width: 700px) {
	.c-dl-my-quests__item__tool {
		width: 5rem;
		background: #609eae;
		color: #fff;
   }
}
.c-dl-my-quests__item__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 1;
}
.c-dl-my-quests__item__title {
	display: flex;
	align-items: center;
	text-decoration: none !important;
	color: inherit;
	width: 100%;
}
.c-my-quests__item.is-fully .c-dl-my-quests__item__title.c-dl-my-quests__item__title {
	width: 100%;
}
.c-dl-my-quests__item__title a {
	display: block;
	position: relative;
	font-size: 16px;
	white-space: nowrap;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 4.1rem;
	text-decoration: none !important;
	font-weight: 700;
	max-width: 97%;
	color: var(--bg-text-color);
}
.c-dl-my-quests__item__title a .icon {
	position: relative;
	top: 0.15em;
}
.c-dl-my-quests__item__title__span {
	font-size: 12px;
	font-weight: 400;
	color: var(--bg-text-color-secondary);
	justify-content: flex-start;
}
@media (max-width: 500px) {
	.c-dl-my-quests__item__title__span {
		flex-direction: column;
		align-items: flex-start;
   }
}
.c-dl-my-quests__item__title__span span {
	color: var(--greyColor);
}
.c-dl-my-quests__item__title__span__repeat-count {
	color: $greyDarkColor !important;
}
.c-dl-my-quests__item__star {
	position: relative;
	font-size: 2rem;
	margin-right: 30px;
	min-width: 2.1rem;
	z-index: 2;
}
@media (max-width: 600px) {
	.c-dl-my-quests__item__star {
		margin-right: 15px;
   }
}
.c-dl-my-quests__item__star .h-xs-loader__control {
	font-size: 1.4rem;
}
.c-dl-my-quests__item__star__inner {
	display: flex;
	justify-content: center;
	min-width: 2.1rem;
}
.c-dl-my-quests__item__statistics {
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-left: 1rem;
	z-index: 1;
}
.c-dl-my-quests__item__statistics > *:not(:last-child) {
	margin-right: 1rem;
}
.c-dl-my-quests__item__statistics__trigger {
	display: flex;
	font-size: 2rem;
	padding-left: 1rem;
	padding-right: 1rem;
	position: relative;
	z-index: 1;
}
.c-dl-my-quests__item__statistics__trigger .icon-cancel {
	font-size: 0.8em;
}
.c-dl-my-quests__item__circle {
	z-index: 2;
}
.c-dl-my-quests__item__progress__repeat {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 2.2rem;
	height: 2.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	margin-right: 0 !important;
	z-index: 2;
	background: $golden-fizz;
	border-radius: 50%;
	padding: 0;
	font-size: 1.2rem;
}
.c-dl-my-quests__item__progress__repeat.is-only-child, .c-dl-my-quests__item__progress__repeat:only-child {
	margin: 0;
}
.c-dl-my-quests__item__progress__repeat .icon {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 2.2rem;
}
.c-dl-my-quests__item__progress .c-quest-progress__inner {
	font-size: 6rem;
}
.c-dl-my-quests__item__progress .c-quest-progress__caption span {
	display: none;
}
.c-dl-my-quests__item__repeat {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $tealColor;
	padding-right: 0.4rem;
	font-weight: 600;
	font-size: 14px;
}
@media (max-width: 600px) {
	.c-dl-my-quests__item__repeat {
		font-size: 12px;
		text-align: right;
   }
}
.c-dl-my-quests__skeleton-super > li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 9rem;
	padding: 0 2.5rem 0 2.5rem;
	border-bottom: 1px solid white-30;
}
.c-dl-my-quests__skeleton-super > li:not(:last-child) {
	margin-bottom: 1rem;
}
.c-dl-my-quests__skeleton-super > li:before {
	content: '';
	width: 30%;
	height: 2rem;
	background-color: white-30;
	border-radius: 0.4rem;
}
.c-dl-my-quests__skeleton-super > li:after {
	content: '';
	width: 1em;
	height: 1em;
	font-size: 6rem;
	border-radius: 50%;
	background-color: white-30;
}
.c-dl-my-quests__skeleton-super > li:nth-child(2):before {
	width: 50%;
}
.c-dl-my-quests__skeleton-super > li:nth-child(3):before {
	width: 25%;
}
.c-dl-my-quests__skeleton-super > li:nth-child(4):before {
	width: 40%;
}
.c-dl-my-quests__empty {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: calc(100vh - 21rem);
	line-height: 1.25;
}
.c-dl-my-quests__empty.is-inline {
	height: 10rem;
}
.c-dl-my-quests__empty.is-inline .c-my-quests__empty__title {
	font-size: 1.6rem;
}
.c-dl-my-quests__empty.is-inline .c-my-quests__empty__icon {
	font-size: 7.8rem;
}
.c-dl-my-quests__empty__icon {
	font-size: 10rem;
	opacity: 0.2;
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	color: var(--petrol);
}
.c-dl-my-quests__empty__icon-friends-list {
	font-size: 9rem;
	opacity: 0.2;
	color: var(--petrol);
	padding-bottom: 0.5rem;
}
@media (max-width: $breakpoint-6s) {
	.c-dl-my-quests__empty__caption {
		max-width: 22rem;
   }
}
.c-dl-my-quests__empty__title {
	font-size: 2rem;
	text-transform: uppercase;
	margin-bottom: 1rem;
}
.c-dl-my-quests__empty__title:last-child {
	margin-bottom: 0;
}
.c-dl-my-quests__empty__cnt {
	text-transform: uppercase;
}
.c-dl-my-quests__empty__cnt .h-link {
	font-weight: 800;
}
.c-dl-my-quests__actions {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;

	@media (max-width: $screen-sm-max) {
		left: 4px;
		width: 2rem;
	}

	&__header {
	// 	width: 100%;
	// 	height: 100%;
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;

		&.dropdown-toggle {
			&::after {
				display: none;
			}
		}
	}

	&__oval {
		display: block;
		font-size: 3.5rem;
		color: $tealDarkColor;
		width: 20px;

		@media (max-width: $screen-sm-max) {
			font-size: 3.5rem;
		}
		.icon {
			font-size: 0.7em;
		}
	}

	&__body {
		// right: 100%;
		// width: 20rem;
		// margin-right: 2rem;
		// top: 1rem;
		padding: 0.5rem !important;
		border-radius: 5px !important;
		background: #fff;
		// z-index: 2;
		// display: none;
		box-shadow: 0 2px 6px -4px #000;
	}

	&__form-group {
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__icon-control {
		background: #edf7f9;
		font-size: 1.8rem;
		border: 0.2rem solid #edf7f9;
		width: 100%;
		color: #69b9cf;
		border-radius: 2rem;
		align-items: center;
		display: flex;
		padding: 0.7rem 2rem;
		line-height: 1;
		height: 4.6rem;

		&:hover {
			border: 0.2rem solid #1d728f;
		}
	}

	&__legend {
		display: flex;
		padding: 0.5rem;

		.icon {
			margin-right: 0.5em;
		}
	}
}
// .c-dl-my-quests__actions__container {
// 	display: flex;
// 	height: 100%;
// }
// .open .c-dl-my-quests__actions__body {
// 	display: block;
// }

// .open .c-dl-my-quests__actions__reverse-body {
// 	display: block;
// }
// .c-dl-my-quests__actions__inner {
// 	margin-bottom: -1.5rem;
// }

.c-dl-my-quests__banner {
	text-align: center;
	width: 100%;
	padding: 10px 40px;
	font-size: 16px;
	color: var(--outer-space);
	background: paleCyan;
	font-weight: 700;
	position: relative;
}
@media (max-width: 769px) {
	.c-dl-my-quests__banner {
		padding: 10px 25px;
		text-align: left;
   }
}
.c-dl-my-quests__banner .cancel-btn {
	position: absolute;
	right: 20px;
	cursor: pointer;
}
@media (max-width: 769px) {
	.c-dl-my-quests__banner .cancel-btn {
		top: 10px;
		right: 10px;
   }
}

