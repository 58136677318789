.pswp__user {
	position: absolute;
	left: 5px;
	top: 0;
	display: flex;
	font-size: 12px;
	height: 43px;
	align-items: center;
}
.pswp__user .c-avatar {
	font-size: 34px;
}
.pswp__user .c-avatar__inner {
	background-color: var(--petrol);
}
.pswp__user__caption {
	display: block;
	align-items: center;
	color: #fff;
	padding-left: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: calc(100vw - 240px);
}
.pswp__counter {
	left: auto;
	right: 162px;
}
