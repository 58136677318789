.h-pulse-animation {
	animation: pulse 2s infinite;
}
@-moz-keyframes rotating {
	from {
		transform: rotate(0deg);
   }
	to {
		transform: rotate(360deg);
   }
}
@-webkit-keyframes rotating {
	from {
		transform: rotate(0deg);
   }
	to {
		transform: rotate(360deg);
   }
}
@-o-keyframes rotating {
	from {
		transform: rotate(0deg);
   }
	to {
		transform: rotate(360deg);
   }
}
@keyframes rotating {
	from {
		transform: rotate(0deg);
   }
	to {
		transform: rotate(360deg);
   }
}
@-moz-keyframes pulse {
	0% {
		box-shadow: 0 0 0.3rem 0 var(--petrol);
   }
	70% {
		box-shadow: 0 0 0.3rem 1rem rgba(0,81,102,0);
   }
	100% {
		box-shadow: 0 0 0.3rem 0 rgba(0,81,102,0);
   }
}
@-webkit-keyframes pulse {
	0% {
		box-shadow: 0 0 0.3rem 0 var(--petrol);
   }
	70% {
		box-shadow: 0 0 0.3rem 1rem rgba(0,81,102,0);
   }
	100% {
		box-shadow: 0 0 0.3rem 0 rgba(0,81,102,0);
   }
}
@-o-keyframes pulse {
	0% {
		box-shadow: 0 0 0.3rem 0 var(--petrol);
   }
	70% {
		box-shadow: 0 0 0.3rem 1rem rgba(0,81,102,0);
   }
	100% {
		box-shadow: 0 0 0.3rem 0 rgba(0,81,102,0);
   }
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0.3rem 0 var(--petrol);
   }
	70% {
		box-shadow: 0 0 0.3rem 1rem rgba(0,81,102,0);
   }
	100% {
		box-shadow: 0 0 0.3rem 0 rgba(0,81,102,0);
   }
}
