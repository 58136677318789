.c-share-widjet {
    display: block;
    padding-bottom: 20px;
}
.c-share-widjet__list {
    display: block;
}
.c-share-widjet__tabs {
    display: flex;
    justify-content: left;
}
.c-share-widjet__tabs__item {
    width: 45px;
    height: 45px;
}
.c-share-widjet__tabs__item:not(:last-child) {
    margin-right: 2rem;
}
.c-share-widjet__tabs__item .icon {
    width: 45px;
    height: 45px;
}
.c-share-widjet__tabs__item__ttl {
    display: none;
}
.c-share-widjet__inner {
    padding-top: 2rem;
}
.c-share-widjet__icon {
    font-size: 3.5rem;
    background-color: var(--accent-color);
    color: #fff;
}
.c-share-widjet__icon:hover {
    background-color: anakiwa;
    color: #000;
}
.c-share-widjet__tabs__item.is-active .c-share-widjet__icon {
    background-color: #000;
    color: #fff;
}
.c-share-widjet__icon .icon-envelope_alt {
    font-size: 0.6em;
}
.c-share-widjet__icon .icon-link {
    font-size: 0.48em;
    margin-right: -0.1em;
}
.c-share-widjet__icon .icon-twitter {
    font-size: 0.7em;
    margin-right: -0.1em;
    margin-bottom: -0.1em;
}
.c-share-widjet__icon .icon-facebook {
    font-size: 0.7em;
    margin-bottom: -0.1em;
}
.c-share-widjet__icon .icon-copy {
    font-size: 0.5em;
}
.c-share-widjet__link {
    position: relative;
    min-height: 70px;
    display: flex !important;
}
.c-share-widjet__link__input {
    display: block;
    border: 0;
    margin: 0;
    width: 100%;
    line-height: 1.2;
    background: transparent;
    color: inherit;
    text-overflow: ellipsis;
    text-indent: 20px;
    padding-right: 160px;
}
.c-share-widjet__link__input:focus-visible {
    outline: none;
}
.c-share-widjet__link__trigger {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
}
.c-share-widjet__link__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    background-color: wild-sand;
    color: dusty-gray;
}
.c-share-widjet__link__icon .icon {
    font-size: 0.5em;
}
.c-share-widjet__link__icon:hover {
    background-color: anakiwa;
    color: #000;
}
.c-share-widjet__link__ttl {
    display: none;
}
.c-share-widjet__content {
    display: block;
}
.c-share-widjet__inner {
    display: none;
}
.c-share-widjet__inner.is-active {
    display: block;
    width: 100%;
}
.c-share-widjet__emails {
    display: block;
}
.c-share-widjet__email {
    margin-bottom: 2rem !important;
}
.c-share-widjet__email__delete {
    cursor: pointer;
}
.c-share-widjet__email__delete:hover {
    color: $tabasco;
}
