body .c-quest-tabs-nav {
	background: var(--accent-color);
	position: relative;
}
body .c-quest-tabs-nav__container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
}
body .c-quest-tabs-nav__container.progress-bar-display {
	padding-right: 190px;
}
@media (max-width: 769px) {
	body .c-quest-tabs-nav__container.progress-bar-display {
		padding-right: 130px;
   }
}
@media (max-width: $quest-detail-new-xs-max) {
	body .c-quest-tabs-nav__container.progress-bar-display {
		padding-right: 0;
   }
}
body .c-quest-tabs-nav .tab-name {
	display: inline-block;
}
body .c-quest-tabs-nav__list {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	justify-items: center;
	align-content: center;
	align-items: center;
	margin: 0;
	padding-left: 0;
}
@media (min-width: $quest-detail-new-sm-min) {
	body .c-quest-tabs-nav__list {
		justify-content: flex-start;
   }
}
body .c-quest-tabs-nav__list-item {
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	display: inline-block;
	position: relative;
}
body .c-quest-tabs-nav__list-item.active {
	background: #fff;
	position: relative;
}
body .c-quest-tabs-nav__list-item.active::after {
	content: '';
	position: absolute;
	background: var(--accent-color);
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0.8;
}
body .c-quest-tabs-nav__list-item.active::before {
	background: #fff;
	height: 3px;
	width: 100%;
	position: absolute;
	content: " ";
	bottom: 0;
	left: 0;
	z-index: 3;
}
@media (min-width: $quest-detail-new-sm-min) {
	body .c-quest-tabs-nav__list-item.active::before {
		width: 100%;
   }
}
body .c-quest-tabs-nav__list-item:hover {
	color: var(--theme-text-light);
	text-decoration: none;
	background: #fff;
	position: relative;
}
body .c-quest-tabs-nav__list-item:hover::after {
	content: '';
	position: absolute;
	background: var(--accent-color);
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0.8;
	top: 0;
	left: 0;
}
body .c-quest-tabs-nav__list-item .c-quest-tabs-nav__link {
	width: 100%;
	cursor: pointer;
	position: relative;
	z-index: 7;
}
body .c-quest-tabs-nav__list-item a {
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
	letter-spacing: 1.25px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	display: block;
	padding: 16px 39px;
	color: var(--theme-text-light);
	cursor: pointer;
}
body .c-quest-tabs-nav__list-item a:active {
	color: var(--theme-text-light);
}
@media (max-width: $breakpoint-xs-max) {
	body .c-quest-tabs-nav__list-item a {
		padding: 16px 15px;
		min-width: 100px;
   }
}
body .c-quest-tabs-nav__icon-list {
	list-style: none;
	display: flex;
	align-items: center;
	margin: 0 10px 0 0;
	padding: 0;
}
body .c-quest-tabs-nav__icon-list > li {
	margin-right: 10px;
	display: flex;
	height: 30px;
	width: 30px;
	border: solid 1px var(--theme-text-light);
	border-radius: 5px;
	text-align: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
body .c-quest-tabs-nav__icon-list > li.options-icon {
	position: relative;
	font-size: 14px;
	color: var(--theme-text-light);
	font-weight: 700;
	width: auto;
	padding-left: 15px;
	padding-right: 15px;

	app-quest-header-tools {
		position: absolute;
		left: 0;
		top: 24px;
		max-width: none;
		width: 160px;
	}

	> span {
		display: inline-flex;
		align-items: center;
	}
}

body .c-quest-tabs-nav__icon-list > li.options-icon > span .icon {
	margin-left: 10px;
}
@media (max-width: 650px) {
	body .c-quest-tabs-nav__icon-list > li.options-icon > span .icon {
		margin-left: 0;
   }
}
body .c-quest-tabs-nav__icon-list > li.options-icon > span .icon-down-filled {
	fill: var(--theme-text-light);
}
@media (max-width: 650px) {
	body .c-quest-tabs-nav__icon-list > li.options-icon > span .text {
		display: none;
   }
}
body .c-quest-tabs-nav__icon-list > li a {
	display: inline-flex;
}
body .c-quest-tabs-nav__icon-list > li .icon-edit-box, body .c-quest-tabs-nav__icon-list > li .icon-settings-cog {
	width: 20px;
	height: 20px;
}
