body .c-donate__title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    color: var(--bg-text-color);
}
body .c-donate__title a {
    text-decoration: none;
    color: inherit;
}
body .c-donate__title a:hover {
    color: inherit;
}
body .c-donate__content-section {
    background: var(--flow-pages-section-bg);
    padding: 40px 80px 40px 40px;
    position: relative;
    padding-bottom: 120px;
    width: 100%;
}
body .c-donate__content-section.no-button-bottom {
    padding-bottom: 50px;
}
@media (max-width: 650px) {
    body .c-donate__content-section {
        padding: 40px 20px;
        padding-bottom: 20px;
   }
}
body .c-donate__content-cont {
    flex-direction: row;
}
@media (max-width: 650px) {
    body .c-donate__content-cont {
        flex-direction: column;
        padding-bottom: 100px;
   }
}
body .c-donate__left-content {
    width: 60%;
}
@media (max-width: 650px) {
    body .c-donate__left-content {
        width: 100%;
   }
}
body .c-donate__right-content {
    width: 35%;
    background: var(--bg-white-ghost);
    color: var(--bg-text-color);
    padding: 30px;
}
@media (max-width: 650px) {
    body .c-donate__right-content {
        width: 100%;
   }
}
body .c-donate__right-content .legend {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin-top: 30px;
}
body .c-donate__right-content .infoRow {
    margin-top: 20px;
}
body .c-donate__right-content .infoRow .info {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}
body .c-donate__right-content .infoRow .info .info__label {
    width: 68%;
    font-weight: 400;
}
body .c-donate__right-content .infoRow .info .info__value {
    font-weight: 400;
    width: 28%;
    text-align: right;
}
body .c-donate__right-content .infoRow .info .total-payment {
    font-weight: 700;
}
body .c-donate__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    border-top: solid 1px $lightGreyColor;
    padding: 12px 40px;
}
@media (max-width: 650px) {
    body .c-donate__footer {
        padding: 12px 20px;
   }
}
body .c-donate__prices {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 3px;
}
body .c-donate__prices__col {
    width: 16.666666666666668%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
}
@media (max-width: $breakpoint-xs) {
    body .c-donate__prices__col {
        width: 33.333333333333336%;
   }
}
body .c-donate__price {
    display: flex;
    justify-content: center;
    text-align: center !important;
    align-items: center;
}
body .c-donate__group {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
}
body .c-donate__group__col {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 50%;
}
body .c-donate__list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}
body .c-donate__list li {
    margin-bottom: 10px;
}
body .c-donate__list li > ul {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 30px;
}
body .c-donate__list a.is-disabled {
    pointer-events: none;
    cursor: default;
}
body .c-donate__list.detail-form {
    padding-left: 10px;
}
@media (max-width: 650px) {
    body .c-donate__list.detail-form {
        margin-bottom: 20px;
   }
}
body .c-donate__list.detail-form .c-n-form__group {
    width: 100%;
}
@media (max-width: 650px) {
    body .c-donate__list.detail-form .c-n-form__group {
        flex-direction: column;
        align-items: start;
   }
}
body .c-donate__list.detail-form .c-n-form__group .e-toggle__wrap {
    width: 50%;
}
@media (max-width: 650px) {
    body .c-donate__list.detail-form .c-n-form__group .e-toggle__wrap {
        width: 100%;
   }
}
body .c-donate__list.detail-form .c-n-form__group .e-toggle__wrap.msg-cont {
    width: 100%;
}
body .c-donate__list.detail-form .c-n-form__group .e-toggle__wrap .donate-for-col {
    width: 100%;
}
body .c-donate__submit {
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
}
body .c-donate__submit.end {
    justify-content: flex-end;
}
body .c-donate__submit .c-btn {
    margin: 0;
}
@media (max-width: 650px) {
    body .c-donate__submit .c-btn {
        max-width: 50%;
        min-width: 150px;
   }
}
body .c-donate__success {
    color: var(--bg-text-color);
}
body .c-donate__success__title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    padding: 0;
    margin: 0 0 25px 0;
}
body .c-donate__success__caption strong {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}
body .c-donate__success__caption p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
}
body .c-donate__success__tools {
    margin-top: 15px;
    margin-bottom: 10px;
}
body .c-donate__success__tools__title {
    text-align: center;
    display: block;
    margin-bottom: 10px;
}
body .c-donate__success__tools__body {
    display: flex;
    justify-content: left;
    margin-bottom: 40px;
}
body .c-donate__success__tool {
    margin: 0;
    min-width: 140px;
}
body .c-donate__success__tool:not(:last-child) {
    margin-right: 20px;
}
body .c-donate__success__footer p {
    margin-bottom: 0;
}
body .c-donate__success__explore {
    margin-top: 30px;
    padding: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
@media (max-width: $breakpoint-xs) {
    body .c-donate__success__explore {
        font-size: 14px;
        padding-bottom: 20px;
   }
}
body .c-donate__success__explore b {
    margin-right: 10px;
    font-weight: 600;
}
@media (max-width: $breakpoint-xs) {
    body .c-donate__success__explore b {
        margin-right: 10px;
   }
}
.form-is-submitted body .c-donate__payDialog__input.ng-invalid ~ .c-donate__payDialog__control .c-forms__input {
    border-color: puce;
}
body .c-donate__payDialog__control {
    display: block;
}
body .c-donate__secured {
    color: var(--bg-text-color);
}
body .c-donate__icon {
    padding-top: 5px;
}
body .c-donate__stripe-powered {
    float: right;
    margin-top: -8px;
}
body .c-donate__login {
    background-color: $petrol-15;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}
body .c-donate__login__caption {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}
body .c-donate__login__ttl {
    display: block;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #29393b;
}
body .c-donate__login__desc {
    font-size: 14px;
}
body .c-donate__login__trigger {
    margin: 0;
    flex-shrink: 0;
}
@media (min-width: 769px) {
    body .c-n-modal__body {
        min-width: 100%;
        width: 100%;
   }
}
body .c-n-modal__body .donate-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
}
body .c-n-modal__body .donate-form .c-n-form__group {
    width: 45%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}
@media (max-width: 650px) {
    body .c-n-modal__body .donate-form .c-n-form__group {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
   }
}
body .c-n-modal__body .donate-form .c-n-form__group:nth-child(odd) {
    margin-left: 0;
}
body .c-n-modal__body .donate-form .c-n-form__group:nth-child(even) {
    margin-right: 0;
}
