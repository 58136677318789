.c-land-carousel {
	display: block;
	font-family: 'Proxima Nova';
	padding-top: 30px;
}
.c-land-carousel__title {
	font-size: 24px;
	text-transform: uppercase;
	font-weight: 900;
	text-align: center;
}
.c-land-carousel__body {
	display: block;
}
@media (max-width: $breakpoint-xm) {
	.c-land-carousel__body {
		margin-top: 10px;
   }
}
.c-land-carousel__inner {
	min-height: 200px;
}
.c-land-carousel__col {
	display: block;
	padding: 30px 20px;
	outline: 0 !important;
}
.c-land-carousel .slick-dots {
	display: flex;
	justify-content: center;
	align-items: center;
	list-style-type: none;
}
.c-land-carousel .slick-dots li {
	width: 1em;
	height: 1em;
	border-radius: 50%;
	font-size: 20px;
	cursor: pointer;
	transform: scale(0.5);
	transform-origin: center center;
	transition-property: transform, opacity;
	transition-timing-function: ease;
	transition-duration: 300ms;
	background-color: var(--petrol);
}
.c-land-carousel .slick-dots li:not(:last-child) {
	margin-right: 5px;
}
.c-land-carousel .slick-dots li:hover {
	transform: scale(0.92);
}
.c-land-carousel .slick-dots li.slick-active {
	transform: none;
}
.c-land-item {
	font-family: 'Proxima Nova';
	width: 700px;
	height: 350px;
	display: flex;
	background-color: rgba(255,255,255,0.3);
	transform: scale(0.9);
	transform-origin: center center;
	transition-property: transform, opacity;
	transition-timing-function: ease;
	transition-duration: 300ms;
	opacity: 0.5;
	box-shadow: 0 10px 40px -21px #000;
	cursor: pointer;
	line-height: 1.25;
}
@media (max-width: $breakpoint-xm) {
	.c-land-item {
		width: 100%;
		height: auto;
		flex-direction: column;
   }
}
.c-land-item:hover {
	transform: scale(0.92);
	opacity: 1;
	background-color: #63c1d9;
	color: #fff;
}
@media (max-width: $breakpoint-xm) {
	.c-land-item:hover {
		transform: none;
   }
}
.slick-active .c-land-item {
	transform: none;
	opacity: 1;
	cursor: default;
}
.slick-active .c-land-item:hover {
	transform: none;
}
.c-land-item__view {
	position: relative;
	width: 50%;
}
@media (max-width: $breakpoint-xm) {
	.c-land-item__view {
		width: 100%;
   }
	.c-land-item__view:after {
		content: '';
		display: block;
		height: 0;
		padding-bottom: 56.6%;
   }
}
.c-land-item__view .h-object-fit {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: smalt-blue;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.c-land-item__view__trigger {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	cursor: default;
}
.c-land-item__caption {
	width: 50%;
	padding: 30px 30px 25px;
	display: flex;
	flex-direction: column;
	background: linear-gradient(225deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 90%);
	text-decoration: none !important;
	color: inherit;
}
.c-land-item__caption:hover {
	color: inherit;
}
@media (max-width: $breakpoint-xm) {
	.c-land-item__caption {
		width: 100%;
		height: 285px;
   }
}
.c-land-item__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	color: juniper;
}
.c-land-item__category {
	display: flex;
	align-items: center;
	font-weight: 800;
	font-size: 14px;
	font-weight: 800;
}
.c-land-item__category .icon {
	margin-right: 5px;
}
.c-land-item__category span {
	margin-bottom: -0.2em;
}
.c-land-item__views {
	display: flex;
	align-items: center;
}
.c-land-item__views .icon-eye {
	font-size: 16px;
}
.c-land-item__title {
	font-size: 20px;
	font-weight: 800;
	text-transform: uppercase;
	margin-bottom: 10px;
	max-height: 60px;
	overflow: hidden;
}
@media (max-width: $breakpoint-xm) {
	.c-land-item__title {
		max-height: 40px;
   }
}
.c-land-item__desc {
	overflow: hidden;
	display: block;
	padding-bottom: 0;
	max-height: 200px;
}
@media (max-width: $breakpoint-sm) {
	.c-land-item__desc {
		max-height: 140px;
   }
}
.c-land-item__desc *:not(:last-child) {
	margin-bottom: 0;
}
.c-land-item__desc *:last-child {
	margin-bottom: 0;
}
.c-land-item__top {
	width: 100%;
	margin-bottom: 20px;
}
.c-land-item__footer {
	width: 100%;
	margin-top: auto;
	justify-content: space-between;
}
.c-land-item__details {
	text-transform: uppercase;
	display: inline-block;
	text-decoration: underline;
	font-size: 14px;
	font-weight: 800;
	float: right;
	text-align: right;
}
