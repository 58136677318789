.e-question {
	display: inline-flex;
	position: relative;
	text-decoration: none;
	font-size: 20px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	background-color: var(--tooltip-bg);
	text-align: center;
	font-style: normal;
	font-weight: 800;
	cursor: pointer;
}
.e-question:after {
	content: "?";
	font-size: 0.7em;
	color: var(--tooltip-text-color);
}
.e-help {
	display: inline-flex;
	position: relative;
	text-decoration: none;
	font-size: 20px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	background-color: currentColor;
	text-align: center;
	font-style: normal;
	font-weight: 800;
	cursor: pointer;
}
.e-help:after {
	content: "!";
	font-size: 0.7em;
	color: #fff;
}
