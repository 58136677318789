body app-profile {
    background-image: none;
    display: block;
}
body .c-profile-new {
    display: block;
}
body .c-profile-new__page {
    background-color: var(--primary-bg);
    background-image: none;
}
body .c-profile-new__container {
    background-color: var(--primary-bg-secondary);
}
body .c-profile-new__container.content-container {
    margin-top: 20px;
    border-radius: 5px 5px 0 0;
    padding-top: 40px;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__container.content-container {
        margin-top: 0;
   }
}
body .c-profile-new__header {
    position: relative;
    display: block;

     &__cover-upload-btn {
        justify-content: center;
        align-items: center;
        position: absolute;
        font-size: 12px;
        display: flex;
        cursor: pointer;
        width: auto;
        border-radius: 5px;
        right: 10px;
        bottom: 10px;
        padding: 7px 10px;
        color: var(--btn-text-light);
        
        .icon {
            fill: var(--btn-text-light);
            width: 16px;
            height: 16px;
            margin-right: 8px;
            @media (max-width: $breakpoint-xs) {
                margin-right: 0;
            }
        }
        * {
            z-index: 2;
        }
        &::before {
            content: '';
            background: var(--accent-color);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.6;
            border-radius: 5px;
        }
        .no-mobile {
            @media (max-width: $breakpoint-xs) {
                display: none;
            }
        }
    }
}
body .c-profile-new__header__photo {
    background: url("/assets/images/useful/tree-back.png") no-repeat center center;
    background-color: #1f3034;
    padding-bottom: 34.6875%;
    background-size: cover;
    margin-bottom: 50px;
    position: relative;
    height: 0;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__header__photo {
        padding-bottom: 54.5%;
        margin-bottom: 45px;
   }
}
@media (min-width: $breakpoint-md - 1px) {
    body .c-profile-new__header__photo {
        margin-bottom: 90px;
   }
}
body .c-profile-new__header__photo__bg .h-object-fit {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}
body .c-profile-new__header__photo__bg__upload {
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 20px;
    display: flex;
    cursor: pointer;
    background: var(--accent-color);
    width: 2em;
    height: 2em;
    border-radius: 5px;
    right: 10px;
    bottom: 10px;
    padding: 2px 3px;
}
@media (max-width: 426px) {
    body .c-profile-new__header__photo__bg__upload {
        right: 15px;
        width: 1.5em;
        height: 1.5em;
   }
    body .c-profile-new__header__photo__bg__upload .icon {
        width: 1.3em;
        height: 1.3em;
   }
}
body .c-profile-new__header__photo__bg__upload .icon {
    fill: var(--theme-text-light);
}
@media (max-width: 426px) and (max-width: 426px) {
    body .c-profile-new__header__photo__bg__upload .icon {
        width: 1em;
        height: 1em;
   }
}
body .c-profile-new__header__photo__img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    &__cont-bg {
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
body .c-profile-new__header__photo__avatar {
    transform: translateY(50%);
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    font-size: 120px;
    display: block;
    height: 1.1em;
    width: 1.1em;
    bottom: 0;
    right: 0;
    left: 0;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__header__photo__avatar {
        font-size: 100px;
   }
}
@media (min-width: $breakpoint-md - 1px) {
    body .c-profile-new__header__photo__avatar {
        font-size: 180px;
   }
}
body .c-profile-new__header__photo__image-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 2em;
    height: 2em;
    border-radius: 5px;
    position: absolute;
    bottom: 8px;
    left: 4px;
    background: var(--accent-color);
    color: var(--theme-text-light);
    cursor: pointer;
    padding-left: 3px;
    padding-top: 2px;
}
@media (max-width: 426px) {
    body .c-profile-new__header__photo__image-upload {
        bottom: 4px;
        left: 0;
        width: 1.5em;
        height: 1.5em;
   }
}
body .c-profile-new__header__photo__image-upload .icon {
    display: block;
    width: 1.3em;
    height: 1.3em;
    fill: var(--theme-text-light);
}
@media (max-width: 426px) {
    body .c-profile-new__header__photo__image-upload .icon {
        width: 1em;
        height: 1em;
   }
}
body .c-profile-new__header__photo .c-avatar {
    border-color: #fff;
    font-size: inherit;
    border-width: 4px;
    display: block;
    width: 1.1em;
    height: 1.1em;
    border: 3px solid #fff;
}
body .c-profile-new__header__caption {
    text-align: center;
}
body .c-profile-new__header__user {
    text-transform: none;
    margin-bottom: 10px;
    padding-top: 25px;
    font-weight: 900;
    font-size: 24px;
    color: var(--bg-text-color);
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__header__user {
        font-size: 20px;
   }
}
body .c-profile-new__header__country {
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 15px;
    color: var(--bg-text-color);
}
body .c-profile-new__header__edit a {
    text-decoration: none;
    color: #f8ec31;
    font-weight: 600;
    font-size: 12px;
    text-shadow: 0 0 1px #000;
}
body .c-profile-new__header__missionstatement {
    text-align: left;
    font-size: 16px;
}
body .c-profile-new__header__tools {
    display: flex;
    justify-content: center;
}
body .c-profile-new__header__trigger {
    margin: 0;
}
body .c-profile-new__header__trigger:not(:last-child) {
    margin-right: 10px;
}
body .c-profile-new__header__trigger > .icon-add-friend {
    margin-top: -0.2em;
}
body .c-profile-new__header__trigger > .icon-remove {
    margin-top: -0.1em;
}
body .c-profile-new__tabs {
    background: var(--white-section-bg);
    margin-top: 40px;
    padding-top: 40px;
}
body .c-profile-new__tabs__nav {
    text-transform: uppercase;
    list-style-type: none;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    line-height: 18px;
    max-width: 500px;
    overflow: hidden;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__tabs__nav {
        margin-right: 10px;
        margin-left: 10px;
        max-width: 100%;
   }
}
body .c-profile-new__tabs__nav__item {
    text-align: center;
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 1;
}
body .c-profile-new__tabs__nav__item a {
    background-color: var(--quest-highlight-bg);
    text-decoration: none;
    padding: 7px 10px;
    color: var(--bg-text-color);
    display: block;
}
body .c-profile-new__tabs__nav__item:not(:last-child) {
    margin-right: 0;
}
body .c-profile-new__tabs-row {
    background: var(--primary-bg-secondary);
    padding-top: 10px;
    border-top: solid 1px var(--lightGreyColor);
    margin-top: 25px;
    position: relative;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__tabs-row {
        border-top-width: 0;
   }
    body .c-profile-new__tabs-row::after {
        position: absolute;
        content: '';
        height: 5px;
        width: 100%;
        background: var(--bg-text-color-secondary);
        bottom: 0;
        z-index: 1;
   }
}
body .c-profile-new__tabs-row__nav {
    list-style: none;
    margin: 0;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}
body .c-profile-new__tabs-row__nav__item {
    font-size: 16px;
    font-weight: 600;
    color: var(--accent-color);
    text-align: center;
    min-width: 150px;
    padding: 15px 0;
    position: relative;
    cursor: pointer;
}
body .c-profile-new__tabs-row__nav__item.active-tab::after {
    content: '';
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--accent-color);
    z-index: 2;
}
body .c-profile-new__quests__icons {
    padding-bottom: 15px;
    padding-top: 15px;
}
body .c-profile-new__quests__list {
    padding-bottom: 10px;
    margin-right: -10px;
    margin-left: -10px;
    flex-wrap: wrap;
    display: flex;
}
body .c-profile-new__quests__item {
    flex-basis: 50%;
    padding: 10px;
    width: 50%;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__quests__item {
        flex-basis: 100%;
        width: 100%;
   }
}
body .c-profile-new__quests__item .c-explore__item {
    border-radius: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    margin: 0;
}
body .c-profile-new__quests .prof-list-more {
    white-space: nowrap;
    margin-right: auto;
    margin-left: auto;
    display: block;
    font-size: 16px;
    text-decoration: none;
    color: var(--accent-color);
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}
body .c-profile-new__balance {
    padding: 20px;
    align-items: center;
    font-size: 16px;
    display: flex;
    border-radius: 5px;
    border: solid 2px var(--accent-color);
    background: var(--quest-highlight-bg);
    margin-bottom: 20px;
    color: var(--bg-text-color);
}
body .c-profile-new__balance__group {
    display: flex;
    align-items: center;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__balance__group {
        flex-direction: column;
        align-items: flex-start;
   }
}
body .c-profile-new__balance__row {
    margin-top: 3px;
    margin-bottom: 3px;
}
body .c-profile-new__balance__row:not(:last-child) {
    margin-right: 10px;
}
body .c-profile-new__balance__row b {
    margin-right: 5px;
}
@media (max-width: $breakpoint-6s) {
    body .c-profile-new__balance__row b {
        display: block;
   }
}
body .c-profile-new__balance__tools {
    margin-left: auto;
}
body .c-profile-new__balance__tools span {
    display: inline-block;
    color: var(--accent-color);
    font-size: 16px;
    float: right;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
}
body .c-profile-new__section {
    padding-right: 40px;
    padding-left: 40px;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__section {
        padding-left: 25px;
        padding-right: 25px;
   }
}
body .c-profile-new__section.favorites-sec .rando-h3 {
    margin-bottom: 25px;
}
body .c-profile-new__section.favorites-sec .c-my-friends__profile-empty, body .c-profile-new__section.favorites-sec .c-my-friends__profile-empty span {
    font-weight: 600;
}
body .c-profile-new__transactions {
    padding: 0 40px 15px 40px;
    margin-bottom: 0 !important;
}
@media (max-width: $breakpoint-xm) {
    body .c-profile-new__transactions {
        padding-left: 0;
        padding-right: 0;
   }
}
body .c-profile-new__transactions .c-article.is-isolated {
    padding: 25px;
}
