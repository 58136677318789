.ng2-tag-input__text-input {
    background: transparent !important;
    width: 32rem;
}
.dark {
    margin-top: -6px;
}
.dark tag {
    box-shadow: none !important;
    background-color: petrol !important;
    font-family: inherit !important;
    font-weight: 800;
}
.dark tag > div {
    display: flex !important;
    align-items: center !important;
}
.dark .tag-wrapper {
    align-items: center !important;
}
.dark .tag__text {
    font-size: 12px !important;
    line-height: 1.2 !important;
}
.dark delete-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-right: -6px;
}
.dark delete-icon:hover {
    transform: none !important;
}
.dark delete-icon > span {
    display: block;
}
.dark svg {
    height: 14px !important;
    display: block;
    margin: 0;
    width: 15px;
}
.dark .ng2-tag-input__text-input {
    font-family: inherit !important;
    font-weight: 800;
}
.ng2-dropdown-menu__options-container .ng2-menu-item {
    font-family: inherit !important;
    font-weight: 800;
}
