.c-dl-explore-search {
	display: block;
	width: 100%;
	background-color: anakiwa;
	padding: 1rem 0;
}
@media (min-width: $screen-sm-min) {
	.c-dl-explore-search {
		padding: 2.5rem 0;
   }
}
.c-dl-explore-search__inner {
	padding: 1rem 0;
	background-color: anakiwa;
}
.is-sticky .c-dl-explore-search__inner {
	z-index: 12 !important;
}
@media (min-width: $screen-sm-min) {
	.c-dl-explore-search__inner {
		padding: 1.5rem 0;
   }
}
.c-dl-explore-search__group {
	position: relative;
}
.c-dl-explore-search__control {
	background: #fff;
	border-radius: 4rem;
	border: 0.4rem solid #fff;
	height: 4.6rem;
	line-height: 4.6rem;
	padding: 0 7rem 0 3rem;
	font-size: 1.8rem;
	width: 100%;
   /* clears the 'X' from Internet Explorer */
   /* clears the 'X' from Chrome */
}
.c-dl-explore-search__control::placeholder {
	color: $silver-sand;
}
.c-dl-explore-search__control::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
.c-dl-explore-search__control::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}
.c-dl-explore-search__control::-webkit-search-decoration, .c-dl-explore-search__control::-webkit-search-cancel-button, .c-dl-explore-search__control::-webkit-search-results-button, .c-dl-explore-search__control::-webkit-search-results-decoration {
	display: none;
}
.c-dl-explore-search__indicator {
	display: flex;
	position: absolute;
	right: 2rem;
	top: 0;
	bottom: 0;
	width: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.8rem;
}
.c-dl-explore-search__loop {
	font-size: 2.4rem;
	transform: scaleX(-1);
	color: pelorous;
}
.c-dl-explore-search__remove {
	font-size: 2.4rem;
	color: pelorous;
}
.c-dl-explore-search__dropdown {
	position: absolute;
	top: 100%;
	right: 2rem;
	left: 2rem;
	font-size: 1.4rem;
	background: #fff;
	max-height: 18rem;
	overflow-x: hidden;
	overflow-y: auto;
	border: 1px solid pelorous;
	margin-top: 1px;
	display: none;
	z-index: 2;
}
.has-results.open .c-dl-explore-search__dropdown {
	display: block;
}
.c-dl-explore-search__dropdown.is-loading {
	opacity: 0.8;
	pointer-events: none;
}
.c-dl-explore-search__dropdown__inner {
	display: flex;
	flex-direction: column;
}
.c-dl-explore-search__dropdown__item {
	display: block;
	padding: 1rem;
	text-decoration: none !important;
	color: pelorous;
}
.c-dl-explore-search__dropdown__item:hover {
	background-color: pelorous;
	color: #fff;
}
