.c-dl-team-control {
    display: block;
}
.c-dl-team-control.ng-invalid.ng-touched .c-n-form__input {
    border-color: puce;
}
.c-dl-team-control__header {
    display: block;
}
.c-dl-team-control__header.bs-placeholder .filter-option-inner-inner {
    padding-left: 2rem;
}
.c-dl-team-control__text {
    flex-grow: 1;
    flex-shrink: 1;
    padding-left: 1.25rem;
    white-space: normal;
}
.c-dl-team-control__view {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
}
.c-dl-team-control__view.is-empty {
    opacity: 0.5;
}
.c-dl-team-control__view.is-empty img {
    max-width: 24px;
}
.c-dl-team-control__view > .h-object-fit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
}
.c-dl-team-control__count {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: -0.7rem;
    width: 1.4rem;
    height: 1.4rem;
    font-size: 1rem;
    border-radius: 50%;
    background: var(--petrol);
    color: #fff;
    font-style: normal;
    font-weight: 400;
    align-items: center;
}
.c-dl-team-control .filter-option {
    width: 100%;
    overflow: hidden;
}
.c-dl-team-control .filter-option-inner-inner {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
}
.c-dl-team-control .filter-option-inner-inner .c-dl-team-control__view {
    position: relative;
}
.c-dl-team-control .filter-option-inner-inner .c-dl-team-control__count {
    display: none;
}
.c-dl-team-control .dropdown-menu.inner > li > a {
    position: relative;
    padding: 0.5rem 1rem;
}
.c-dl-team-control .bootstrap-select .dropdown-menu li a span.text {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1rem 0 0 0;
}
span span .no-team {
    margin-left: 30px;
}
