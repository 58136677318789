.c-nl-partners {
	padding: 5rem 0;
	background-color: #f9f9f9;
	color: #2e3638;
}
.c-nl-partners__header {
	padding-top: 6rem;
}
.c-nl-partners__body {
	padding-bottom: 0;
}
.c-nl-partners__title {
	font-size: 3.2rem;
	font-weight: 400;
	letter-spacing: 0.044rem;
	text-align: center;
	color: #2e3638;
	line-height: 1;
	padding-left: 15%;
	padding-right: 15%;
}
@media (max-width: 651px) {
	.c-nl-partners__title {
		padding-left: 0;
		padding-right: 0;
   }
}
.c-nl-partners__title b {
	font-weight: 800;
}
.c-nl-partners__group {
	display: flex;
	flex-wrap: wrap;
	margin-left: -0.2rem;
	margin-right: -0.2rem;
	margin: auto;
}
.c-nl-partners__col {
	padding-left: 0.2rem;
	padding-right: 0.2rem;
	margin-bottom: 0.4rem;
	width: 33.333%;
}
@media (max-width: $screen-sm-max) {
	.c-nl-partners__col {
		width: 50%;
   }
}
@media (max-width: $screen-xs-max) {
	.c-nl-partners__col {
		width: 100%;
   }
}
.c-nl-partners__item {
	background-color: $tealColor;
	color: #fff;
	display: block;
	position: relative;
	width: 100%;
	margin: 0 auto;
	text-decoration: none !important;
}
.c-nl-partners__item:after {
	content: '';
	display: block;
	padding-bottom: 34.1%;
}
.c-nl-partners__item > img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	max-width: 90%;
	max-height: 90%;
	width: auto;
	height: auto;
}
