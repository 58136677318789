.c-dl-explore-categories {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-left: -1rem;
	margin-right: -1rem;
}
@media (min-width: $screen-md-min) {
	.c-dl-explore-categories {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
   }
}
.c-dl-explore-categories__item {
	width: 50%;
	padding-left: 1rem;
	padding-right: 1rem;
	margin-bottom: 2rem;
}
@media (min-width: $screen-md-min) {
	.c-dl-explore-categories__item {
		width: 33.33%;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin-bottom: 3rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-explore-categories__item {
		width: 100%;
   }
}
.c-dl-explore-categories__item__inner {
	display: block;
	width: 100%;
	transition: transform 200ms ease;
	cursor: pointer;
	border: 2px solid transparent;
	box-shadow: 0 3px 0.6rem -0.6rem #000;
}
.c-dl-explore-categories__item:hover .c-dl-explore-categories__item__inner {
	transform: scale(1.02);
}
.c-dl-explore-categories__item.is-active .c-dl-explore-categories__item__inner {
	border-color: $tealColor;
}
.c-dl-explore-categories__item__view {
	position: relative;
	display: block;
}
.c-dl-explore-categories__item__view > .h-object-fit {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.c-dl-explore-categories__item__view:after {
	content: '';
	display: block;
	padding-bottom: 56.6%;
}
.c-dl-explore-categories__item__caption {
	position: relative;
	display: block;
	padding: 0.8rem 0.8rem;
	color: #fff;
	background-color: $tealColor;
}
.c-dl-explore-categories__item__title {
	display: flex;
	align-items: center;
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: 0;
}
.c-dl-explore-categories__item__title .icon {
	margin-right: 1rem;
}
.c-dl-explore-categories__item__help {
	position: absolute;
	right: 1rem;
	top: 0;
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	font-weight: 400;
	overflow: hidden;
}
.c-dl-explore-categories__item__help__inner {
	display: flex;
	align-items: center;
	transform: translateX(100%);
	transition: transform 200ms ease;
}
.c-dl-explore-categories__item:hover .c-dl-explore-categories__item__help__inner {
	transform: none;
}
.c-dl-explore-categories__item__help__inner .icon {
	margin-left: 0.5em;
}
.c-dl-explore-categories__inner {
	display: block;
}
.c-dl-explore-categories__carousel .c-dl-explore-categories__item {
	display: block;
	margin-bottom: 0;
}
.c-dl-explore-categories__carousel .slick-dots {
	display: flex;
	flex-wrap: wrap;
	padding: 10px 5px 0;
	justify-content: center;
	list-style-type: none;
	margin-bottom: 0;
}
.c-dl-explore-categories__carousel .slick-dots li {
	width: 1em;
	height: 1em;
	border-radius: 50%;
	font-size: 10px;
	background-color: anakiwa;
	cursor: pointer;
}
.c-dl-explore-categories__carousel .slick-dots li:not(:last-child) {
	margin-right: 4px;
}
.c-dl-explore-categories__carousel .slick-dots li.slick-active {
	background-color: $tealColor;
}
