.e-oval {
	border-radius: 50%;
	width: 1em;
	height: 1em;
	font-size: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.e-oval.is-green {
	background-color: shamrock;
	color: #fff;
}
.e-oval.is-red {
	background-color: $cinnabar;
	color: #fff;
}
.e-oval.is-yellow {
	background-color: var(--orient);
	color: ebony-clay;
}
.e-oval.is-orient {
	background-color: var(--orient);
	color: #fff;
}
.e-oval.is-transparent {
	background-color: rgba(0,0,0,0);
	color: $black;
}
.e-oval.is-loading {
	position: relative;
}
.e-oval.is-loading .fa, .e-oval.is-loading .icon {
	display: none;
}
.e-oval.is-loading:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	border: 2px solid currentColor;
	border-bottom-color: transparent;
	display: block;
	vertical-align: middle;
	font-size: 16px;
	animation: rotating 2s linear infinite;
}
.e-oval .fa, .e-oval .icon {
	font-size: 0.6em;
}
