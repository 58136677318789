.profile-section {
	padding: 15px 0 15px 0;
	font-size: 12px;
	font-weight: 800;
}
.profile-section span {
	font-weight: normal;
}
.ps-info {
	font-weight: 900;
}
.ps-info span {
	font-weight: 400;
	margin-left: 5px;
}
.ps-info-view-more {
	font-weight: 900;
}
.ps-info-view-more span {
	font-weight: 400;
	margin: 0 auto;
}
.rando-h3 {
	font-size: 12px;
	color: rgba(0,0,0,0.8);
	text-transform: uppercase;
	margin-bottom: 0px;
	font-weight: 900;
	letter-spacing: 0.5px;
}
.favorites-list {
	margin-left: -10px;
	margin-right: -10px;
	margin-top: 20px;
}
.favorite-pod {
	width: 100px;
	height: 70px;
	border-radius: 6px;
	background-color: rgba(68,120,131,0.8);
	margin-bottom: 15px;
	margin-left: 11px;
	margin-right: 0;
	float: left;
	text-align: center;
	color: #fff;
	background-repeat: no-repeat;
	background-position: center 13px;
	-webkit-transform: translate3d(0, 0, 0);
}
.favorite-pod span {
	padding-top: 50px;
	display: block;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 800;
}
.fav-physical {
	background-image: url("/assets/static/fav-physical.png");
	background-size: 33px 25px;
}
.fav-intellectual {
	background-image: url("/assets/static/fav-intellectual.png");
	background-size: 25px 25px;
}
.fav-spiritual {
	background-image: url("/assets/static/fav-spiritual.png");
	background-size: 25px 25px;
	background-position: center 14px;
}
.fav-social {
	background-image: url("/assets/static/fav-social.png");
	background-size: 25px 25px;
}
.fav-occupational {
	background-image: url("/assets/static/fav-occupational.png");
	background-size: 25px 25px;
}
.fav-financial {
	background-image: url("/assets/static/fav-financial.png");
	background-size: 25px 25px;
}
.fav-environment {
	background-image: url("/assets/static/fav-environment.png");
	background-size: 25px 25px;
}
.fav-physical-active {
	background-image: url("/assets/static/fav-physical-active.png");
	background-size: 33px 25px;
}
.fav-intellectual-active {
	background-image: url("/assets/static/fav-intellectual-active.png");
	background-size: 25px 25px;
}
.fav-spiritual-active {
	background-image: url("/assets/static/fav-spiritual-active.png");
	background-size: 25px 25px;
	background-position: center 14px;
}
.fav-social-active {
	background-image: url("/assets/static/fav-social-active.png");
	background-size: 25px 25px;
}
.fav-occupational-active {
	background-image: url("/assets/static/fav-occupational-active.png");
	background-size: 25px 25px;
}
.fav-financial-active {
	background-image: url("/assets/static/fav-financial-active.png");
	background-size: 25px 25px;
}
.fav-environment-active {
	background-image: url("/assets/static/fav-environment-active.png");
	background-size: 25px 25px;
}
.recent-mod {
	background: transparent url("/static/recent-mod.png") 0 center no-repeat;
	color: #fff;
	text-transform: uppercase;
	padding-left: 18px;
	background-size: 12px 12px;
	font-size: 10px;
	line-height: 12px;
	font-weight: 400;
	margin-top: 6px;
}
.recent-mod-transactions {
	float: left;
	color: #1c4955;
	text-transform: uppercase;
	padding-left: 1px;
	background-size: 12px 12px;
	font-size: 7px;
	line-height: 1px;
	margin-top: -5px;
	font-weight: 400;
}
.recent-trans-quest-desc {
	color: #000;
	margin-top: -17px;
	font-weight: 400;
}
.horz-list-transactions {
	overflow: auto;
	width: 100%;
}
ul.horz-list-transactions {
	list-style: none;
}
.horz-list-transactions:not(:last-child) {
	border-bottom: 1px solid rgba(90,147,162,0.5);
}
.horz-list-transactions li {
	padding-top: 12px;
	float: left;
	padding-bottom: 1px;
}
@media only screen and (max-width: 768px) {
	.horz-list-transactions li {
		width: 15%;
   }
}
.horz-list-transactions li:nth-child(2) {
	width: 53%;
	padding-left: 17px;
	padding-top: 18px;
	padding-right: 5px;
}
@media only screen and (max-width: 768px) {
	.horz-list-transactions li:nth-child(2) {
		width: 60%;
		padding-top: 5px;
   }
}
.horz-list-transactions li:nth-child(3) {
	width: 35%;
	float: right;
	text-align: right;
	padding-top: 15px;
}
@media only screen and (max-width: 768px) {
	.horz-list-transactions li:nth-child(3) {
		width: 17%;
		padding-top: 10px;
   }
}
div.trans-icon {
	width: 10px;
	text-align: center;
	float: left;
	margin-right: 10px;
}
.yellow-transactions {
	color: #ff0;
}
.red-transactions {
	color: #f00;
}
.red-transactions-fee {
	color: #1c4955;
	font-weight: lighter;
	white-space: nowrap;
}
@media only screen and (max-width: 768px) {
	.red-transactions-fee {
		font-size: 9px;
   }
}
.green-transactions {
	color: #008000;
}
.white-transactions {
	color: #fff;
}
.black-transactions {
	color: #000;
}
.quest-rp-num {
	font-family: 'Proxima Nova Bl';
	font-size: 15px;
}
.subtle-link {
	text-decoration: none;
}
.quest-rp {
	position: absolute;
	top: 7px;
	left: 23px;
}
.friend-pod {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	border: 1px solid #fff;
	overflow: hidden;
	float: left;
	margin-left: 10px;
	margin-bottom: 10px;
}
@media (min-width: 0px) and (max-width: 799px) {
	.friend-pod {
		width: 34px;
		height: 34px;
   }
}
.friend-pod img {
	width: 100%;
	height: 100%;
	overflow: auto;
	background-size: cover;
}
.friend-pod .friend-pod-sub {
	width: 99.5%;
	height: 99.5%;
	background-size: cover;
	background-position: center center;
}
.friend-pod .friend-pod-sub-bank {
	width: 85%;
	height: 85%;
	margin-left: 5px;
	background-size: cover;
	background-position: center center;
}
@media (min-width: 0px) and (max-width: 799px) {
	.friend-pod .friend-pod-sub-bank {
		margin-left: 2.5px;
   }
}
