@-moz-keyframes fadeOut {
	0% {
		opacity: 1;
   }
	80% {
		opacity: 1;
   }
	90% {
		opacity: 0;
   }
	100% {
		opacity: 1;
   }
}
@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
   }
	80% {
		opacity: 1;
   }
	90% {
		opacity: 0;
   }
	100% {
		opacity: 1;
   }
}
@-o-keyframes fadeOut {
	0% {
		opacity: 1;
   }
	80% {
		opacity: 1;
   }
	90% {
		opacity: 0;
   }
	100% {
		opacity: 1;
   }
}
@keyframes fadeOut {
	0% {
		opacity: 1;
   }
	80% {
		opacity: 1;
   }
	90% {
		opacity: 0;
   }
	100% {
		opacity: 1;
   }
}

my-quests-component-active, my-quests-component-saved, my-quests-component-completed {
	display: block;
}
.c-my-quests.is-included {
	padding-top: 0;
}
.c-my-quests__header {
	display: block;
	background: linear-gradient(to right, #265967 0%, #33798a 100%);
	color: #fff;
}
.c-my-quests__header.is-light-themed {
	background: $petrol-15;
	color: inherit;
	box-shadow: none;
}
.c-my-quests__user {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 85px;
}
.c-my-quests__user__add {
	position: relative;
	padding-left: 40px;
	font-size: 16px;
	font-weight: 800;
	line-height: 1.35em;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none !important;
}
.c-my-quests__user__add:hover {
	color: $bright-yellow;
}
.c-my-quests__user__add .icon {
	position: absolute;
	left: 0;
	top: 4px;
	font-size: 33px;
	color: $bright-yellow;
}
.c-my-quests__profile {
	position: relative;
	padding-left: 84px;
	height: 85px;
	display: flex;
	align-items: center;
	font-weight: 800;
	font-size: 21px;
	max-width: calc(100% - 110px);
	text-decoration: none !important;
	color: inherit;
}
.c-my-quests__profile__view {
	position: absolute;
	top: 10px;
	left: 0;
	width: 1em;
	height: 1em;
	font-size: 64px;
	overflow: hidden;
	border-radius: 50%;
	border: solid 2px #fff;
	background-color: var(--petrol);
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
.c-my-quests__profile__img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: auto;
	width: auto;
	display: block;
	max-height: 100%;
	max-width: 100%;
}
.c-my-quests__profile__name {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}
.c-my-quests__nav {
	border-top: 1px solid powder-blue;
}
.c-my-quests__nav:first-child {
	border-top: 0;
}
.c-my-quests__nav__list {
	display: flex;
	list-style-type: none;
	margin-bottom: 0;
	padding-left: 0;
	padding-right: 0;
}
.c-my-quests__nav__list:first-child {
	border-top: 0;
}
.c-my-quests__nav__list li {
	width: 33.333%;
	position: relative;
}
@media (max-width: 321px) {
	.c-my-quests__nav__list li {
		width: auto;
		flex-grow: 1;
   }
}
.c-my-quests__nav__list li:not(:last-child):after {
	content: '';
	position: absolute;
	right: -0.5px;
	top: 10px;
	bottom: 10px;
	width: 1px;
	background-color: powder-blue;
}
.c-my-quests__header.is-light-themed .c-my-quests__nav__list li:not(:last-child):after {
	background-color: var(--petrol);
}
.c-my-quests__nav__list li.is-active a {
	color: $bright-yellow;
}
.c-my-quests__header.is-light-themed .c-my-quests__nav__list li.is-active a {
	color: $bright-yellow;
}
.c-my-quests__nav__list a {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
	color: powder-blue;
	text-decoration: none !important;
	padding: 20px 0;
	height: 64px;
	font-weight: 800;
	font-size: 14px;
}
.c-my-quests__header.is-light-themed .c-my-quests__nav__list a {
	color: inherit;
}
@media (max-width: $breakpoint-xs) {
	.c-my-quests__nav__list a {
		font-size: 12px;
   }
}
.c-my-quests__nav__list a .icon {
	margin-right: 10px;
	font-size: 20px;
}
@media (max-width: $breakpoint-xs) {
	.c-my-quests__nav__list a .icon {
		margin-right: 6px;
		font-size: 16px;
		margin-left: -0.2em;
   }
}
.c-my-quests__nav__list a i {
	font-style: normal;
	min-width: 20px;
	text-align: center;
	margin-left: 3px;
}
@media (max-width: $breakpoint-xs) {
	.c-my-quests__nav__list a i {
		font-style: normal;
		min-width: 5px;
		text-align: center;
		margin-left: 3px;
   }
}
.c-my-quests__nav__list a:hover {
	color: #fff;
}
.c-my-quests__body {
	display: block;
	height: calc(100vh - 190px);
}
.c-my-quests.is-included .c-my-quests__body {
	height: auto;
	padding-top: 10px;
	min-height: 100px;
}
.c-my-quests.is-included .c-my-quests__body.is-empty {
	padding-top: 0;
}
.c-my-quests.is-included .c-my-quests__body .h-loader__control {
	background-color: $petrol-15;
}
.c-my-quests__inner {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100vh - 190px);
}
.c-my-quests.is-included .c-my-quests__inner {
	height: auto;
}
.c-my-quests__footer {
	display: flex;
	justify-content: center;
	padding: 0 0 40px;
}
@media (max-width: $breakpoint-xs) {
	.c-my-quests__footer {
		padding: 20px 0;
		border-top: 1px solid #599aab;
   }
	.c-my-quests.is-included .c-my-quests__footer {
		border-top-color: $petrol-15;
   }
}
.c-my-quests__item {
	display: block;
	position: relative;
	width: 100%;
	cursor: pointer;
	overflow: hidden;
}
.c-my-quests__item:not(:last-child) {
	border-bottom: 1px solid $petrol-50;
}
.c-my-quests.is-included .c-my-quests__item:not(:last-child) {
	border-bottom-color: $petrol-15;
}
.c-my-quests__item:only-child {
	border-top: 1px solid $petrol-50;
	border-bottom: 1px solid $petrol-50;
}
.c-my-quests__item:hover, .c-my-quests__item--selected {
	background: rgba(255,255,255,0.25);
}
.c-my-quests__item__inner {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 82px;
	width: 100%;
	transition: transform 300ms ease;
	padding: 0 15px;
}
.c-my-quests__item__inner.has-right-pad {
	padding: 0 35px 0 15px;
}
@media (max-width: 700px) {
	.c-my-quests__item__inner.has-right-pad {
		padding: 0 25px 0 15px;
   }
}
@media (min-width: 701px) {
	.c-my-quests__item__inner {
		transform: none !important;
   }
}
.c-my-quests__item__tools {
	position: absolute;
	height: 100%;
	right: 0;
	top: 0;
	display: flex;
}
@media (max-width: 700px) {
	.c-my-quests__item__tools {
		display: flex;
		left: 100%;
		right: auto;
   }
}
.c-my-quests__item__tools__arrows {
	position: absolute;
	right: 100%;
	top: 0;
	margin-top: 0;
	font-size: 14px;
	height: 100%;
	display: none;
	align-items: center;
	margin-right: 0;
	padding-right: 4px;
	padding-left: 4px;
	z-index: 2;
}
@media (max-width: 700px) {
	.c-my-quests__item__tools__arrows {
		display: flex;
   }
}
.has-tools-showed .c-my-quests__item__tools__arrows {
	transform: rotate(180deg);
}
.c-my-quests__item__tools__arrows .icon {
	position: relative;
	animation: fadeOut 5s infinite ease-in-out 1200ms;
}
.c-my-quests__item__tools__arrows .icon:nth-child(2) {
	animation-delay: 1100ms;
}
.c-my-quests__item__tools__arrows .icon:nth-child(3) {
	animation-delay: 1000ms;
}
.c-my-quests__item__tool {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 30px;
	text-decoration: none;
	height: 100%;
	font-size: 16px;
	position: relative;
	z-index: 1;
}
.c-my-quests__item__tool span {
	font-size: 0.8em;
	margin-top: 5px;
	display: none;
}
@media (max-width: 700px) {
	.c-my-quests__item__tool span {
		display: block;
   }
}
@media (max-width: 700px) {
	.c-my-quests__item__tool {
		width: 50px;
		background: #609eae;
		color: #fff;
   }
}
.c-my-quests__item__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 1;
}
.c-my-quests__item__title {
	display: flex;
	align-items: center;
	width: calc(100% - 91px);
	text-decoration: none !important;
	color: inherit;
}
.c-my-quests__item.is-fully .c-my-quests__item__title.c-my-quests__item__title {
	width: 100%;
}
.c-my-quests__item__title > span, .c-my-quests__item__title > a {
	display: block;
	position: relative;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 1.35em;
	word-wrap: break-word;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	text-overflow: -o-ellipsis-lastline;
	max-height: 41px;
	text-decoration: none !important;
	font-weight: 800;
}
.c-my-quests__item__title > span .icon, .c-my-quests__item__title > a .icon {
	position: relative;
	top: 0.15em;
}
.c-my-quests__item__star {
	position: relative;
	font-size: 20px;
	margin-right: 12px;
	min-width: 21px;
	z-index: 2;
}
.c-my-quests__item__star .h-xs-loader__control {
	font-size: 14px;
}
.c-my-quests__item__star .icon-full-star {
	color: $bright-yellow;
}
.c-my-quests__item__star__inner {
	display: flex;
	justify-content: center;
	min-width: 21px;
}
.c-my-quests__item__statistics {
	position: relative;
	display: flex;
	padding-left: 10px;
}
.c-my-quests__item__statistics > *:not(:last-child) {
	margin-right: 10px;
}
.c-my-quests__item__statistics__trigger {
	font-size: 20px;
	padding-left: 10px;
	padding-right: 10px;
	position: relative;
	z-index: 2;
}
.c-my-quests__item__progress__repeat {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	margin-right: 0 !important;
	z-index: 2;
	background: $golden-fizz;
	border-radius: 50%;
	padding: 0;
	font-size: 12px;
}
.c-my-quests__item__progress__repeat.is-only-child, .c-my-quests__item__progress__repeat:only-child {
	margin: 0;
}
.c-my-quests__item__progress__repeat .icon {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 22px;
}
.c-my-quests__item__progress .c-quest-progress__inner {
	font-size: 60px;
}
.c-my-quests__item__progress .c-quest-progress__caption span {
	display: none;
}
.c-my-quests__item__repeat {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $golden-fizz;
	padding-top: 2px;
	padding-right: 4px;
}
.c-my-quests__item__repeat__count {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 1em;
	height: 1em;
	font-size: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--petrol);
	font-size: 20px;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	background-color: $golden-fizz;
}
.c-my-quests__item__repeat__count > span {
	font-size: 11px;
}
.c-my-quests__item__repeat .icon-update, .c-my-quests__item__repeat .icon-empty-brand {
	font-size: 30px;
}
.c-my-quests__item__repeat .icon-repeat {
	position: absolute;
	top: -1px;
	left: 0;
	font-size: 62px;
	margin-left: -0.04em;
	transform: rotate(-19deg);
}
.c-my-quests__empty {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: calc(100vh - 190px);
	line-height: 1.25;
}
.c-my-quests__empty.is-inline {
	height: 100px;
}
.c-my-quests__empty.is-inline .c-my-quests__empty__title {
	font-size: 16px;
}
.c-my-quests__empty.is-inline .c-my-quests__empty__icon {
	font-size: 78px;
}
.c-my-quests__empty__icon {
	font-size: 100px;
	opacity: 0.2;
	position: absolute;
	bottom: 10px;
	right: 10px;
	color: var(--petrol);
}
.c-my-quests__empty__icon-friends-list {
	font-size: 90px;
	opacity: 0.2;
	color: var(--petrol);
	padding-bottom: 5px;
}
@media (max-width: $breakpoint-6s) {
	.c-my-quests__empty__caption {
		max-width: 220px;
   }
}
.c-my-quests__empty__title {
	font-size: 20px;
	text-transform: uppercase;
	margin-bottom: 10px;
}
.c-my-quests__empty__title:last-child {
	margin-bottom: 0;
}
.c-my-quests__empty__cnt {
	text-transform: uppercase;
}
.c-my-quests__empty__cnt .h-link {
	font-weight: 800;
}
