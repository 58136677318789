.c-dialog .modal-inner {
    display: flex;
}
@media (max-width: $breakpoint-xs) {
    .c-dialog .modal-inner {
        padding: 0;
   }
}
.c-dialog .modal-content {
    position: relative;
    line-height: 1.2;
    width: 100%;
    display: flex;
    flex-direction: column;
}
@media (min-width: breakpoint-xs1px) {
    .c-dialog .modal-content {
        max-width: 500px;
        margin: auto;
   }
}
@media (max-width: $breakpoint-xs) {
    .c-dialog .modal-content {
        max-width: none;
   }
}
.c-dialog .modal-close {
    background-color: transparent;
    top: 10px;
    right: 10px;
    font-size: 40px;
    color: $black;
}
@media (max-width: $breakpoint-xs) {
    .c-dialog .modal-close {
        top: 0;
        right: 0;
        padding: 0;
   }
}
.c-dialog__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    background-color: #fff;
    color: bondi-blue;
    padding: 10px 64px 10px 10px;
    text-transform: uppercase;
    font-size: 16px;
    width: 100%;
}
@media (min-width: breakpoint-xs1px) {
    .c-dialog__header {
        height: auto;
        padding: 40px 40px 25px;
        border-radius: 10px 10px 0 0;
   }
}
.c-dialog__title {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    text-transform: none;
    font-weight: 700;
    font-size: 28px;
}
.c-dialog__title .icon {
    font-size: 0.7em;
    margin-right: 7px;
}
.c-dialog__body {
    overflow-y: auto;
    color: var(--outer-space);
    width: 100%;
}
@media (min-width: breakpoint-xs1px) {
    .c-dialog__body {
        border-radius: 0 0 10px 10px;
        max-height: 700px;
   }
}
.c-dialog__inner {
    padding: 10px 10px;
}
.c-dialog__bottom-tools {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    order: 1;
    display: flex;
    background: #fff;
}
.c-dialog__bottom-tools ~ .c-dialog__body {
    height: calc(100vh - 81px);
}
@media (max-width: $breakpoint-xs) {
    .c-dialog__bottom-tools ~ .c-dialog__body {
        height: calc(100vh - 80px);
   }
}
.c-dialog__tool {
    height: 40px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none !important;
    background-color: var(--petrol);
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    flex-grow: 1;
}
.c-dialog__tool.is-red {
    background-color: $cinnabar;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
}
