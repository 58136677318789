.tippy__mention {
	display: block;
}
.tippy__caption {
	position: relative;
	display: flex;
	align-items: center;
	padding-right: 15px;
	cursor: pointer;
	color: #2e3638;
}
.tippy__caption:hover {
	text-decoration: underline;
}
.tippy__caption:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 1em;
	height: 1em;
	border-right: 1px solid currentColor;
	border-bottom: 1px solid currentColor;
	transform: rotate(-45deg);
	margin: auto 0;
	font-size: 4px;
	color: #2e3638;
}
.tippy__avatar {
	font-size: 40px !important;
	flex-shrink: 0;
}
@media (max-width: $quest-detail-new-sm-max) {
	.tippy__avatar {
		font-size: 32px !important;
   }
}
.tippy__ttl {
	padding-left: 10px;
	margin-right: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 20px;
}
@media (max-width: $quest-detail-new-sm-max) {
	.tippy__ttl {
		font-size: 16px;
   }
}
.tippy__friend-status {
	display: flex;
	align-items: center;
	justify-content: center;
	color: smalt-blue;
	margin: 0 auto;
}
.tippy__friend-status span {
	display: none;
}
.tippy__friend-status .icon {
	display: none;
	margin-right: 5px;
	font-size: 0.7em;
}
.tippy__tools {
	display: none;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-top: 1px dashed #fff;
	padding-top: 5px;
	margin-top: 5px;
}
.tippy__tools.is-active {
	display: flex;
}
.tippy__tools.is-active .tippy__friend-status {
	color: #fff;
}
.tippy__tools.is-active .tippy__friend-status:hover {
	text-decoration: underline;
}
.tippy__tools.is-active .tippy__friend-status .active {
	display: block;
}
.tippy__tools.is-active .tippy__friend-status .icon-plus {
	display: block;
}
.tippy__tools.is-pending {
	display: block;
}
.tippy__tools.is-pending .tippy__friend-status {
	color: #fff;
}
.tippy__tools.is-pending .tippy__friend-status .pending {
	display: block;
}
.tippy__tools.is-pending .tippy__friend-status .icon-plane {
	display: block;
}
.tippy__tools.is-accepted {
	display: block;
}
.tippy__tools.is-accepted .tippy__friend-status {
	color: shamrock;
}
.tippy__tools.is-accepted .tippy__friend-status .accepted {
	display: block;
}
.tippy__tools.is-accepted .tippy__friend-status .icon-checked {
	display: block;
}
