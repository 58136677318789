.c-interests__inner {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.c-interest {
	flex-grow: 1;
	display: block;
	position: relative;
	border-radius: 5px;
	background-color: rgba(0,81,102,0.3);
	transition-property: background-color, color;
	transition-duration: 200ms;
	transition-timing-function: ease;
	margin-right: 2%;
	max-width: 180px;
	width: 15%;
	box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.15);
}
@media (max-width: 426px) {
	.c-interest {
		width: 49%;
		max-width: 49%;
		margin-bottom: 20px;
   }
	.c-interest:nth-child(even) {
		margin-right: 0;
   }
}
@media (max-width: 1025px) and (min-width: 770px) {
	.c-interest {
		width: 170px;
   }
}
@media (max-width: 769px) and (min-width: 427px) {
	.c-interest {
		width: 33.33%;
		margin-bottom: 20px;
   }
}
.c-interest:hover:not(.is-active) {
	background-color: rgba(0,81,102,0.5);
}
.c-interest:last-child {
	margin-right: 0;
}
.c-interest.is-active {
	display: block;
}
.c-interest.is-active.physical .c-interest__caption {
	background-color: $physical;
}
.c-interest.is-active.environmental .c-interest__caption {
	background-color: $environmental;
}
.c-interest.is-active.occupational .c-interest__caption {
	background-color: $occupational;
}
.c-interest.is-active.mental .c-interest__caption {
	background-color: $mental;
}
.c-interest.is-active.friends .c-interest__caption {
	background-color: $friends;
}
.c-interest.is-active.piggybank .c-interest__caption {
	background-color: $piggybank;
}
.c-interest.is-editable {
	display: block;
	cursor: pointer;
}
.c-interest.is-editable.is-active .icon-check {
	opacity: 1;
}
.c-interest__caption {
	text-align: left;
	text-transform: capitalize;
	padding: 10px 12px;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.5px;
	font-weight: 700;
	color: #fff;
}
.c-interest__icon {
	width: 1.6em;
	height: 1.6em;
}
.c-interest .icon-check {
	position: absolute;
	top: 6px;
	right: 6px;
	opacity: 0;
	transition: opacity 200ms ease;
	font-size: 14px;
}
.c-interest__activity-count {
	background: var(--milestone-task-bg);
	color: var(--bg-text-color);
	text-align: left;
	font-size: 14px;
	padding: 10px 11px;
	line-height: 22px;
	min-height: 70px;
}
.c-interest__activity-count span {
	font-size: 26px;
	font-weight: 600;
	display: inline-block;
}
.c-interest__quest-count {
	background: #fff;
	color: var(--bg-text-color);
	text-align: left;
	font-size: 14px;
	padding: 10px 11px;
	line-height: 22px;
	min-height: 70px;
}
.c-interest__quest-count span {
	font-size: 26px;
	font-weight: 600;
	display: inline-block;
}
.c-interest.disabled-btn .c-interest__caption {
	background-color: var(--platinum) !important;
}
