.c-menu {
	position: fixed;
	display: block;
	z-index: 50;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;
}
.c-menu__inner {
	background-color: rgba(36,88,102,0.7);
	flex-direction: column;
	padding-top: 40px;
	display: flex;
	height: 100%;
	width: 100%;
}
.c-menu__close {
	background: transparent;
	justify-content: center;
	align-items: center;
	position: absolute;
	font-size: 20px;
	color: #fff;
	display: flex;
	border: none;
	height: 40px;
	width: 40px;
	padding: 0;
	right: 0;
	top: 0;
}
.c-menu__overflow {
	justify-content: space-between;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-grow: 1;
}
.c-menu__body {
	margin-bottom: 20px;
	flex-grow: 1;
}
.c-menu__list {
	border-top: 1px solid rgba(96,191,215,0.3);
}
.c-menu__list li {
	border-bottom: 1px solid rgba(96,191,215,0.3);
}
.c-menu__list li.is-active a {
	background-color: rgba(0,81,102,0.5);
	cursor: default;
}
.c-menu__list li.is-active .icon-arrow-right {
	opacity: 1;
}
.c-menu__list a {
	transition: background-color 0.3s ease;
	text-decoration: none;
	padding-right: 108px;
	position: relative;
	line-height: 56px;
	text-align: right;
	font-weight: 900;
	font-size: 20px;
	display: block;
	color: #fff;
	height: 56px;
	position: relative;
}
.c-menu__list a:hover {
	background-color: rgba(0,81,102,0.15);
}
.c-menu__list a.ham-feed, .c-menu__list a.ham-quests, .c-menu__list a.ham-friends, .c-menu__list a.ham-profile, .c-menu__list a.ham-account, .c-menu__list a.ham-alerts, .c-menu__list a.ham-log-out {
	background-position: calc(100% - 64px) center;
	background-repeat: no-repeat;
	background-size: 30px 30px;
}
.c-menu__list a.ham-feed {
	background-image: url("/assets/static/ham-feed2.png");
}
.c-menu__list a.ham-quests {
	background-image: url("/assets/static/ham-quests.png");
}
.c-menu__list a.ham-friends {
	background-image: url("/assets/static/ham-friends.png");
}
.c-menu__list a.ham-profile {
	background-image: url("/assets/static/ham-profile.png");
}
.c-menu__list a.ham-account {
	background-image: url("/assets/static/ham-account.png");
}
.c-menu__list a.ham-alerts {
	background-image: url("/assets/static/ham-alerts.png");
}
.c-menu__list a.ham-log-out {
	background-image: url("/assets/static/ham-logouot.png");
}
.c-menu__list a .icon-arrow-right {
	transform: rotate(180deg);
	position: absolute;
	stop-color: 0;
	margin: auto;
	right: 25px;
	opacity: 0;
	bottom: 0;
	top: 0;
}
.c-menu__icon {
	position: absolute;
	right: 64px;
	font-size: 30px;
	top: 0;
	bottom: 0;
	margin: auto;
	color: $golden-fizz;
}
.c-menu__footer {
	padding: 10px 64px 10px 0;
	position: relative;
	text-align: right;
	margin-top: auto;
	display: block;
	color: #fff;
	width: 100%;
	margin: 0;
}
.c-menu__footer a {
	color: rgba(255,255,255,0.8);
	display: inline-block;
	font-size: 11px;
	padding: 10px 0;
}
.c-menu__footer a:not(:last-child) {
	margin-right: 8px;
}
