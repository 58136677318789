.c-ticket-summary {
	position: relative;
	display: block;
}
.c-ticket-summary:not(:last-child) {
	margin-bottom: 10px;
}
.c-ticket-summary__legend {
	font-weight: 600;
	background: #fff;
	padding: 10px 70px 10px 10px;
	border-radius: 10px;
}
.c-ticket-summary__legend span:not(:last-child) {
	margin-right: 10px;
}
.c-ticket-summary__caption {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 100%;
	font-weight: 800;
}
.c-ticket-summary__price {
	display: block;
	font-size: 14px;
}
.c-ticket-summary__fee {
	display: block;
	font-style: normal;
	font-size: 12px;
	opacity: 0.4;
}
.c-ticket-summary .icon-remove {
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 2px;
	margin: auto;
	cursor: pointer;
}
