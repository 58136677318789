.c-quest-modes {
    margin-bottom: 20px;
    border-radius: 5px;
    margin-top: 20px;
}
.c-quest-modes__item {
    margin-bottom: 1rem;
    text-align: left;
    background-color: var(--team-option-bg);
}
.c-quest-modes__item.is-active {
    background-color: var(--team-option-bg-dark);
}
.c-quest-modes__item:not(.is-active):hover {
    background-color: var(--team-option-bg-dark);
}
.c-quest-modes__item__title {
    position: relative;
    padding: 10px 10px 10px 40px;
    font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
}
.c-quest-modes__item.is-active .c-quest-modes__item__title {
    cursor: default;
}
.c-quest-modes__item__title:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1em;
    height: 1em;
    font-size: 20px;
    border-radius: 50%;
    background-color: #ccc;
}
.c-quest-modes__item.is-active .c-quest-modes__item__title:before {
    background-color: var(--accent-dark-color);
}
.c-quest-modes__item__title:after {
    content: '';
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1em;
    height: 1em;
    font-size: 8px;
    border-radius: 50%;
    background-color: var(--checkbox-selection-color);
    visibility: hidden;
}
.c-quest-modes__item.is-active .c-quest-modes__item__title:after {
    visibility: visible;
}
.c-quest-modes__item__title .h-coming {
    width: 100%;
    font-size: 12px;
    text-transform: none;
    width: 100%;
    display: flex;
    align-items: center;
}
.c-quest-modes__item__title .h-coming .fa {
    font-size: 10px;
    margin-right: 5px;
}
.c-quest-modes__item__body {
    padding: 0px 10px 10px 40px;
}
.c-quest-modes__item__body strong {
    display: block;
    margin-bottom: 5px;
}
.c-quest-modes__item__body ul {
    margin-left: -5px;
}
.c-quest-modes__item__body ul li:last-child {
    margin-bottom: 5px;
}
