app-members {
	display: block;
}
@media (max-width: $quest-detail-new-xs-max) {
	app-members .c-article__header {
		flex-wrap: wrap;
   }
}
.c-members__header {
	border-bottom: 2px solid var(--greyColor);
	position: relative;
	padding: 9px 10px;
	z-index: 2;
}
@media (min-width: ($breakpoint-xs + 1px)) {
	.c-members__header {
		padding: 5px 10px 15px 10px;
   }
}
@media (min-width: ($breakpoint-xs + 1px)) {
	.c-members__row {
		align-items: flex-start;
		margin-right: -10px;
		margin-left: -10px;
		flex-wrap: wrap;
		display: flex;
   }
}
.c-members__col {
	width: 100%;
}
@media (min-width: ($breakpoint-xs + 1px)) {
	.c-members__col {
		padding-right: 10px;
		padding-left: 10px;
		width: 50%;
   }
}
@media (max-width: $breakpoint-xs) {
	.c-members__col:not(:last-child) {
		margin-bottom: 10px;
   }
	.c-members__col .c-field__label {
		min-width: 70px;
   }
}
.c-members__col .c-n-form__group {
	margin-bottom: 0;
}
.c-members__inner {
	display: block;
}
.c-members__body {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	font-size: 14px;
	display: block;
	height: 630px !important;
	padding: 0;
}
.c-members__body .ng-scrollbar-wrapper {
	pointer-events: all;
	opacity: 1;
}
@media (min-width: ($breakpoint-xs + 1px)) {
	.c-members__body {
		min-height: 250px;
		max-height: calc(100vh - 250px);
   }
}
@media (max-width: $breakpoint-xs) {
	.c-members__body {
		height: calc(100vh - 250px) !important;
   }
}
.c-members__tabs {
	border-top: 1px solid $petrol-15;
	background-color: #fff;
	display: flex;
}
.c-members__tabs__item {
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 10px 5px;
	cursor: pointer;
	display: flex;
	flex-grow: 1;
}
.c-members__tabs__item > input {
	visibility: hidden;
	position: absolute;
	padding: 0;
	opacity: 0;
	height: 0;
	border: 0;
	margin: 0;
	width: 0;
	left: 0;
	top: 0;
}
.c-members__tabs__item .icon {
	margin-right: 2px;
}
.c-members__tabs__item span {
	padding-top: 0.1em;
}
.c-members__tabs__item:not(:last-child) {
	border-right: 1px solid $petrol-15;
}
.c-members__tabs__item.is-active {
	background-color: var(--petrol);
	color: #fff;
}
.c-members__filters {
	color: rgba(0,0,0,0.7);
	letter-spacing: 0.025em;
	padding-left: 15px;
	font-weight: 700;
	font-size: 10px;
}
@media (max-width: $quest-detail-new-xs-max) {
	.c-members__filters {
		padding-left: 0;
		margin-top: 8px;
		width: 100%;
   }
}
.c-members__filters__trig {
	background-color: #83cad9;
	letter-spacing: 0.025em;
	border-radius: 14px;
	white-space: nowrap;
	font-weight: 700;
	padding: 4px 8px;
	font-size: 10px;
	border: none;
}
.c-members__filters__trig:after {
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	border-top: 5px solid currentColor;
	display: inline-block;
	margin-left: 3px;
	content: '';
	height: 0;
	width: 0;
}
.c-members__filters > span {
	display: inline-block;
	margin-right: 5px;
}
.c-members .c-search__filter {
	border-top: 1px solid $petrol-15;
	background: #fff;
}
.c-members .c-dialog__body {
	background: #fff;
	max-height: none;
	overflow: hidden;
	min-height: 0;
	height: auto;
	padding: 0 40px 40px;
}
@media (min-width: ($breakpoint-xs + 1px)) {
	.c-members .c-dialog__body {
		max-height: none;
		overflow: hidden;
		min-height: 0;
		height: auto;
   }
}
.c-members .c-dialog__body .c-my-friends__item {
	padding-left: 0;
	padding-right: 0;
}
@media (min-width: ($breakpoint-xs + 1px)) {
	.c-members .c-my-friends__item {
		padding-right: 30px;
		padding-left: 30px;
   }
}
.c-members .c-my-friends__item.has-currency {
	padding-bottom: 25px;
}
.c-search__item {
	border-bottom: 1px solid #c5c9cb;
	margin-bottom: 0;
}
.c-search__item:last-child {
	border-bottom: 0;
}
