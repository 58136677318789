.c-dl-edit__input {
	margin: 0 auto;
	padding: 0;
	border: solid 5px #87ffff;
	font-size: 1.4rem;
	font-family: inherit;
	font-weight: inherit;
	appearance: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: block;
	width: 100%;
	padding: 0.5rem;
	font-weight: 400;
	text-transform: none;
	color: var(--outer-space);
	background: #fff;
	transition: border 200ms ease;
	line-height: 1.2;
	text-align: left;
	outline: 0 !important;
	position: relative;
	z-index: 1;
}
.c-dl-edit__input[type=number]::-webkit-inner-spin-button, .c-dl-edit__input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
@media (max-width: $screen-sm-max) {
	.c-dl-edit__input {
		font-size: 1.1rem;
		border-width: 3px;
		padding: 2px;
		width: calc(100% + 8px);
		margin: -5px -4px 0;
   }
}
.c-dl-edit__input.is-invalid {
	border-color: $tabasco;
}
