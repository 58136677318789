.c-dl-search {
	position: relative;
	display: flex;
	height: 100%;
	flex-direction: column;
}
.c-dl-search.has-drop-absolute {
	height: auto;
}
.c-dl-search__header {
	position: relative;
	display: block;
	flex-shrink: 0;
}
.c-dl-search__control {
	display: block;
	background: #ceebf3;
	border: 0;
	height: 7.4rem;
	line-height: 7.4rem;
	width: 100%;
	border-radius: 3.7rem;
	font-size: 4rem;
	color: #168cb2;
	font-weight: 400;
	padding-left: 4rem;
	padding-right: 15rem;
}
.c-dl-search__control::placeholder {
	color: #168cb2;
}
@media (max-width: $screen-xl-max) {
	.c-dl-search__control {
		height: 5rem;
		line-height: 5rem;
		font-size: 3.2rem;
		padding-left: 2rem;
		padding-right: 12rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-search__control {
		height: 3rem;
		line-height: 3rem;
		font-size: 1.8rem;
		padding-left: 1.5rem;
		padding-right: 8rem;
		border-radius: 1.5rem;
   }
}
.c-dl-search__control.is-active {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.c-dl-search__tools {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	display: flex;
	align-items: center;
	padding-right: 3.4rem;
	color: #028bb4;
}
@media (max-width: $screen-xl-max) {
	.c-dl-search__tools {
		padding-right: 2rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-search__tools {
		padding-right: 1rem;
   }
}
.c-dl-search__tool {
	display: block;
}
.c-dl-search__tool:not(:last-child) {
	margin-right: 1.4rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-search__tool:not(:last-child) {
		margin-right: 1rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-search__tool:not(:last-child) {
		margin-right: 0.5rem;
   }
}
.c-dl-search__submit {
	display: block;
	padding: 1rem;
	font-size: 3rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-search__submit {
		font-size: 2rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-search__submit {
		font-size: 1.6rem;
		padding: 0.5rem;
   }
}
.c-dl-search__submit > .icon {
	display: block;
	transform: scaleX(-1);
}
.c-dl-search__done {
	background: #fff;
	border: 1px solid currentColor;
	font-size: 1.9rem;
	font-weight: 700;
	line-height: 1.25;
	padding: 0.5rem 1.8rem;
	border-radius: 1.7rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-search__done {
		font-size: 1.6rem;
		padding: 0.3rem 1.4rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-search__done {
		font-size: 1.4rem;
		padding: 0.2rem 1rem;
   }
}
.c-dl-search__inner {
	height: 0;
	border: 1px solid #ceebf3;
	border-top: 0;
	border-radius: 0 0 3.7rem 3.7rem;
	overflow: hidden;
	flex-grow: 1;
}
.has-drop-absolute .c-dl-search__inner {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 28rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-search__inner {
		border-radius: 0 0 2.5rem 2.5rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-search__inner {
		border-radius: 0 0 1.5rem 1.5rem;
   }
}
.c-dl-search__body {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
}
@media (max-width: 768px) {
	.c-dl-search__body {
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll;
   }
}
.c-dl-search__body.is-loading {
	overflow: hidden;
}
.c-dl-search__body .h-loader__control {
	top: 1px;
	bottom: 1px;
	right: 1px;
	left: 1px;
}
.c-dl-search__list {
	position: relative;
}
.c-dl-search__item {
	margin-bottom: 1px;
	font-size: 1.6rem;
}
.c-dl-search__item:hover {
	background: var(--search-hover-bg);
}
.c-dl-search__item__quest {
	text-decoration: none;
	align-items: center;
	color: inherit;
	display: flex;
	padding: 1rem 0.5rem 1rem 4rem;
}
.c-dl-search__item__quest:hover {
	text-decoration: none;
}
@media (max-width: $screen-xl-max) {
	.c-dl-search__item__quest {
		padding-left: 2rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-search__item__quest {
		padding-left: 1rem;
   }
}
.c-dl-search__item__quest__img {
	background-color: #1f3034;
	background-size: cover;
	border-radius: 0.8rem;
	min-width: 5rem;
	flex-shrink: 0;
	height: 5rem;
	width: 5rem;
}
.c-dl-search__item__quest__title {
	padding-left: 1.5rem;
	font-weight: 600;
	font-size: 1.6rem;
	flex-grow: 1;
}
.c-dl-search__item__friend {
	padding: 1rem 0.5rem 1rem 4rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-search__item__friend {
		padding-left: 2rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-search__item__friend {
		padding-left: 1rem;
   }
}
.search-items-header .c-dl-search__item {
	font-size: 16px;
}
.search-items-header .c-dl-search__item:hover {
	background: var(--tealLightBg);
}
.search-items-header .c-dl-search__item__quest {
	padding: 7px 20px;
	display: flex;
}
.search-items-header .c-dl-search__item__quest:hover {
	color: var(--tealColor);
}
.search-items-header .c-dl-search__item__quest__img {
	min-width: 4rem;
	width: 4rem;
	height: 4rem;
}
.search-items-header .c-dl-search__item__friend {
	padding: 7px 20px;
}
