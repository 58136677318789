.c-account {
    padding-bottom: 40px;
}
.c-account__group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 20px;
}
@media (max-width: $breakpoint-xm) {
    .c-account__group {
        margin-top: 0;
   }
}
.c-account__col {
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;
}
@media (max-width: $breakpoint-xm) {
    .c-account__col {
        width: 100%;
   }
}
.c-account__row {
    color: var(--bg-text-color);
}
.c-account__row:not(:last-child) {
    margin-bottom: 30px;
}
.c-account__row__header {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 0 none;

    .c-account__row__title {
        font-size: 22px;
        font-weight: 700;
    }
    
    p {
        font-size: 14px;
        margin-top: 8px;
    }
    
}
.c-account__row__title {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 0;
    text-transform: none;
    color: var(--bg-text-color);
}
.c-account__row__title .icon {
    font-size: 20px;
    margin-right: 10px;
}
.c-account__row__submit {
    display: flex;
    justify-content: flex-end;
}
.c-account__row__submit .c-btn {
    margin: 0;
    width: 100%;
}
@media (max-width: $breakpoint-xm) {
    .c-account__row__submit .c-btn {
        max-width: 100%;
   }
}
.c-account__row__secured { 
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
        font-size: 16px;
    }
}
.c-account__row__secured__stripe-powered {
    float: right;
}
.c-account__submit {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    border-top: 1px solid rgba(0,0,0,0.1);
}
.c-account__submit .c-btn {
    min-width: 300px;
}
.c-account .c-checkbox, .c-account .c-checkbox__caption {
    color: var(--bg-text-color);
}
.custom-color__container-content {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media (max-width: $breakpoint-xs-max) {
    .custom-color__container-content {
        flex-direction: column;
   }
}
.custom-color__container-content .strong {
    font-weight: 600;
}
.custom-color__container-left {
    width: 40%;

    .c-n-form__group {
        border-bottom: 0 none !important;
    }
}
@media (max-width: $breakpoint-xs-max) {
    .custom-color__container-left {
        width: 100%;
   }
}
.custom-color__container .c-checkbox, .custom-color__container .c-checkbox__caption {
    color: var(--bg-text-color);
}
.custom-color__container-right {
    background: var(--sample-primary-bg);
    padding: 2rem 6rem;
    width: 55%;
    color: var(--sample-bg-text-color);
    border: dashed 1px #e5eaed;
}
@media (max-width: $breakpoint-xs-max) {
    .custom-color__container-right {
        width: 100%;
   }
}
.custom-color__sample-window {
    background: var(--quest-content-bg);
    color: var(--sample-bg-text-color);
}
.custom-color__sample__content {
    padding: 20px;
}
.custom-color__sample-color-box {
    width: 40px;
    height: 40px;
    border: solid 1px #2e3638;
    margin-left: 10px;
    border-radius: 5px;
}
.custom-color .c-quest-tabs-nav {
    background: var(--sample-theme-bg-color);
}
.custom-color .c-quest-tabs-nav__list-item.active::after {
    background: var(--sample-theme-bg-color);
}
.custom-color .c-quest-tabs-nav__list-item:hover {
    color: var(--sample-theme-text-light);
}
.custom-color .c-quest-tabs-nav__list-item:hover::after {
    background: var(--sample-theme-bg-color);
}
.custom-color .c-quest-tabs-nav__list-item a {
    color: var(--sample-theme-text-light);
}
.custom-color .c-quest-tabs-nav__list-item a:active {
    color: var(--sample-theme-text-light);
}
.custom-color .e-btn_dark {
    border-color: var(--sample-theme-bg-color);
    background: var(--sample-theme-bg-color);
    color: var(--sample-theme-text-light);
}
.custom-color .e-btn_dark:active, .custom-color .e-btn_dark:hover {
    border-color: var(--sample-theme-bg-color);
    color: var(--sample-btn-text-color);
}
.custom-color .color-field {
    position: relative;
}
.custom-color .color-field::before {
    content: '#';
    color: inherit;
    position: absolute;
    top: 7px;
    left: 12px;
    font-size: 18px;
    z-index: 2;
}
.custom-color .color-field input {
    padding-left: 25px;
}
.custom-color .btn-container {
    padding: 30px 0 50px 0;
    display: flex;
    justify-content: flex-end;
}
.custom-color .btn-container button {
    margin: 0;
}
.custom-color .btn-container button.c-btn_dark-alternate {
    margin-right: 20px;
}
