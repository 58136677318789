.c-crop-area {
	display: flex;
	justify-content: center;
	font-family: 'Proxima Nova';
	font-size: 14px;
	text-align: center;
	color: var(--accent-color);
}
@media (max-width: breakpoint-modal-sm) {
	.c-crop-area {
		flex-direction: column;
   }
}
.c-crop-area__col {
	padding: 0 10px 10px;
	padding-right: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
}
@media (max-width: breakpoint-modal-sm) {
	.c-crop-area__col {
		max-width: 320px;
		margin: 0 auto;
   }
	.c-crop-area__col:not(:last-child) {
		margin-bottom: 10px;
   }
}
.c-crop-area__title {
	font-size: 18px;
	font-weight: 800;
}
.c-crop-area__body {
	padding-top: 10px;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 353px;
	margin: 0 auto;
	width: 100%;
}
.c-crop-area__body.is-loading {
	min-height: 200px;
}
.c-crop-area__body img {
	max-width: 100%;
	background: $black-50;
}
.c-crop-area__dropzone {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
	width: 100%;
	border: 2px dashed var(--accent-color);
	border-radius: 10px;
	background-color: var(--accent-bg-light-color);
	max-width: 300px;
	margin: 10px auto 10px;
	max-height: 100px;
	font-size: 18px;
	cursor: pointer;
}
.c-crop-area__dropzone:hover {
	background-color: var(--accent-bg-light-color);
}
.c-crop-area__dropzone > input {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	padding: 0;
	margin: 0;
}
.c-crop-area__dropzone__title {
	display: flex;
	align-items: center;
}
.c-crop-area__dropzone__title .fa {
	font-size: 20px;
	margin-right: 10px;
}
.c-crop-area__preview {
	position: relative;
	overflow: hidden;
}
.c-crop-area__preview.is-uploading img {
	filter: blur(10px);
}
.c-crop-area__preview.is-uploading .ng-progress-inline {
	opacity: 1;
}
.c-crop-area__preview:before {
	content: '';
	display: block;
	width: 100%;
	padding-bottom: 100%;
	background-color: $black-40;
}
.c-crop-area__progress-bar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	padding: 1rem;
	visibility: hidden;
	opacity: 0;
}
.is-uploading .c-crop-area__progress-bar {
	visibility: visible;
	opacity: 1;
	transform: translate(0, 0);
}
.c-crop-area__progress-bar__inner {
	height: 1rem;
	width: 100%;
	position: relative;
	border-radius: 1rem;
	background-color: #fff;
	overflow: hidden;
}
.c-crop-area__progress-bar__inner:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 90%;
	height: 100%;
	background-color: shamrock;
	transition: transform 5s ease;
	transform: scaleX(0);
	transform-origin: 0 0;
}
.is-uploading .c-crop-area__progress-bar__inner:before {
	transform: none;
}
.c-crop-area__caption {
	padding-top: 10px;
}
.c-crop-area__footer {
	padding-top: 10px;
}
.c-crop-area__footer__group {
	display: flex;
	margin-left: -5px;
	margin-right: -5px;
}
.c-crop-area__footer__col {
	width: 50%;
	padding-left: 5px;
	padding-right: 5px;
}
.c-crop-area__error {
	color: geraldine;
	font-weight: 800;
	margin-bottom: 10px;
}
.c-crop-area img {
	max-width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	max-width: 100%;
	max-width: 100%;
	width: auto;
	height: auto;
	margin: auto;
	transition: filter 300ms ease;
	max-height: 300px;
}
.c-crop-area__preview {
	overflow: hidden;
	position: relative;
	width: 100%;
	min-width: 200px;
	display: flex;
	align-items: center;
	max-height: 300px;
}
.c-crop-area__rotate {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 30px;
	background-color: $petrol-50;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	height: 1em;
	width: 1em;
}
.c-crop-area__rotate .fa {
	font-size: 20px;
	color: #fff;
}
.cropper-drag-box .cropper-crop-box {
	will-change: height, width, transform;
}
