.e-dl-tool {
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px #000;
	border-radius: 50%;
	width: 3.5rem;
	height: 3.5rem;
	padding: 0;
	margin: 0;
}
@media (max-width: $screen-sm-max) {
	.e-dl-tool {
		width: 2.5rem;
		height: 2.5rem;
   }
}
.e-dl-tool:not(.is-disabled) {
	cursor: pointer;
}
.e-dl-tool:not(.is-disabled):hover {
	background-color: $tealColor;
	color: #fff;
}
.e-dl-tool.is-active {
	background-color: #000;
	color: #fff;
}
.e-dl-tool.is-active:not(.is-disabled) {
	cursor: pointer;
}
.e-dl-tool.is-active:not(.is-disabled):hover {
	background-color: #fff;
	color: $tealColor;
}
.e-dl-tool.is-disabled {
	opacity: 0.2;
	cursor: default;
}
.e-dl-tool:not(:first-child) {
	margin-left: 1rem;
}
@media (max-width: $screen-sm-max) {
	.e-dl-tool:not(:first-child) {
		margin-left: 0.5rem;
   }
}
.e-dl-tool .icon {
	font-size: 2rem;
	pointer-events: none;
}
@media (max-width: $screen-sm-max) {
	.e-dl-tool .icon {
		font-size: 1.2rem;
   }
}
