.c-nl-footer {
	background-color: #295967;
	color: #fff;
	padding-bottom: 6rem;
}
.c-nl-footer__inner {
	padding-bottom: 2rem;
	padding-top: 6rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
@media (max-width: 651px) {
	.c-nl-footer__inner {
		flex-direction: column;
   }
}
.c-nl-footer__inner .left-container {
	width: 49%;
	display: flex;
	flex-direction: row;
}
@media (max-width: 651px) {
	.c-nl-footer__inner .left-container {
		width: 100%;
		margin-bottom: 20px;
   }
}
@media (max-width: 426px) {
	.c-nl-footer__inner .left-container {
		flex-direction: column;
   }
}
.c-nl-footer__inner .left-container .footer-nav {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 43%;
	padding-right: 7%;
	margin-right: 7%;
	border-right: solid 1px #fff;
}
@media (max-width: 651px) {
	.c-nl-footer__inner .left-container .footer-nav {
		width: 100%;
		border-bottom: solid 1px #fff;
   }
	.c-nl-footer__inner .left-container .footer-nav:last-child {
		border-right: none;
		border-bottom: none;
		padding-top: 20px;
   }
}
@media (max-width: 426px) {
	.c-nl-footer__inner .left-container .footer-nav {
		border-right: none;
   }
}
.c-nl-footer__inner .left-container .footer-nav__title {
	margin-bottom: 20px;
	font-weight: 600;
}
.c-nl-footer__inner .left-container .footer-nav li {
	font-size: 16px;
	margin-bottom: 15px;
}
.c-nl-footer__inner .left-container .footer-nav li a {
	color: #fff;
	text-decoration: none;
}
.c-nl-footer__inner .right-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 49%;
	align-items: center;
}
@media (max-width: 651px) {
	.c-nl-footer__inner .right-container {
		width: 100%;
		justify-content: flex-start;
   }
	.c-nl-footer__inner .right-container .img-block img {
		max-height: 90px;
   }
}
.c-nl-footer__inner .right-container .img-block {
	margin-right: 45px;
	margin-bottom: 20px;
}
@media (max-width: 651px) {
	.c-nl-footer__inner .right-container .img-block {
		margin-right: 15px;
   }
}
.c-nl-footer .copyright {
	font-size: 1.4rem;
	text-align: center;
}
.c-nl-footer .social-icons {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 15px;
}
.c-nl-footer .social-icons a {
	display: block;
	margin-right: 10px;
}
.c-nl-footer__nav ul {
	display: flex;
	justify-content: center;
}
@media (min-width: $screen-sm-min) {
	.c-nl-footer__nav ul {
		justify-content: center;
   }
}
.c-nl-footer__nav li:not(:last-child) {
	margin-right: 2rem;
}
@media (min-width: $screen-sm-min) {
	.c-nl-footer__nav li:not(:last-child) {
		margin-right: 6.6rem;
   }
}
.c-nl-footer__nav a {
	display: block;
	font-size: 1.6rem;
	font-weight: 700;
	color: #fff;
	text-decoration: none;
}
.c-nl-footer__nav a:hover {
	color: #fff;
	text-decoration: underline;
}
.c-nl-footer .newsletter-form .form__group {
	margin-bottom: 5px;
}
.c-nl-footer .newsletter-form .form__group:last-child {
	margin-bottom: 0;
}
.c-nl-footer .newsletter-form .form__group .c-n-form__input {
	height: 30px;
}
.c-nl-footer .newsletter-form .form__group .submit-btn {
	padding: 0.5rem 2rem;
}
