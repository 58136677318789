.c-dl-main-nav.is-opened {
	display: block;
}
.c-dl-main-nav > .c-n-modal {
	padding: 0;
	background-color: #fff;
}
.c-dl-main-nav__inner {
	flex-direction: column;
	min-height: 100%;
	padding: 11.5rem 0 5rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-main-nav__inner {
		padding-top: 8rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__inner {
		padding-top: 3.9rem;
		padding-bottom: 3.3rem;
   }
}
.c-dl-main-nav.is-opened .c-dl-main-nav__inner {
	display: flex;
}
.c-dl-main-nav__close {
	position: fixed;
	top: 2.9rem;
	right: 5.4rem;
	display: flex;
	font-size: 2.8rem;
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__close {
		font-size: 1.4rem;
		top: 1rem;
		right: 1.5rem;
   }
}
.c-dl-main-nav__header:not(.is-active) {
	flex-shrink: 0;
}
.c-dl-main-nav__header.is-active {
	flex-grow: 1;
	height: calc(100vh - 40rem);
}
.c-dl-main-nav__header.is-active > .c-dl-main-nav__container {
	height: 100%;
}
.c-dl-main-nav__container {
	max-width: 144rem;
	margin: auto;
	padding-left: 10rem;
	padding-right: 10rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-main-nav__container {
		max-width: 110rem;
		padding-left: 5rem;
		padding-right: 5rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__container {
		padding-left: 3.4rem;
		padding-right: 3.4rem;
   }
}
.c-dl-main-nav__body {
	padding-top: 5rem;
	padding-bottom: 0;
	flex-grow: 1;
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__body {
		padding-top: 4rem;
   }
}
.c-dl-main-nav__row {
	display: flex;
	align-items: flex-start;
	padding: 0 1rem;
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__row {
		padding: 0 0.5rem;
   }
}
.c-dl-main-nav__nav-super {
	display: flex;
	font-size: 4.6rem;
	font-weight: 600;
	flex-grow: 1;
	padding-right: 2rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-main-nav__nav-super {
		font-size: 3.2rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__nav-super {
		font-size: 2.4rem;
		padding-right: 0;
   }
}
.c-dl-main-nav__nav-super li:not(:last-child) {
	margin-bottom: 2.5rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-main-nav__nav-super li:not(:last-child) {
		margin-bottom: 2rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__nav-super li:not(:last-child) {
		margin-bottom: 1.5rem;
   }
}
.c-dl-main-nav__nav-super a {
	display: flex;
	align-items: center;
	text-decoration: none !important;
	color: $tealColor;
}
.c-dl-main-nav__nav-super a:hover {
	color: $casal;
}
.c-dl-main-nav__nav-super .e-oval {
	position: relative;
	top: 0.06em;
	min-width: 1.691em;
	font-size: 2.6rem;
	margin-left: 0.4em;
	width: auto;
	border-radius: 1em;
	padding: 0.731em 0.421em;
	background: $bright-yellow;
	border: 0.3rem solid $tealColor;
}
@media (max-width: $screen-xl-max) {
	.c-dl-main-nav__nav-super .e-oval {
		font-size: 2rem;
		border-width: 0.2rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__nav-super .e-oval {
		font-size: 1.4rem;
		border-width: 0.2rem;
   }
}
.c-dl-main-nav__nav-super .e-oval > span {
	font-size: 0.6em;
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__profile {
		display: none;
   }
}
.c-dl-main-nav__res-super {
	display: flex;
	justify-content: center;
	font-size: 2rem;
	font-weight: 600;
	padding-left: 1rem;
	padding-right: 1rem;
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__res-super {
		font-size: 1.5rem;
		justify-content: center;
		padding-left: 0;
		padding-right: 0;
   }
}
.c-dl-main-nav__res-super ul {
	display: flex;
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__res-super ul {
		min-width: 0;
   }
}
.c-dl-main-nav__res-super li:not(:last-child) {
	padding-right: 2.8rem;
	position: relative;
}
.c-dl-main-nav__res-super li:not(:last-child):after {
	content: '';
	position: absolute;
	top: 0;
	right: 1.4rem;
	height: 100%;
	width: 0.2rem;
	background-color: dusty-gray;
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__res-super li:not(:last-child) {
		padding-right: 2.4rem;
   }
}
.c-dl-main-nav__res-super a {
	display: block;
	color: #000;
	text-decoration: none;
}
.c-dl-main-nav__res-super a:hover {
	text-decoration: underline;
}
@media (min-width: $screen-sm-min) {
	.c-dl-main-nav__sm-d-none {
		display: none;
   }
}
.c-dl-main-nav__footer {
	padding-top: 2rem;
}
@media (max-width: $screen-sm-max) {
	.c-dl-main-nav__footer {
		padding-top: 1rem;
   }
}
