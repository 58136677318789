.c-dropdown {
    position: relative;
    font-family: 'Proxima Nova';
}
.c-dropdown.is-active {
    z-index: 1;
}
.c-dropdown__header {
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    min-height: 40px;
    padding: 0 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0.5px;
    background: $black-40;
    border-radius: 6px;
}
.c-dropdown__header > span {
    margin-right: 10px;
    margin-bottom: -0.1em;
}
.c-dropdown__header__cancel {
    position: absolute;
    top: 3px;
    right: 0;
    padding: 10px;
    z-index: 1;
    color: $cinnabar;
    display: none;
}
.c-dropdown.is-active .c-dropdown__header__cancel {
    display: flex;
}
.c-dropdown__header__cancel .icon-cancel {
    display: block;
}
.c-dropdown__body {
    position: absolute;
    background: #fff;
    border-radius: 4px;
    min-width: 140px;
    right: 0;
    top: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    display: none;
    text-transform: none;
    font-weight: 800;
    box-shadow: 0px 2px 10px -10px #000;
}
.c-dropdown.is-active .c-dropdown__body {
    display: block;
}
.c-dropdown__row {
    width: 100%;
}
.c-dropdown__row:not(:first-child) {
    padding-top: 8px;
}
.c-dropdown__row:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid purpley-grey;
}
.c-dropdown__item {
    display: block;
    padding: 6px 14px;
    font-size: 14px;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
}
.c-dropdown__item:hover {
    color: var(--petrol);
}
.c-dropdown__item.is-flex {
    display: flex;
    align-items: center;
    line-height: 1;
}
.c-dropdown__item.is-red {
    color: $cinnabar;
    &:hover {
        color: darken($cinnabar, 10%);
    }
}
.c-dropdown__item > .fa {
    font-size: 1.2em;
    margin-right: 0.2em;
}
.c-dropdown__item > .icon {
    font-size: 1.2em;
    margin-right: 0.2em;
}
.c-dropdown__item .icon-danger {
    color: $cinnabar;
}
.c-dropdown__item .icon-pencil {
    font-size: 1em;
}
.c-dropdown__item .icon-double-angle {
    font-size: 0.8em;
    margin-left: 2px;
    margin-right: 5px;
}
.c-dropdown__item .icon-copy {
    font-size: 0.9em;
    margin-left: 2px;
    margin-right: 4px;
}
