.c-header {
	background-color: #fff;
	width: 100%;
	height: auto;
	position: fixed;
	top: 0;
	left: 0;
	color: #028ab4;
	z-index: 12;
}
.c-header.position-static {
	position: static;
}
.c-header__container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@media (max-width: $breakpoint-sm) {
	.c-header__container {
		flex-wrap: wrap;
   }
}
.c-header__logo {
	display: flex;
	align-items: center;
	font-size: 22px;
	color: currentColor;
	text-decoration: none !important;
}
.c-header__logo:hover {
	color: var(--petrol);
}
.c-header__title {
	font-family: 'Proxima Nova Rg';
	font-weight: 800;
	text-transform: uppercase;
}
.c-header__tools {
	display: flex;
	justify-content: center;
	margin-right: -1rem;
}
@media (max-width: $breakpoint-sm) {
	.c-header__tools {
		margin-left: auto;
   }
}
.c-header__tool {
	width: 1em;
	height: 1em;
	font-size: 40px;
	position: relative;
	cursor: pointer;
	border: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
	border-radius: 0;
	margin-bottom: 0;
	background-color: transparent;
}
.c-header__tool.has-notification .notify-icon, .c-header__tool.has-notification:after {
	content: '';
	width: 1em;
	height: 1em;
	background: $bright-yellow;
	border: 1px solid var(--orient);
	position: absolute;
	top: 50%;
	right: 50%;
	border-radius: 50%;
	font-size: 8px;
	margin-top: -1.7em;
	margin-right: -2.2em;
}
.c-header__tool.has-notification .notify-icon {
	background-color: transparent;
	border: 0;
}
.c-header__tool.has-notification.has-notify-icon:after {
	display: none;
}
.c-header__tool:hover {
	color: var(--petrol);
}
.c-header__tool .icon {
	font-size: 20px;
}
.c-header__burger {
	position: relative;
	width: 1em;
	height: 0.74em;
	font-size: 2.8rem;
	display: flex;
	align-items: center;
}
.c-header__burger span {
	display: block;
	width: 100%;
	height: 0.4rem;
	border-radius: 0.2rem;
	background-color: currentColor;
}
.c-header__burger span:before, .c-header__burger span:after {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	height: 0.4rem;
	border-radius: 0.2rem;
	background-color: currentColor;
}
.c-header__burger span:before {
	top: 0;
}
.c-header__burger span:after {
	bottom: 0;
}
.c-header__user {
	position: relative;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
}
.c-header__user.has-notification .icon-user-active {
	font-size: 10px;
	margin-top: -1.2em;
	margin-right: -1.1em;
	color: #006a86;
}
.c-header__quick-search {
	display: flex;
	align-items: center;
	padding: 0 10px;
	margin-left: auto;
}
@media (max-width: $breakpoint-sm) {
	.c-header__quick-search {
		width: calc(100% + 20px);
		order: 1;
		margin: 0 -10px;
		background-color: #daeff4;
		border-top: 1px solid #68c3d8;
		padding: 8px 10px 9px;
   }
	.c-header__quick-search.with-place-for-filter {
		padding-right: 40px;
   }
}
.c-header__quick-search__form {
	position: relative;
}
@media (max-width: $breakpoint-sm) {
	.c-header__quick-search__form {
		width: 100%;
   }
}
.c-header__quick-search__submit {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 30px;
	height: 100%;
	cursor: pointer;
	border: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
	border-radius: 0;
	margin-bottom: 0;
	background-color: transparent;
	font-size: 14px;
}
.c-header__quick-search__control {
	background: transparent;
	width: 300px;
	height: 22px;
	border: 0;
	outline: 0;
	background-color: rgba(2,138,180,0.3);
	border-radius: 12px;
	padding: 0 32px 0 12px;
	font-size: 12px;
	line-height: 22px;
	font-family: 'Proxima Nova Rg';
	font-weight: 400;
}
@media (max-width: $breakpoint-sm) {
	.c-header__quick-search__control {
		width: 100%;
   }
}
.c-header__quick-search__control::placeholder {
	color: var(--petrol);
}
.c-header__quick-search__control:focus, .c-header__quick-search__control:hover {
	background-color: rgba(2,138,180,0.5);
}
