.c-dl-profile-thumb {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 30rem;
	padding: 5.3rem 2rem 4.5rem;
	box-sizing: border-box;
	border-radius: 1.2rem;
	margin-right: 0.5rem;
	font-size: 2rem;
	background: alabaster;
	color: $casal;
}
@media (max-width: $screen-xl-max) {
	.c-dl-profile-thumb {
		width: 25rem;
		font-size: 1.8rem;
		padding-top: 3rem;
		padding-bottom: 3rem;
   }
}
.c-dl-profile-thumb__avatar {
	font-size: 15.8rem;
	margin-bottom: 3.3rem;
	border-width: 0.6rem;
	border-color: $tealColor;
}
@media (max-width: $screen-xl-max) {
	.c-dl-profile-thumb__avatar {
		font-size: 12rem;
		border-width: 0.4rem;
   }
}
.c-dl-profile-thumb__title {
	font-size: 3rem;
	margin-bottom: 1rem;
	font-weight: 500;
	max-width: 100%;
	text-align: center;
}
@media (max-width: $screen-xl-max) {
	.c-dl-profile-thumb__title {
		font-size: 2.6rem;
   }
}
.c-dl-profile-thumb__tool {
	color: $casal;
	text-decoration: none !important;
	opacity: 0.7;
}
.c-dl-profile-thumb__tool:not(:last-child) {
	margin-bottom: 0.4rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-profile-thumb__tool:not(:last-child) {
		margin-bottom: 0.3rem;
   }
}
.c-dl-profile-thumb__tool:hover {
	opacity: 1;
}
