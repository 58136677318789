body .c-nl-header {
	width: 100%;
	display: block;
	background-color: #fff;
}
body .c-nl-header .flex-1 {
	flex: 1;
}
body .c-nl-header .flex-2 {
	flex: 1.7;
}
body .c-nl-header .flex-3 {
	flex: 1.3;
}
body .c-nl-header .mobile-menu-options {
	display: none;
	overflow-y: auto;
}
body .c-nl-header .mobile-menu-options.is-active {
	padding: 0 0 10px 0;
	box-shadow: 0 5px 4px 1px rgba(0,0,0,0.2);
}
@media (max-width: 769px) {
	body .c-nl-header .mobile-menu-options {
		display: block;
   }
}
body .c-nl-header .mobile-menu-options .sub-menu-options {
	padding-left: 10px;
}
body .c-nl-header .mobile-menu-options .sub-menu-options li a {
	text-transform: none !important;
	font-weight: 400 !important;
}
body .c-nl-header .mobile-menu-options .sub-menu-opened {
	position: relative;
	background: #f5f7f8;
}
body .c-nl-header .mobile-menu-options .sub-menu-opened > a {
	background: #e5eaed;
}
body .c-nl-header .mobile-menu-options .sub-menu-opened .icon-back_arrow {
	position: absolute;
	right: 10px;
	top: 10px;
	transform: rotate(90deg);
	font-size: 24px;
	z-index: 9;
}
body .c-nl-header .mobile-menu-options li a.blue-text {
	color: $tealColor !important;
}
@media (max-width: 768px) {
	body .c-nl-header .web-menu-options {
		display: none;
   }
}
@media (min-width: 769px) {
	body .c-nl-header {
		padding-top: 1.7rem;
		padding-bottom: 1.7rem;
		color: #2e3638;
   }
}
body .c-nl-header__logo-burger-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
}
@media (max-width: 769px) {
	body .c-nl-header__logo-burger-container {
		padding: 20px 20px 20px 13px;
   }
}
@media (min-width: 769px) {
	body .c-nl-header__inner {
		display: flex;
   }
}
@media (max-width: 769px) {
	body .c-nl-header__inner {
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
   }
}
body .c-nl-header__logo {
	margin-right: 40px;
}
body .c-nl-header__logo .icon {
	cursor: pointer;
}
@media (min-width: $screen-sm-min) {
	body .c-nl-header__logo {
		padding: 0;
		height: 30px;
		padding-top: 0.3rem;
   }
	body .c-nl-header__logo .icon {
		display: block;
		width: 11.6rem;
		font-size: 3rem;
   }
}
@media (max-width: $screen-sm-max) {
	body .c-nl-header__logo {
		top: 14px;
		left: 13px;
		font-size: 2rem;
		color: $casal;
   }
	body .c-nl-header__logo .icon {
		display: block;
   }
}
body .c-nl-header__svg-icon {
	cursor: pointer;
}
body .c-nl-header__svg-icon svg {
	width: 30px;
	height: 30px;
}
body .c-nl-header__svg-icon.bell svg {
	width: 24px;
}
@media (min-width: $screen-sm-min) {
	body .c-nl-header__nav {
		font-size: 1.6rem;
		font-weight: 700;
		height: auto;
   }
}
@media (min-width: $screen-sm-min) {
	body .c-nl-header__nav__body {
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		height: 100%;
   }
	body .c-nl-header__nav__body li:not(:last-child) {
		margin-right: 2.9rem;
   }
	body .c-nl-header__nav__body a {
		color: inherit;
		display: block;
		text-decoration: none;
   }
	body .c-nl-header__nav__body a:hover {
		color: var(--accent-color);
		text-decoration: underline;
   }
	body .c-nl-header__nav__body a.active-link {
		text-decoration: underline;
   }
}
@media (max-width: 768px) {
	body .c-nl-header__nav__body {
		overflow: hidden;
		max-height: 0;
		transition: max-height 300ms ease;
   }
	body .c-nl-header__nav__body.is-active {
		max-height: 32rem;
   }
	body .c-nl-header__nav__body a {
		display: block;
		font-size: 1.2em;
		font-weight: 800;
		color: var(--outer-space);
		text-decoration: none !important;
		text-transform: uppercase;
		padding: 1.1em 20px;
   }
}
body .c-nl-header__nav__body.justify-right {
	justify-content: flex-end;
}
body .c-nl-header__nav__body .notifications-drodown-options {
	width: 410px !important;
	padding: 30px 0;
}
body .c-nl-header__nav__body .notifications-drodown-options__header {
	display: flex;
	flex-direction: row;
	padding: 0 26px;
}
body .c-nl-header__nav__body .notifications-drodown-options__header h2 {
	font-size: 22px;
	text-transform: none;
	font-weight: 700;
	margin: 0;
	color: #2e3638;
}
body .c-nl-header__nav__body .notifications-drodown-options__body {
	padding: 0;
	margin-bottom: 0;
	height: auto;
	max-height: 50rem;
}
body .c-nl-header__nav__body .notifications-drodown-options, body .c-nl-header__nav__body .profile-drodown-options {
	position: absolute;
	right: -14px;
	background: #fff;
	width: 210px;
	z-index: 9;
	border-radius: 5px 0 5px 5px;
	padding-bottom: 10px;
	box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.2);
	top: calc(100% + 5px);
}
body .c-nl-header__nav__body .notifications-drodown-options li, body .c-nl-header__nav__body .profile-drodown-options li {
	padding: 10px 20px;
	margin-right: 0 !important;
	position: relative;
}
body .c-nl-header__nav__body .notifications-drodown-options li:last-child, body .c-nl-header__nav__body .profile-drodown-options li:last-child {
	border-bottom: none;
}
body .c-nl-header__nav__body .notifications-drodown-options li:hover, body .c-nl-header__nav__body .profile-drodown-options li:hover {
	background: #e5eaed;
}
body .c-nl-header__nav__body .notifications-drodown-options li a, body .c-nl-header__nav__body .profile-drodown-options li a {
	font-size: 18px;
	font-weight: 600;
}
body .c-nl-header__nav__body .notifications-drodown-options li a:hover, body .c-nl-header__nav__body .profile-drodown-options li a:hover {
	text-decoration: none;
}
body .c-nl-header__nav__body .notifications-drodown-options li.profile-info, body .c-nl-header__nav__body .profile-drodown-options li.profile-info {
	padding: 20px;
	height: 80px;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	border-bottom: solid 1px #c5c9cb;
	font-size: 18px;
	font-weight: 600;
}
body .c-nl-header__nav__body .notifications-drodown-options li.profile-info:hover, body .c-nl-header__nav__body .profile-drodown-options li.profile-info:hover {
	background: none;
}
body .c-nl-header__nav__body .notifications-drodown-options li.profile-info span, body .c-nl-header__nav__body .profile-drodown-options li.profile-info span {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
	margin-left: 50px;
	white-space: nowrap;
}
body .c-nl-header__nav__body .profile-menu-elem .c-nl-header__svg-icon.opened {
	display: block;
}
body .c-nl-header__nav__body .profile-menu-elem .c-nl-header__svg-icon.opened::before {
	content: '';
	background: #fff;
	width: 58px;
	height: 44px;
	display: block;
	position: absolute;
	top: -9px;
	left: -14px;
	z-index: 10;
	box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
	border-radius: 10px 10px 0 0;
}
body .c-nl-header__nav__body .profile-menu-elem .c-nl-header__svg-icon.opened::after {
	content: '';
	height: 9px;
	background: #fff;
	width: 58px;
	z-index: 12;
	position: absolute;
	bottom: -9px;
	left: -14px;
}
body .c-nl-header__nav__body .profile-menu-elem .profile-icon-small {
	position: relative;
	z-index: 11;
}
body .c-nl-header__nav__body.web-menu-options {
	margin-right: 40px;
}
@media (min-width: $screen-sm-min) {
	body .c-nl-header__nav__btn {
		border-radius: 5px;
		background-color: #2e3638;
		padding: 0.8rem 1.1rem;
		color: #fff !important;
		text-decoration: none !important;
		min-width: 125px;
		text-align: center;
		line-height: 16px;
		border: solid 2px #2e3638;
		font-size: 16px;
   }
	body .c-nl-header__nav__btn:hover {
		color: #fff !important;
		opacity: 0.8;
   }
	body .c-nl-header__nav__btn.btn-alternate {
		background: none;
		color: #2e3638 !important;
   }
}
@media (min-width: $screen-sm-min) and (max-width: 1000px) {
	body .c-nl-header__nav__btn {
		min-width: 50px;
   }
}
body .c-nl-header__nav__burger {
	padding: 0;
}
@media (min-width: 769px) {
	body .c-nl-header__nav__burger {
		display: none;
   }
}
@media (max-width: 769px) {
	body .c-nl-header__nav__burger:focus {
		outline: 0;
   }
}
body .c-nl-header__nav__burger.search {
	margin-right: 20px;
}
body .c-nl-header__nav__burger.search .icon {
	width: 25px;
	height: 25px;
}
body .c-nl-header__nav .search-bar {
	min-width: 240px;
	flex-grow: 1;
	display: flex;
	justify-content: flex-end;
}
body .c-nl-header__nav .search-bar .input-parent {
	width: 250px;
	position: relative;
	transition: all 0.3s;
}
body .c-nl-header__nav .search-bar .input-parent.opened {
	width: 100%;
}
body .c-nl-header__nav .search-bar .input-parent.opened .c-n-form__input {
	border-radius: 5px 5px 0 0;
	box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.5);
}
body .c-nl-header__nav .search-bar .input-parent.opened .icon-cancel-rounded-corner {
	display: block;
}
body .c-nl-header__nav .search-bar .search-items-parent {
	position: absolute;
	width: 100%;
	background: var(--white);
	min-height: 100px;
	max-height: 260px;
	overflow-y: auto;
	top: 34px;
	box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.5);
	border-radius: 0 0 5px 5px;
	z-index: 6;
	padding: 13px 0;
}
body .c-nl-header__nav .search-bar .search-items-parent app-friends-item .c-my-friends__item__caption {
	color: var(--outer-space);
}
body .c-nl-header__nav .search-bar .search-items-parent app-friends-item .c-my-friends__item__title:hover {
	color: var(--tealColor);
}
body .c-nl-header__nav .search-bar .c-n-form__input {
	border-radius: 5px;
	text-indent: 15px;
	height: 34px;
	border-color: #6d787e;
	margin: 0;
}
body .c-nl-header__nav .search-bar .search-gray {
	position: absolute;
	top: calc(50% - 8px);
	left: 10px;
	z-index: 6;
}
body .c-nl-header__nav .search-bar .icon-cancel-rounded-corner {
	position: absolute;
	right: 15px;
	top: 10px;
	z-index: 6;
	width: 15px;
	height: 15px;
	cursor: pointer;
	display: none;
}
body .c-nl-header__nav.search-profile-option-parent .c-nl-header__nav__body li:not(:last-child) {
	margin-right: 2rem;
}
body .c-nl-header__nav.search-profile-option-parent .c-nl-header__nav__body li.search-bar {
	margin-right: 4rem;
}
body .c-nl-header__nav .position-relative {
	position: relative;
}
body .c-nl-header__nav .notification-icon-parent .e-oval {
	position: absolute;
	background: $tealColor;
	font-size: 12px;
	color: #fff;
	top: 4px;
	right: -3px;
}
body .c-nl-header__nav .notification-icon-parent .c-nl-header__svg-icon.opened {
	display: block;
}
body .c-nl-header__nav .notification-icon-parent .c-nl-header__svg-icon.opened::before {
	content: '';
	background: #fff;
	width: 52px;
	height: 44px;
	display: block;
	position: absolute;
	top: -9px;
	left: -14px;
	z-index: 10;
	box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
	border-radius: 10px 10px 0 0;
}
body .c-nl-header__nav .notification-icon-parent .c-nl-header__svg-icon.opened::after {
	content: '';
	height: 9px;
	background: #fff;
	width: 52px;
	z-index: 12;
	position: absolute;
	bottom: -9px;
	left: -14px;
}
body .c-nl-header__nav .notification-icon-parent .c-nl-header__svg-icon .notification-bell {
	position: relative;
	z-index: 11;
}
body .c-nl-header__profile__img {
	position: absolute;
	top: 20px;
	left: 20px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid #fff;
	margin-right: 10px;
}
body .mobile-search-page {
	background: #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 39;
	height: 100vh;
}
body .mobile-search-page__header {
	display: flex;
	flex-direction: row;
	padding: 10px 25px;
	border-bottom: solid 1px #c5c9cb;
	align-items: center;
}
body .mobile-search-page__header .back-btn {
	margin-right: 22px;
}
body .mobile-search-page__header .input-container {
	width: 100%;
	position: relative;
}
body .mobile-search-page__header .input-container .c-n-form__input {
	width: 100%;
	background: #f5f7f8;
	border: 0 none;
	border-radius: 5px;
	padding: 0 35px;
}
body .mobile-search-page__header .input-container .icon {
	position: absolute;
	z-index: 2;
}
body .mobile-search-page__header .input-container .icon.search-gray {
	left: 12px;
	top: 12px;
	width: 15px;
	height: 15px;
	transform: rotate(90deg);
}
body .mobile-search-page__header .input-container .icon.icon-cancel-rounded-corner {
	width: 16px;
	height: 16px;
	right: 10px;
	top: 13px;
}
body .mobile-search-page__body {
	overflow-y: auto;
	height: calc(100vh - 60px);
}
