$iconWidth: 30px;

.c-page__header {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}
.c-page__header.is-thin {
    padding-top: 10px;
    padding-bottom: 10px;
}
.c-page__cover {
    position: relative;
    height: 200px;
    margin: 0;
}
.c-page__cover .h-object-fit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.c-page__cover.min-height-cont {
    height: 640px;
}
@media (max-width: $breakpoint-xs-max) {
    .c-page__cover {
        display: none;
   }
}
.c-page__title {
    font-size: 16px;
    display: flex;
    justify-content: center;
    color: var(--bg-text-color);
}
.c-page__title .icon {
    font-size: 0.74em;
    margin-right: 8px;
    margin-left: -1em;
}
.c-page__title > span {
    margin-bottom: -0.1em;
    margin-top: -0.1em;
    display: block;
}
.c-page__sub-title {
    font-size: 16px;
    margin: 0 auto;
    margin-top: 10px;
    color: var(--bg-text-color);
}
.c-page__steps {
    max-width: 330px;
    margin: 20px auto 0;
}
.c-page__steps__list {
    display: flex;
    justify-content: center;
    list-style-type: none;
}
.c-page__steps__list > li {
    flex-grow: 1;
    color: #fff;
    position: relative;
}
.c-page__steps__list > li.is-active {
    color: var(--petrol);
}
.c-page__steps__list > li.is-active:not(:first-child):before {
    background-image: linear-gradient(to right, petrol 60%, rgba(255,255,255,0) 0%);
}
.c-page__steps__list > li:not(:first-child):before {
    content: '';
    position: absolute;
    right: calc(50% + 25px);
    top: 15px;
    height: 2px;
    background-image: linear-gradient(to right, currentColor 60%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 9px 2px;
    background-repeat: repeat-x;
    width: calc(100% - 50px);
}
.c-page__steps__list > li > a {
    position: relative;
    display: block;
    text-decoration: none !important;
    color: currentColor;
    min-height: 60px;
    width: 100%;
    cursor: pointer;
}
.c-page__steps__list > li > a.is-disabled {
    pointer-events: none;
}
.c-page__steps__list > li > a > span {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    text-align: center;
    display: block;
    color: currentColor;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 0.4px;
}
.c-page__steps__icon {
    width: 1em;
    height: 1em;
    font-size: 30px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.is-active .c-page__steps__icon {
    border-color: var(--petrol);
}
.is-valid .c-page__steps__icon {
    background-color: var(--petrol);
    color: #fff;
    border-color: var(--petrol);
}
.is-valid .c-page__steps__icon .icon-checked {
    display: block;
}
.c-page__steps__icon .icon-checked {
    font-size: 15px;
    display: none;
}
