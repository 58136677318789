.c-avatar {
    position: relative;
    width: 1em;
    height: 1em;
    font-size: 50px;
    display: block;
    overflow: hidden;
    border-radius: 50%;
    background-color: #fff;
}
.c-avatar.q-d-page {
    width: 120px;
    height: 120px;
}
.c-avatar.thin-border {
    border-width: 1px;
}
.c-avatar__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-size: cover;
    background-position: center center;
    color: #000;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.4em;
}

.c-team-avatar {
    position: relative;
    width: 1em;
    height: 1em;
    font-size: 50px;
    display: block;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
    border: solid 1px var(--accent-color);
    &__linked-quest {
        border-color: var(--accent-dark-color);
    }
    &.q-d-page {
        width: 120px;
        height: 120px;
    }
    &.thin-border {
        border-width: 1px;
    }
    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-size: cover;
        background-position: center center;
        color: #000;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: .4em;
    }
}
