// card specific properties
$CARD_RADIUS: .8rem;
// card specific category colors
$CARD_CATEGORY_ENVIRONMENTAL: #295967;
$CARD_CATEGORY_PHYSICAL: #168cb2;
$CARD_CATEGORY_MENTAL: #3caac5;
$CARD_CATEGORY_SOCIAL: #437b8a;
$CARD_CATEGORY_FINANCIAL: #6d787e;
$CARD_CATEGORY_OCCUPATIONAL: #2e3638;

.c-card {
	display: block;
	position: relative;
	font-family: 'Proxima Nova';
	border-radius: $CARD_RADIUS;
	border: solid 1px #eaebeb;
	box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.03);
}
.c-card__image {
	display: block;
	position: relative;
}
.c-card__image > .h-object-fit {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: $CARD_RADIUS $CARD_RADIUS 0 0;
}
.c-card__image > .h-object-fit img:after {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: alabaster;
	content: attr(alt);
	color: dove-gray;
	font-size: 0.9em;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.c-card__image:after {
	content: '';
	display: block;
	padding-bottom: 56.6%;
}
.c-card__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 2.5em;
	color: #fff;
	font-size: 10px;
	padding: 0 1.5em;
}
.c-card__header__tools {
	display: flex;
}
.c-card__header__tool {
	display: block;
	color: #fff;
	cursor: pointer;
}
.c-card__header__tool:hover {
	transform: scale(1.1);
}
.c-card__header__tool:not(:last-child) {
	margin-right: 1em;
}
.c-card__header__tool .icon {
	display: block;
	font-size: 1.8em;
}
.c-card__category {
	display: flex;
	align-items: center;
}
.c-card__category > .icon {
	margin-right: 0.5em;
}
.c-card__category > span {
	line-height: 1;
	margin-bottom: -0.2em;
}
.c-card__body {
	display: flex;
	padding: 1em 0;
}
.c-card__statistic {
	flex-shrink: 0;
	padding-left: 1em;
	padding-right: 1em;
	border-right: solid 1px rgba(46,54,56,0.07);
	min-width: 60px;
	font-size: 10px;
}
.c-card__statistic > li {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}
.c-card__statistic > li:not(:last-child) {
	margin-bottom: 1em;
}
.c-card__statistic > li > .icon {
	font-size: 1.8em;
}
.c-card__statistic > li > span {
	font-size: 0.9em;
	line-height: 1;
	text-transform: uppercase;
	margin-top: 0.3em;
}
.c-card__caption {
	padding-left: 1em;
	padding-right: 1em;
	flex-grow: 1;
}
.c-card__title {
	font-size: 1em;
	font-weight: 800;
	line-height: 1.2;
	color: var(--outer-space);
	text-decoration: none;
}
.c-card__title:hover {
	color: $casal;
	text-decoration: underline;
}
.c-card__title > a {
	display: block;
	color: inherit;
	text-decoration: inherit;
	font-size: 20px;
	height: 23px;
	line-height: 1.1;
}
.c-card__description {
	font-size: 1.2rem;
	line-height: 1.2;
	color: var(--outer-space);
}
.c-card__description .js-three-dots-container {
	height: 3.7em;
	margin-top: 0.6em;
	word-break: break-word;
	overflow: hidden;
}
.c-p-card {
	overflow: hidden;
	position: relative;
}
.c-p-card:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(90deg, rgba(0,0,0,0), rgba(255,255,255,0.3), rgba(0,0,0,0));
	transform: translateX(-100%);
	animation: loading 1.5s infinite;
	z-index: 1;
}
.c-p-card__image .icon-photo-of-a-landscape {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	font-size: 10em;
	color: tower-gray;
}
.c-p-card__header {
	background-color: geyser;
}
.c-p-card__header__tool {
	background-color: #fff;
	width: 1em;
	height: 1em;
}
.c-p-card__category {
	height: 1em;
	background-color: #fff;
	width: 20%;
}
.c-p-card__statistic {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	min-height: 8.9em;
}
.c-p-card__statistic > li {
	align-items: flex-start;
}
.c-p-card__statistic > li:before, .c-p-card__statistic > li:after {
	content: '';
	background-color: tower-gray;
	height: 0.3em;
}
.c-p-card__statistic > li:before {
	width: 80%;
	margin-bottom: 0.4em;
}
.c-p-card__statistic > li:after {
	width: 100%;
}
.c-p-card__title {
	height: 4.6em;
}
.c-p-card__title:after, .c-p-card__title:before {
	content: '';
	display: block;
	height: 1em;
	width: 80%;
	background-color: tower-gray;
}
.c-p-card__title:before {
	width: 50%;
	margin-bottom: 1em;
}
.c-p-card__description {
	padding-top: 0.6em;
}
.c-p-card__description span {
	display: block;
	height: 0.3em;
	width: 100%;
	background-color: tower-gray;
}
.c-p-card__description span:not(:last-child) {
	margin-bottom: 0.5em;
}
.c-p-card__description span:nth-child(1) {
	width: 60%;
}
.c-p-card__description span:nth-child(2) {
	width: 40%;
}
.c-p-card__description span:nth-child(3) {
	width: 80%;
}
@-moz-keyframes loading {
	100% {
		transform: translateX(100%);
   }
}
@-webkit-keyframes loading {
	100% {
		transform: translateX(100%);
   }
}
@-o-keyframes loading {
	100% {
		transform: translateX(100%);
   }
}
@keyframes loading {
	100% {
		transform: translateX(100%);
   }
}
