$MILESTONE_PREVIEW_MARGIN_TOP: 4.2rem;
$MILESTONE_PREVIEW_MARGIN_TOP_SM: 3rem;

.c-dl-milestone-preview {
	position: absolute;
	top: 0;
	left: 0.5rem;
	right: 0.5rem;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone-preview {
		left: 0;
   }
}
.c-dl-milestone-preview__container {
	display: flex;
	height: 100%;
	background-color: #ceeff6;
	overflow: visible;
}
.c-dl-milestone-preview__header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.c-dl-milestone-preview__close {
	position: absolute;
	right: 0;
	top: 0;
	font-size: 1rem;
	display: flex;
	padding: 1rem;
}
.c-dl-milestone-preview__oval {
	display: block;
	font-size: 6.4rem;
	background-color: #fff;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone-preview__oval {
		font-size: 3.5rem;
   }
}
.c-dl-milestone-preview__body {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: 7rem;
	width: 26rem;
	padding: 2rem;
	border-radius: 0.9rem;
	background: #fff;
	z-index: 2;
	display: none;
	box-shadow: 0 2px 6px -4px #000;
	margin-top: -($MILESTONE_PREVIEW_MARGIN_TOP + $MILESTONE_PREVIEW_MARGIN_TOP / 2);
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone-preview__body {
		width: 21rem;
		padding: 1rem;
		margin-left: 3.5rem;
		margin-top: -($MILESTONE_PREVIEW_MARGIN_TOP_SM + $MILESTONE_PREVIEW_MARGIN_TOP_SM / 2);
   }
}
.c-dl-milestone-preview__body:before {
	content: '';
	position: absolute;
	right: 100%;
	top: $MILESTONE_PREVIEW_MARGIN_TOP;
	width: 0;
	height: 0;
	border-style: solid;
	border-color: transparent #fff transparent transparent;
	border-width: ($MILESTONE_PREVIEW_MARGIN_TOP / 2) ($MILESTONE_PREVIEW_MARGIN_TOP * 0.4) ($MILESTONE_PREVIEW_MARGIN_TOP / 2) 0;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone-preview__body:before {
		top: $MILESTONE_PREVIEW_MARGIN_TOP_SM;
		border-width: ($MILESTONE_PREVIEW_MARGIN_TOP_SM / 2) ($MILESTONE_PREVIEW_MARGIN_TOP_SM * 0.4) ($MILESTONE_PREVIEW_MARGIN_TOP_SM / 2) 0;
	}
}
.open .c-dl-milestone-preview__body {
	display: block;
}
.c-dl-milestone-preview__inner {
	margin-bottom: -2rem;
}
.c-dl-milestone-preview__icon-control {
	font-size: 2.5rem;
	display: flex;
	color: #fff;
	min-width: 13rem;
}
.c-dl-milestone-preview__control {
	position: relative;
}
.c-dl-milestone-preview__control__trigger {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0.5rem;
	margin: auto;
	font-size: 3rem;
	z-index: 1;
	background-color: $tealColor;
	color: #fff;
}
.c-dl-milestone-preview__control__trigger:hover {
	background-color: $shakespeare;
}
.c-dl-milestone-preview__control__trigger .icon {
	margin-right: -0.2em;
}
.c-dl-milestone-preview__input {
	padding-right: 4rem;
	text-overflow: ellipsis;
}
.c-dl-milestone-preview__legend {
	display: flex;
}
.c-dl-milestone-preview__legend .icon {
	margin-right: 0.5em;
}
.c-dl-milestone-preview__link-quest-dialog {
	background: #f00;
}
.c-dl-milestone-preview__link-quest-dialog .c-dl-search__header {
	margin: 0 4rem 1rem 4rem;
}
@media (max-width: $screen-xl-max) {
	.c-dl-milestone-preview__link-quest-dialog .c-dl-search__header {
		margin: 0 2rem 1rem 2rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone-preview__link-quest-dialog .c-dl-search__header {
		margin: 0 1rem 0.5rem 1rem;
   }
}
.c-dl-milestone-preview__link-quest-dialog .c-dl-search__inner {
	border-color: transparent;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.c-dl-milestone-preview__link-quest-dialog .c-n-modal__content {
	height: 100%;
	max-height: 100%;
}
.c-dl-milestone-preview__link-quest-dialog .c-n-modal__layout {
	height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
}

