body .c-dl-ns-feedback {
    position: relative;
    display: block;
    margin-top: 20px;
}
.c-dl-ns-post__replies body .c-dl-ns-feedback {
    padding-left: 8rem;
}
@media (max-width: $screen-md-max) {
    .c-dl-ns-post__replies body .c-dl-ns-feedback {
        padding-left: 6rem;
   }
}
@media (max-width: $screen-xs-max) {
    .c-dl-ns-post__replies body .c-dl-ns-feedback {
        padding-left: 2rem;
   }
}
body .c-dl-ns-feedback__inner {
    display: flex;
    align-items: flex-start;
}
body .c-dl-ns-feedback__profile {
    flex-shrink: 0;
    margin-right: 1rem;
}
@media (max-width: $screen-xs-max) {
    body .c-dl-ns-feedback__profile {
        font-size: 3rem;
        margin-right: 0.5rem;
   }
}
body .c-dl-ns-feedback__avatar {
    border: none;
    font-size: 4rem;
}
@media (max-width: $screen-xs-max) {
    body .c-dl-ns-feedback__avatar {
        font-size: 3rem;
   }
}
body .c-dl-ns-feedback__ctrl {
    display: block;
    flex-grow: 1;
}
body .c-dl-ns-feedback__input {
    border-radius: 10px !important;
    background-color: var(--feedback-bg) !important;
    resize: none;
    border: none !important;
    flex-grow: 1;
}
body .c-dl-ns-feedback__input:focus-visible {
    outline: none;
}
body .c-dl-ns-feedback__submit {
    padding-top: 10px;
}
body .c-dl-ns-feedback__oval {
    font-size: 2rem;
    background-color: #fff;
    border: solid 1px dusty-gray;
}
body .c-dl-ns-feedback__oval:not(:first-child) {
    margin-left: 1rem;
}
body .c-dl-ns-feedback__oval .icon {
    font-size: 0.6em;
}
body .c-dl-ns-feedback__oval .icon-arrow_right {
    font-size: 0.9em;
    margin-right: -0.2em;
}
body .c-dl-ns-feedback__oval .h-xs-loader__control:after {
    width: 0.5em;
    height: 0.5em;
}
