// ! WARNING
// ! THIS STYLES IS DEPRECATED, and it will be removed in the future when all old components will be migrated to new styles
// ! An alternative file is called - "c-n-form.style"

@import "variables";


textarea.c-forms__input {
	min-width: 100%;
	max-width: 100%;
}
.c-forms__group {
	display: block;
	margin-bottom: 10px;
}
.c-forms__group.mt-40 {
	margin-top: 40px;
}
.c-forms__group.mb-20 {
	margin-bottom: 20px;
}
.c-forms__group.has-video {
	position: relative;
	padding-right: 70px;
}
.c-forms__group.is-flexed {
	display: flex;
	align-items: center;
}
.c-forms__group.is-flexed .c-checkbox {
	margin-bottom: 0;
}
.c-forms__group.is-flexed .e-question {
	margin-left: 10px;
}
.c-forms__video {
	width: 60px;
	height: 55px;
	position: absolute;
	overflow: hidden;
	border-radius: 5px;
	margin-left: auto;
	top: 0;
	right: 0;
	background-size: cover;
	background-position: center center;
}
.c-forms__video:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $black-50;
	border-radius: 5px;
}
.c-forms__video .fa {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	color: #fff;
	font-size: 20px;
	width: 1em;
	height: 1em;
	margin: auto;
	text-align: center;
}
.c-forms__video img {
	max-width: 100%;
}
.c-forms__hr {
	padding-top: 10px;
	padding-bottom: 10px;
	position: relative;
}
.c-forms__hr:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 1px;
	background-color: currentColor;
	margin: auto;
}
.c-forms__legend {
	display: block;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 800;
	text-transform: uppercase;
	padding: 10px 0;
	letter-spacing: 0.5px;
	color: var(--bg-text-color);
}
.c-forms__legend.no-top-pad {
	padding-top: 0;
}
.c-forms__legend.is-center {
	text-align: center;
}
.c-forms__tools {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 10px;
	margin-top: 20px;
}
.c-forms__tools .c-forms__legend {
	padding: 0;
}
.c-forms__tool {
	display: block;
	text-decoration: none !important;
	color: var(--petrol);
	font-size: 12px;
	font-weight: 800;
	text-transform: uppercase;
	padding: 10px 0;
	letter-spacing: 0.5px;
}
.c-forms__tool:hover {
	color: var(--petrol);
}
.c-forms__control {
	position: relative;
	display: block;
	font-weight: inherit;
	margin-bottom: 0;
}
.c-forms__control.has-icon .c-forms__input {
	padding-left: 40px;
}
.c-forms__control.has-icon-right .c-forms__input {
	padding-right: 40px;
}
.c-forms__control.is-required .c-forms__input {
	padding-right: 40px;
}
.c-forms__control.is-required:after {
	content: '*';
	position: absolute;
	top: 0;
	bottom: 0;
	right: 5px;
	font-size: 30px;
	width: 1em;
	height: 1em;
	text-align: center;
	line-height: 1.45em;
	margin: auto;
	color: $petrol-30;
}
.c-forms__control.is-select > .icon-dots {
	position: absolute;
	right: 13px;
	top: 0;
	bottom: 0;
	font-size: 12px;
	margin: auto;
	color: #fff;
}
.c-forms__control.is-textarea.is-required:after {
	top: 10px;
	bottom: auto;
}
.c-forms__control.is-editor:after {
	top: 12px;
	bottom: auto;
}
.c-forms__control.is-editor.is-required .ql-editor {
	padding: 0;
}
.c-forms__control.is-editor.is-required .ql-editor.ql-blank::before {
	right: 40px;
	font-weight: 800;
}
.c-forms__control__icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	padding: 0 5px;
	font-size: 12px;
	color: var(--petrol);
}
.c-forms__control__icon.is-lg {
	font-size: 20px;
}
.c-forms__control__icon.is-light {
	color: $petrol-50;
}
.c-forms__control__icon.is-right {
	left: auto;
	right: 0;
}
.c-forms__control__icon.is-ghost {
	pointer-events: none;
}
.c-forms__input {
	margin: 0 auto;
	padding: 0;
	border: 0;
	font-size: 15px;
	font-family: inherit;
	font-weight: inherit;
	appearance: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: block;
	width: 100%;
	padding: 15px 20px;
	border-radius: 8px;
	font-weight: 800;
	background: white-50;
	text-transform: none;
	color: var(--bg-text-color);
	border: 2px solid #9dbcc3;
	transition: border 200ms ease;
	line-height: 1.2;
	border-radius: 3px;
	text-align: left;
}
.c-forms__input[type=number]::-webkit-inner-spin-button, .c-forms__input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.c-forms__input:disabled:read-only {
	-webkit-text-fill-color: #fff;
	opacity: 1;
}
.c-forms__input.is-darkness {
	background: blue-stone-15;
}
.c-forms__input.is-darkness::placeholder {
	color: rgba(71,121,133,0.8);
}
.c-forms__input.is-thin {
	padding: 6px 10px;
}
.c-forms__input.min-h-0 {
	min-height: 0;
}
.c-forms__input.min-h-0.is-thin {
	padding-top: 15px;
	padding-bottom: 0;
}
.c-forms__input.has-submit-btn {
	padding-right: 40px;
}
.c-forms__input.is-focus, .c-forms__input:focus {
	background: white-70;
}
.c-forms__input.is-focus:not(.has-placeholder-on-focus)::placeholder, .c-forms__input:focus:not(.has-placeholder-on-focus)::placeholder {
	visibility: hidden;
}
.c-forms__input::placeholder {
	color: bali-hai;
}
.c-forms__input:-webkit-autofill {
	background-color: transparent;
}
.c-forms__control.is-textarea .c-forms__input {
	max-width: 100%;
	min-width: 100%;
	min-height: 50px;
}
.form-is-submitted .c-forms__input.ng-invalid {
	border-color: puce;
}
.c-forms__input.is-invalid {
	border-color: puce;
}
.c-forms__message {
	font-size: 14px;
	padding-top: 5px;
	padding-bottom: 5px;
	color: $bright-yellow;
	display: none;
}
.form-is-submitted .c-forms__message {
	display: block;
}
.c-forms__message.is-block {
	display: block;
}
.c-forms__message.is-red {
	color: $tabasco;
}
.c-forms__message.is-black {
	color: #000;
}
.c-forms__message.is-default {
	color: var(--petrol);
}
.c-forms__message.is-under, .c-forms__message.is-bottom {
	padding-bottom: 0;
	padding-top: 10px;
}
.c-forms__message.is-top {
	padding-top: 0;
	padding-bottom: 10px;
}
.c-forms__message.text-right {
	text-align: right;
}
.c-forms__submit {
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	width: 1em;
	height: 1em;
	font-size: 40px;
	align-items: center;
	justify-content: center;
	color: var(--petrol);
	background-color: transparent;
	border: 0;
	border-radius: 0;
	text-decoration: none !important;
	padding: 0;
	margin-bottom: 0;
}
.c-forms__submit .icon {
	font-size: 30px;
}
.c-forms__submit.is-middle {
	top: 0;
	margin: auto;
}
.c-forms__submit.is-loading {
	opacity: 1;
	pointer-events: none;
}
.c-forms__submit.is-loading .fa, .c-forms__submit.is-loading .icon {
	opacity: 0;
}
.c-forms__submit.is-loading:before {
	content: '';
	position: absolute;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	border: 2px solid currentColor;
	border-bottom-color: transparent;
	display: block;
	-webkit-animation: rotating 2s linear infinite;
	animation: rotating 2s linear infinite;
	display: block;
	font-size: 20px;
}
.c-forms__submit .icon-remove {
	font-size: 20px;
}
.c-forms__drop {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	color: #fff;
}
.c-forms__drop .c-dropdown__header {
	padding: 18px;
	font-size: 16px;
}
.c-forms__drop.is-active .c-dropdown__header {
	z-index: 1;
	color: #000;
	position: relative;
}
.c-forms__drop .c-dropdown__body {
	top: 6px;
	right: 5px;
}
.c-forms__drop .c-dropdown__item {
	padding-right: 40px;
}
.c-forms__info {
	font-size: 10px;
	text-align: right;
	text-transform: uppercase;
	padding-top: 10px;
}
.c-forms__check-list > .c-forms__check-list {
	padding-left: 45px;
}
.c-forms__check-control {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.c-forms__check-control > .tooltips {
	margin-top: 6px;
}
.c-forms__empty-info {
	background: rgba(255,255,255,0.2);
}
.c-forms__empty-info:focus {
	background: rgba(255,255,255,0.2);
}
.c-forms__row {
	display: flex;
	align-items: flex-start;
	margin-left: -5px;
	margin-right: -5px;
}
.c-forms__col {
	width: 50%;
	padding-left: 5px;
	padding-right: 5px;
}
.c-forms__counter {
	display: block;
	text-align: right;
	font-size: 12px;
	font-weight: 300;
	line-height: 20px;
}
.c-forms__counter--invalid {
	color: $tabasco;
	font-weight: normal;
}
[disabled] {
	pointer-events: none;
	opacity: 0.7;
}
.is-required-label:after {
	content: '*';
	padding-left: 2px;
	color: inherit;
}
