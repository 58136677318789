.c-explore {
	font-family: 'Proxima Nova';
}
@media (max-width: $breakpoint-sm) {
	.c-explore {
		padding: 40px 0 0;
		min-height: calc(100vh - 40px);
   }
}
@media (min-width: breakpoint-sm1px) {
	.c-explore__loader .h-loader__control {
		background-color: transparent;
   }
}
.c-explore__loader .h-loader__caption .container {
	position: static;
	min-height: 0;
}
.c-explore__loader .h-loader__caption .container:before, .c-explore__loader .h-loader__caption .container:after {
	display: none;
}
.c-explore__body {
	display: flex;
	flex-wrap: wrap;
	margin-left: -5px;
	margin-right: -5px;
}
.c-explore__inner {
	position: relative;
	min-height: calc(100vh - 99px);
	padding: 0 0 10px;
}
.c-explore__inner .h-loader__control {
	position: fixed;
	top: 100px;
}
@media (max-width: $breakpoint-sm) {
	.c-explore__inner {
		padding: 10px 0;
		min-height: calc(100vh - 80px);
   }
	.c-explore__inner .h-loader__control {
		z-index: 10;
		top: 81px;
   }
}
.c-explore__item {
	width: 50%;
	padding-left: 5px;
	padding-right: 5px;
	margin-bottom: 10px;
}
@media (max-width: $breakpoint-xm) {
	.c-explore__item {
		width: 100%;
   }
}
.c-explore__empty {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}
.c-explore__search {
	flex-grow: 1;
	display: flex;
	align-items: center;
	position: relative;
}
.c-explore__search .icon-search {
	position: absolute;
	top: 50%;
	left: 0;
	font-size: 1.12em;
	margin-top: -0.5em;
	color: $petrol-70;
}
@media (max-width: $breakpoint-sm) {
	.c-explore__search .icon-search {
		color: #028ab4;
		left: 10px;
   }
}
.c-explore__search__control {
	background: transparent;
	border: 0;
	outline: 0;
	border-radius: 12px;
	font-size: 20px;
	font-family: inherit;
	line-height: 1.25;
	font-weight: 800;
	width: 100%;
	height: auto;
	padding: 18px 15px 17px 35px;
	color: var(--petrol);
}
@media (max-width: $breakpoint-sm) {
	.c-explore__search__control {
		padding: 11px 15px 12px 36px;
		font-size: 14px;
		font-weight: 500;
   }
}
.c-explore__search__control::placeholder {
	color: var(--petrol);
}
.c-explore__search__control:focus, .c-explore__search__control:hover {
	background-color: transparent;
}
.c-explore__filter {
	display: block;
}
.c-explore__filter .container {
	min-height: 0;
}
.c-explore__filter .container:before, .c-explore__filter .container:after {
	display: none;
}
.c-explore__filter__inner.friends-search-filter {
	padding-right: 15px;
	padding-left: 15px;
}
@media (max-width: $breakpoint-sm) {
	.c-explore__tools__item__header {
		display: none;
   }
	.c-explore__tools__item__header.is-mobile {
		display: block;
   }
}
.c-explore__tools__item__title {
	font-weight: 800;
	font-size: 20px;
	white-space: nowrap;
	position: relative;
	display: block;
	padding: 10px 20px 10px 0;
}
.c-explore__tools__item__list {
	padding-left: 0;
	list-style-type: none;
	margin-bottom: 0;
}
.c-explore__tools__li {
	display: block;
	position: relative;
	margin: auto;
	font-weight: inherit;
	font-size: inherit;
	position: relative;
	padding-right: 30px;
	cursor: pointer;
}
.c-explore__tools__li.is-hovered {
	background-color: $petrol-15;
}
.c-explore__tools__li.is-hovered, .c-explore__tools__li:hover {
	color: var(--petrol);
}
.c-explore__tools__li:not(:last-child) {
	border-bottom: 1px solid rgba(207,209,214,0.3);
}
.c-explore__tools__li input[type='checkbox'] {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	margin: 0;
	padding: 0;
	border: 0;
	visibility: hidden;
	opacity: 0;
}
.c-explore__tools__li input[type='checkbox']:checked ~ .icon-checked {
	visibility: visible;
	opacity: 1;
}
.c-explore__tools__li > .icon-checked {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 5px;
	margin: auto;
	font-size: 12px;
	visibility: hidden;
	opacity: 0;
}
@media (max-width: $breakpoint-sm) {
	.c-explore__tools__li > .icon-checked {
		font-size: 14px;
   }
}
.c-explore__tools__li__caption {
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px 10px 10px 10px;
}
.c-explore__tools__li__caption.has-icon {
	padding-left: 30px;
}
.c-explore__tools__li__caption.has-icon .icon {
	position: absolute;
	left: 10px;
	top: 0;
	bottom: 0;
	margin: auto auto auto -0.5em;
}
@media (max-width: $breakpoint-sm) {
	.c-explore__tools__li__caption.has-icon .icon {
		font-size: 18px;
   }
}
@media (max-width: $breakpoint-sm) {
	.c-explore__tools__li__caption.has-icon .icon-mental, .c-explore__tools__li__caption.has-icon .icon-friends, .c-explore__tools__li__caption.has-icon .icon-occupational, .c-explore__tools__li__caption.has-icon .icon-environmental {
		font-size: 16px;
   }
}
.c-explore__tools__li__title {
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.5px;
}
.c-explore__tools__li__title:first-letter {
	text-transform: uppercase;
}
.c-explore__footer {
	display: flex;
	justify-content: center;
	padding-top: 10px;
}
@media (min-width: breakpoint-sm1px) {
	.c-explore__filter {
		display: block;
   }
	.c-explore__filter__inner {
		min-height: 0;
		display: flex;
   }
	.c-explore__tools {
		margin-right: -10px;
		padding: 10px 0;
   }
	.c-explore__tools__trigger {
		display: none;
   }
	.c-explore__tools__header {
		display: flex;
		padding-right: 10px;
   }
	.c-explore__tools__title {
		display: none;
   }
	.c-explore__tools__submit {
		font-family: 'Proxima Nova';
		margin: 0;
		padding: 0;
		border: 0;
		font-size: inherit;
		text-transform: none;
		background: none;
		color: inherit;
		font-weight: 600;
		display: flex;
		align-items: center;
   }
	.c-explore__tools__submit.is-mobile {
		display: none;
   }
	.c-explore__tools__submit .icon {
		margin-right: 10px;
   }
	.c-explore__tools__body {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 0 10px;
		flex-grow: 1;
   }
	.c-explore__tools__item {
		position: relative;
		display: flex;
		align-items: center;
   }
	.c-explore__tools__item__header {
		cursor: pointer;
		position: relative;
   }
	.c-explore__tools__item__header.is-mobile {
		display: none;
   }
	.c-explore__tools__item__header:before {
		content: '';
		position: absolute;
		top: 0;
		left: -8px;
		right: -8px;
		bottom: 0;
		background: alabaster;
		border-radius: 4px 4px 0 0;
		display: none;
   }
	.c-explore__tools__item__header:after {
		content: '';
		position: absolute;
		top: 100%;
		left: -8px;
		right: -8px;
		height: 12px;
		margin-top: -9px;
		background: alabaster;
		z-index: 12;
		display: none;
   }
	.c-explore__tools__item.is-active .c-explore__tools__item__header:before, .c-explore__tools__item.is-active .c-explore__tools__item__header:after {
		display: block;
   }
	.c-explore__tools__item__title {
		color: var(--petrol);
   }
	.c-explore__tools__item.is-active .c-explore__tools__item__title {
		color: inherit;
   }
	.c-explore__tools__item__title:after {
		content: '';
		position: absolute;
		top: 0;
		right: 2px;
		bottom: 4px;
		margin: auto;
		width: 1em;
		height: 1em;
		transform: rotate(45deg);
		border-bottom: 2px solid $petrol-70;
		border-right: 2px solid $petrol-70;
		vertical-align: top;
		font-size: 10px;
   }
	.c-explore__tools__item__body {
		position: absolute;
		top: 100%;
		left: -8px;
		min-width: calc(100% + 8px);
		margin-top: -1px;
		background-color: alabaster;
		padding: 10px 8px 10px;
		border-radius: 0 4px 4px 4px;
		opacity: 0;
		visibility: hidden;
		transition: all 100ms ease;
		z-index: 10;
   }
	.c-explore__tools__item.is-right .c-explore__tools__item__body {
		left: auto;
		right: -8px;
		border-radius: 4px 0 4px 4px;
   }
	.c-explore__tools__item.is-active .c-explore__tools__item__body {
		opacity: 1;
		visibility: visible;
   }
}
@media (max-width: $breakpoint-sm) {
	.c-explore__filter {
		position: fixed;
		top: 40px;
		left: 0;
		width: 100%;
		padding: 0 40px 0 0;
		background-color: #daeff4;
		border-top: 1px solid #68c3d8;
		z-index: 12;
   }
	.c-explore__filter__inner {
		margin-left: -10px;
		margin-right: -10px;
   }
	.c-explore__tools {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
		background-color: rgba(255,255,255,0.95);
		font-family: 'Proxima Nova';
		line-height: 1.25;
		color: #005166;
		z-index: 12;
		display: none;
   }
	.c-explore__tools.is-active {
		display: block;
   }
	.c-explore__tools__trigger {
		position: absolute;
		right: 30px;
		top: 0;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 22px;
		z-index: 10;
		height: 40px;
		cursor: pointer;
		color: #028ab4;
   }
	.c-explore__tools__header {
		display: flex;
		position: relative;
		justify-content: center;
		padding: 10px;
		font-size: 16px;
		background-color: #fff;
		color: #028ab4;
		z-index: 1;
   }
	.c-explore__tools__title {
		text-transform: uppercase;
		font-weight: 800;
		display: flex;
		align-items: center;
   }
	.c-explore__tools__title .icon {
		margin-right: 10px;
   }
	.c-explore__tools__submit {
		font-family: 'Proxima Nova';
		margin: 0;
		padding: 0;
		border: 0;
		font-size: inherit;
		text-transform: none;
		background: none;
		color: inherit;
		position: absolute;
		display: flex;
		align-items: center;
		top: 0;
		right: 0;
		height: 100%;
		padding-right: 10px;
		font-size: 16px;
		font-weight: 800;
		text-decoration: none !important;
		text-transform: uppercase;
		color: var(--petrol);
		width: auto;
   }
	.c-explore__tools__body {
		overflow: auto;
		font-size: 14px;
		height: calc(100vh - 40px);
		padding: 10px;
   }
	.c-explore__tools__item__title {
		padding: 10px 0;
   }
}
@media (max-width: $breakpoint-xm-max) {
	.c-explore__tools__trigger {
		right: 0;
   }
}
