.c-quest-tabs__header {
    display: flex;
    padding: 0 10px;
}
.c-quest-tabs__header.has-align-center {
    display: flex;
    justify-content: center;
}
.c-quest-tabs__item {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    padding: 13px 10px 10px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    justify-content: center;
    width: 100%;
    max-width: 130px;
    color: #000;
    border-bottom: 2px solid transparent;
}
.c-quest-tabs__item.is-full {
    max-width: none;
}
.c-quest-tabs__item:hover, .c-quest-tabs__item:active, .c-quest-tabs__item:focus {
    color: #000;
}
.c-quest-tabs__item.is-active {
    border: 2px solid #000;
    border-top: none;
}
.c-quest-tabs__item .icon {
    margin-right: 5px;
    font-size: 0.82em;
}
.c-quest-tabs__item .icon.is-lg {
    font-size: 1.4em;
}
.c-quest-tabs__item span {
    margin-bottom: -0.2em;
    margin-top: -0.14em;
}
.c-quest-tabs__tab {
    display: block;
}
@media (max-width: 769px) {
    .c-quest-tabs__tab {
        flex-direction: column-reverse;
   }
}
.c-quest-tabs__column {
    float: left;
    width: 50%;
}
@media (max-width: $screen-sm-max) {
    .c-quest-tabs__column {
        width: 100%;
   }
}
.c-quest-tabs__admins {
    clear: left;
}
.c-quest-tabs__content {
    padding: 0;
}
@media (max-width: $screen-sm-max) {
    .c-quest-tabs__content {
        padding-left: 10px;
        padding-right: 10px;
   }
}
.c-quest-tabs__content .details-tab__left {
    background: var(--primary-bg-secondary);
    flex: 2.5;
}
@media (max-width: $screen-sm-max) {
    .c-quest-tabs__content .details-tab__left {
        background: tealLightBg;
   }
    .c-quest-tabs__content .details-tab__left .quest-info-box {
        background: #fff;
        padding: 20px 25px;
        border-radius: 5px;
   }
}
.c-quest-tabs__content .details-tab__left .quest-info-title {
    display: none;
    font-size: 22px;
    color: $greyDarkColor;
    line-height: 22px;
    padding: 0 25px 10px;
    font-weight: 600;
}
@media (max-width: $screen-sm-max) {
    .c-quest-tabs__content .details-tab__left .quest-info-title {
        display: block;
   }
}
.c-quest-tabs__content .details-tab__left .c-quest__article__gallery, .c-quest-tabs__content .details-tab__left .c-quest__members-list, .c-quest-tabs__content .details-tab__left .c-quest__team-list {
    display: none;
}
@media (max-width: $screen-sm-max) {
    .c-quest-tabs__content .details-tab__left .c-quest__article__gallery, .c-quest-tabs__content .details-tab__left .c-quest__members-list, .c-quest-tabs__content .details-tab__left .c-quest__team-list {
        display: block;
        margin-top: 30px;
   }
}
.c-quest-tabs__content .details-tab__right {
    flex: 1;
    background: var(--quest-detail-right);
    padding: 40px 20px;
    color: $greyDarkColor;
    max-width: 380px;
}
@media (max-width: 769px) {
    .c-quest-tabs__content .details-tab__right {
        max-width: 100%;
        margin-bottom: 20px;
   }
}
@media (max-width: $screen-sm-max) {
    .c-quest-tabs__content .details-tab__right {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        padding-top: 30px;
        padding-bottom: 30px;
   }
    .c-quest-tabs__content .details-tab__right .c-quest__article__gallery, .c-quest-tabs__content .details-tab__right .c-quest__members-list, .c-quest-tabs__content .details-tab__right .c-quest__team-list {
        display: none;
   }
}
.c-quest-tabs__content .details-tab__right .c-quest__article__gallery {
    margin-top: 40px;
}
