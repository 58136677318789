body a {
	color: $link-color;
	text-decoration: underline;
	cursor: pointer;
}
body a:active, body a:hover {
	text-decoration: none;
	color: var(--accent-color);
}
body .h-typography {
	font-family: 'Proxima Nova';
	line-height: 1.25;
	font-weight: 400;
	font-style: normal;
	word-wrap: break-word;
}
body .h-typography h1, body .h-typography h2, body .h-typography h3, body .h-typography h4, body .h-typography h5 {
	text-transform: uppercase;
	margin-bottom: 10px;
	font-weight: 900;
}
body .h-typography h1 {
	font-size: 24px;
}
@media (max-width: $breakpoint-xm) {
	body .h-typography h1 {
		font-size: 20px;
   }
}
body .h-typography h2 {
	font-size: 20px;
}
@media (max-width: $breakpoint-xm) {
	body .h-typography h2 {
		font-size: 18px;
   }
}
body .h-typography h3 {
	font-size: 16px;
}
@media (max-width: $breakpoint-xm) {
	body .h-typography h3 {
		font-size: 14px;
   }
}
body .h-typography h4 {
	font-size: 14px;
}
@media (max-width: $breakpoint-xm) {
	body .h-typography h4 {
		font-size: 12px;
   }
}
body .h-typography h5 {
	font-size: 12px;
}
@media (max-width: $breakpoint-xm) {
	body .h-typography h5 {
		font-size: 10px;
   }
}
body .h-typography em, body .h-typography i {
	font-style: italic;
}
body .h-typography p {
	margin-bottom: 0;
}
body .h-typography ul {
	padding-left: 15;
	list-style-type: none;
}
body .h-typography ul li {
	padding-left: 20px;
	position: relative;
}
body .h-typography ul li:before {
	content: '';
	position: absolute;
	top: 6px;
	left: 0;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: currentColor;
}
body .h-typography ol {
	counter-reset: list;
	list-style-type: none;
	margin: 0;
	padding: 0;
}
body .h-typography ol li {
	padding-left: 20px !important;
	position: relative;
	counter-increment: list;
}
body .h-typography ol li:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	content: counter(list) ".";
}
body .h-typography .ql-video {
	display: block;
	max-width: 100%;
}
