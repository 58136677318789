.c-quest-donate {
    font-weight: 400;
    line-height: 1.2;
    font-size: 14px;
    display: block;
}
.c-quest-donate__progress-block {
    padding-bottom: 0;
}
@media only screen and (min-width: $quest-detail-new-sm-min) {
    .c-quest-donate__progress-block {
        flex-grow: 1;
   }
}
.c-quest-donate__progress-block__total {
    font-size: 12px;
    line-height: 22px;
    color: var(--bg-text-color);
    font-weight: 400;
}
.c-quest-donate__progress-block__total p {
    margin-bottom: 10px;
}
.c-quest-donate__progress-block__total__amount {
    font-size: 14px;
    line-height: 36px;
}
.c-quest-donate__progress-block__total__amount b {
    font-size: 22px;
    font-weight: 700;
}
.c-quest-donate__progress-block__range {
    align-items: center;
    position: relative;
    margin-top: 5px;
    margin-bottom: 30px;
    display: flex;
}
.c-quest-donate__progress-block__range.has-trigger {
    padding-right: 50px;
}
.c-quest-donate__progress-block__range__trigger {
    background-color: #ffea00;
    position: relative;
    border-radius: 50%;
    margin-left: 16px;
    color: #295966;
    flex-shrink: 0;
    border: none;
    height: 24px;
    width: 24px;
}
.c-quest-donate__progress-block__range__trigger:before, .c-quest-donate__progress-block__range__trigger:after {
    background-color: currentColor;
    position: absolute;
    display: block;
    margin: auto;
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.c-quest-donate__progress-block__range__trigger:before {
    height: 14px;
    width: 2px;
}
.c-quest-donate__progress-block__range__trigger:after {
    height: 2px;
    width: 14px;
}
.c-quest-donate__progress-block__progress {
    background-color: var(--ghostGrey);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    height: 22px;
}
.c-quest-donate__progress-block__progress > span {
    background-color: var(--accent-dark-color);
    border-radius: 5px;
    position: absolute;
    max-width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.c-quest-donate__progress-block__total-desc {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    color: var(--bg-text-color);
}
.c-quest-donate__progress-block__desc {
    font-size: 12px;
    color: var(--bg-text-color);
}
.c-quest-donate__buttons-block {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.c-quest-donate__buttons-block.flex-direction-column {
    flex-direction: column;
}
.c-quest-donate__buttons-block button {
    display: block;
    margin: 0;
    max-width: 48%;
    width: 100%;
}
.c-quest-donate__buttons-block button.mq-donate-btn {
    min-width: 270px !important;
}
.c-quest-donate__buttons-block app-ui-select-team {
    min-width: 270px;
    position: relative;
    margin-bottom: 0;
    display: block;
    margin-top: 10px;
}
.c-quest-donate__share-block, .c-quest-donate__log-act-btn {
    position: relative;
}
.c-quest-donate__share-block .e-btn .icon, .c-quest-donate__log-act-btn .e-btn .icon {
    position: absolute;
    right: 13px;
    fill: var(--accent-color);
}
.c-quest-donate__share-block app-quest-header-tools .c-quest-detail__header__dropdown .c-quest-detail__header__dropdown__menu, .c-quest-donate__log-act-btn app-quest-header-tools .c-quest-detail__header__dropdown .c-quest-detail__header__dropdown__menu {
    left: 0;
    right: unset;
}
@media (min-width: 650px) {
    .c-quest-donate__log-act-btn {
        display: none;
   }
}
.c-quest-donate__ttl {
    flex-direction: row;
    color: #000;
    margin-top: 3px;
    font-size: 22px;
    display: flex;
}
.c-quest-donate__info {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    margin-top: 8px;
}
