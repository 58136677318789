.c-quest-descption {
    display: block;
}
.c-quest-descption__header {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}
.c-quest-descption__category {
    color: #000;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
}
.c-quest-descption__date {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
}
.c-quest-descption__title {
    width: 100%;
    margin-top: 10px;
    font-weight: 900;
    margin-bottom: 0;
    text-transform: uppercase;
}
.c-quest-descption__caption {
    padding-top: 0;
    position: relative;
    font-size: 14px;
}
.c-quest-descption__caption__inner {
    overflow: hidden;
    position: relative;
}
.c-quest-descption__trigger {
    cursor: pointer;
}
.c-quest-descption .h-typography + .c-quest-descption__trigger {
    text-align: left;
    margin: 10px auto 0 0;
    width: auto;
}
.c-quest-descption__typo {
    color: var(--bg-text-color);
}
@media (min-width: $quest-detail-new-sm-min) {
    .c-quest-descption__typo {
        margin-top: 0 !important;
   }
}
.c-quest-descption__typo[aria-expanded="false"] {
    position: relative;
}
