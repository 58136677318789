body .c-gallery {
  margin-left: -10px;
  margin-right: -10px;
}
body .c-gallery__item {
  display: block;
  position: relative;
}
body .c-gallery__item__inner {
  display: block;
  overflow: hidden;
  border-radius: 4px;
  text-decoration: none !important;
  color: inherit;
}
body .c-gallery__item__figure {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
body .c-gallery__item__delete {
  position: relative;
  right: -2px;
  bottom: 32px;
  background: rgba(255,255,255,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 16px;
  z-index: 2;

  &__thumb {
    position: relative;

    .icon-play-btn {
      width: 50px;
      height: 50px;
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      cursor: pointer;
    }
  }
}
@media (min-width: 800px) {
  body .c-gallery__item__thumb {
    height: 300px;
 }
  body .c-gallery__item__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
 }
}
@media (min-width: 0px) and (max-width: 799px) {
  body .c-gallery__item__thumb {
    height: 75px;
 }
  body .c-gallery__item__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
 }
}
body .c-gallery .slide {
  display: block;
  width: auto;
  margin: 0;
}
body .c-gallery .slick-slide {
  padding-left: 10px;
  padding-right: 10px;
}
body .c-gallery .slick-dots {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 5px 0;
  justify-content: center;
  list-style-type: none;
  margin-bottom: 0;
}
body .c-gallery .slick-dots li {
  display: none;
}
body .c-gallery .slick-dots li.slick-active {
  display: inline-flex;
}
body .c-gallery .slick-track {
  margin: 0;
}
body .c-gallery figcaption {
  display: none;
}
body .c-gallery__empty {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px dashed var(--accent-dark-color);
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  font-size: 14px;
}
body .c-gallery__empty::before {
  content: '';
  width: 100%;
  height: 100%;
  z-index: 1;
  background: var(--accent-color);
  opacity: 0.15;
  position: absolute;
}
body .c-gallery__empty__overwrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
body .c-gallery__empty__link {
  background: transparent;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: -1000px;
  margin: 0;
  border: 0;
  cursor: pointer;
  z-index: 2;
}
body .c-gallery__empty .icon {
  font-size: 15rem;
  color: #6d787e;
  z-index: 2;
}
@media (max-width: 750px) {
  body .c-gallery__empty .icon {
    font-size: 8rem;
 }
}
body .c-gallery__tools {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 2rem;
  color: var(--bg-text-color);
}
body .c-gallery__tools__caption {
  display: block;
  padding: 0 1rem;
}
body .c-gallery__tools__arrow-left.slick-disabled, body .c-gallery__tools__arrow-right.slick-disabled {
  visibility: hidden;
}
