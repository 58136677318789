.e-hamburger {
	position: relative;
	display: block;
	width: 25px;
	height: 18px;
	font-size: 1em;
	top: 3px;
}
.e-hamburger.is-active .e-hamburger__inner {
	transition-delay: 0.22s;
	background-color: transparent !important;
}
.e-hamburger.is-active .e-hamburger__inner:before {
	top: 0;
	transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
	transform: translate3d(0, 10px, 0) rotate(45deg);
}
.e-hamburger.is-active .e-hamburger__inner:after {
	top: 0;
	transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
	transform: translate3d(0, 10px, 0) rotate(-45deg);
}
.e-hamburger__inner {
	display: block;
	transition: background-color 0s linear 0.13s;
}
.e-hamburger__inner:after, .e-hamburger__inner:before {
	display: block;
	content: "";
}
.e-hamburger__inner:before {
	top: 1em;
	transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.e-hamburger__inner:after {
	bottom: -1em;
	top: 2em;
	transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.e-hamburger__inner, .e-hamburger__inner:after, .e-hamburger__inner:before {
	position: absolute;
	width: 25px;
	height: 0.4em;
	transition-timing-function: ease;
	transition-duration: 0.15s;
	transition-property: background-color, transform;
	border-radius: 0.4em;
	background-color: #2e3638;
}
