.c-ticket-info {
	position: relative;
	display: block;
}
.c-ticket-info:not(:last-child) {
	margin-bottom: 20px;
}
.c-ticket-info__legend {
	position: absolute;
	right: 0;
	top: 0;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	color: rgba(71,121,133,0.8);
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 18px;
	font-weight: 800;
	text-transform: uppercase;
}
.c-ticket-info__legend b {
	font-size: 0.6em;
}
.c-ticket-info__legend span {
	font-weight: 900;
}
.c-ticket-info__caption {
	width: 100%;
}
.c-ticket-info__control {
	color: rgba(71,121,133,0.8);
}
.c-ticket-info__control .c-forms__control__icon {
	color: inherit;
	font-size: 20px;
}
.c-ticket-info__input {
	color: inherit;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.c-ticket-info__icon.is-valid {
	display: none;
}
.c-ticket-info.is-valid .c-ticket-info__icon.is-not-valid {
	display: none;
}
.c-ticket-info.is-valid .c-ticket-info__icon.is-valid {
	display: flex;
}
