.c-dl-milestones-dialog {
	padding: 30px 10px 10px;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestones-dialog {
		padding: 20px 10px;
   }
}
.c-dl-milestones-dialog__header {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 2rem;
}
.c-dl-milestones-dialog__title {
	display: flex;
	align-items: center;
	justify-content: center;
}
.c-dl-milestones-dialog__title__count {
	font-style: normal;
	font-size: 5rem;
	background: #87ffff;
}
.c-dl-milestones-dialog__title__count > i {
	font-style: normal;
	font-size: 0.6em;
	font-weight: 700;
}
.c-dl-milestones-dialog__title__cnt {
	font-size: 2rem;
	font-weight: 800;
	padding-left: 2rem;
	white-space: nowrap;
}
.c-dl-milestones-dialog__preview {
	margin-bottom: 30px;
}
.c-dl-milestones-dialog__preview > img {
	object-fit: contain;
	object-position: left;
}
.c-dl-milestones-dialog__preview:after {
	content: '';
	display: block;
	padding-top: 62%;
}
.c-dl-milestones-dialog__link-preview {
	position: relative;
	margin-bottom: 30px;
}
.c-dl-milestones-dialog__link-preview__view {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	font-weight: 700;
	font-size: 2rem;
	overflow: hidden;
	border-radius: 5px;
}
.c-dl-milestones-dialog__link-preview__view:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url("/assets/images/useful/milestones-url-placeholder-lg.jpg") no-repeat center center;
	transition: transform 300ms ease;
}
.c-dl-milestones-dialog__link-preview__view:after {
	position: relative;
	content: '';
	display: block;
	padding-top: 62%;
	width: 100%;
	transition: background-color 200ms ease;
	background-color: rgba(0,0,0,0.1);
}
.c-dl-milestones-dialog__link-preview__view:hover:before {
	transform: scale(1.01);
}
.c-dl-milestones-dialog__link-preview__view:hover:after {
	background-color: rgba(0,0,0,0.3);
}
.c-dl-milestones-dialog__link-preview__view > img {
	object-fit: cover;
}
.c-dl-milestones-dialog__link-preview__inner {
	position: relative;
	z-index: 1;
}
.c-dl-milestones-dialog__link-preview__caption {
	display: block;
	padding: 30px 0 0;
}
.c-dl-milestones-dialog__link-preview__title {
	display: block;
	font-size: 20px;
	margin-bottom: 25px;
	word-break: break-word;
}
.c-dl-milestones-dialog__link-preview__desc {
	font-weight: 400;
	display: block;
	margin-bottom: 20px;
	word-break: break-word;
}
.c-dl-milestones-dialog__link-preview__desc-btn-cont {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.c-dl-milestones-dialog__link-preview__btn {
	position: absolute;
	right: 1rem;
	top: 1rem;
	display: flex;
	align-items: center;
	padding: 1rem 1.2rem;
	font-size: 1.5rem;
	border-radius: 0.8rem;
	background: #fff;
	box-shadow: 0 0.2rem 1rem -0.8rem #000;
	transform-origin: 100% 0;
	transition: transform 200ms ease;
	z-index: 1;
}
.c-dl-milestones-dialog__link-preview__view:hover > .c-dl-milestones-dialog__link-preview__btn {
	transform: scale(1.1);
}
.c-dl-milestones-dialog__link-preview__btn .icon {
	margin-left: 0.5em;
}
.c-dl-milestones-dialog__video-preview {
	position: relative;
	margin-bottom: 30px;
}
.c-dl-milestones-dialog__video-preview:before {
	content: '';
	display: block;
	padding-top: 56.2%;
}
.c-dl-milestones-dialog__video-preview > iframe {
	position: absolute;
	display: block;
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	border: 0;
	left: 0;
	top: 0;
	z-index: 1;
}
.c-dl-milestones-dialog__footer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 2rem;
}
.c-dl-milestones-dialog__decription-title__title {
	text-transform: none;
	font-weight: 700;
	margin-bottom: 25px;
}
.c-dl-milestones-dialog__decription-title__desc {
	font-size: 16px !important;
}
.c-dl-milestones-dialog__decription-title__desc:last-child {
	margin-bottom: 0 !important;
}
.c-dl-milestones-dialog__decription-title__btn-cont {
	margin-top: 20px;
}
