.tri-cta {
	border-top: 1px solid #5a93a2;
	border-bottom: 1px solid #5a93a2;
	padding: 10px 0;
}
.tri-cta .cta-outer {
	float: left;
	display: block;
	width: 33.3333%;
	text-align: center;
	text-decoration: none;
	padding-bottom: 1px;
	padding-top: 8px;
}
.tri-cta .cta-outer:nth-child(2) {
	border-left: 1px solid rgba(90,147,162,0.5);
	border-right: 1px solid rgba(90,147,162,0.5);
}
.tri-cta .cta-inner {
	height: 13px;
	padding-left: 20px;
	padding-top: 1px;
	color: #000;
	font-size: 12px;
	line-height: 12px;
	display: inline-block;
	margin-bottom: 10px;
	font-weight: 900;
}
.tri-cta .cta-bott {
	display: block;
	font-size: 9px;
	color: rgba(0,0,0,0.8);
	text-transform: uppercase;
}
.tri-cta .cta-inner.share {
	background: url("/assets/static/icon-share.png") no-repeat left top;
	background-size: 10px 12px;
}
.tri-cta .cta-inner.comment {
	background: url("/assets/static/icon-comment.png") no-repeat left top;
	background-size: 12px 13px;
}
.tri-cta .cta-inner.save {
	background: url("/assets/static/icon-save.png") no-repeat left top;
	background-size: 10px 13px;
}
.tri-cta-condensed {
	padding: 10px 0;
	max-width: 50%;
	margin: 0 auto;
}
.tri-cta-condensed .cta-outer {
	float: left;
	display: block;
	width: 50%;
	text-align: center;
	text-decoration: none;
	padding-bottom: 1px;
	padding-top: 8px;
}
.tri-cta-condensed .cta-inner {
	height: 18px;
	padding-left: 20px;
	padding-top: 5px;
	color: #000;
	font-size: 12px;
	line-height: 12px;
	display: inline-block;
	margin-bottom: 10px;
	font-weight: 900;
}
.tri-cta-condensed .cta-bott {
	display: block;
	font-size: 9px;
	color: rgba(0,0,0,0.8);
	text-transform: uppercase;
}
.tri-cta-condensed .cta-inner.share {
	background: url("/assets/static/icon-share.png") no-repeat left top;
	background-size: 15px 17px;
}
.tri-cta-condensed .cta-inner.comment {
	background: url("/assets/static/icon-comment.png") no-repeat left top;
	background-size: 15px 19px;
}
.tri-cta-condensed .cta-inner.save {
	background: url("/assets/static/icon-save.png") no-repeat left top;
	background-size: 10px 13px;
}
.bi-cta {
	padding: 8px 0;
	height: 50px;
	background: #174552;
}
.bi-cta .cta-outer {
	float: left;
	display: block;
	width: 50%;
	text-align: center;
	text-decoration: none;
	padding-bottom: 0px;
	padding-top: 8px;
}
.bi-cta .cta-outer:nth-child(1) {
	border-right: 1px solid #5a93a2;
	border-right: 1px solid rgba(96,191,215,0.3);
}
.bi-cta .cta-inner {
	height: 16px;
	padding-left: 24px;
	padding-top: 1px;
	color: #000;
	font-size: 10px;
	line-height: 16px;
	display: inline-block;
	margin-bottom: 10px;
	font-weight: 900;
	color: #428fa3;
	text-transform: uppercase;
}
.bi-cta .cta-inner span {
	font-weight: 400;
}
.bi-cta .cta-inner.cog {
	background: url("/assets/static/quest-cog.png") no-repeat left top;
	background-size: 16px 16px;
}
.bi-cta .cta-inner.bell {
	background: url("/assets/static/quest-bell.png") no-repeat left top;
	background-size: 16px 16px;
}
.bi-cta .cta-inner.cog.selected {
	background: url("/assets/static/quest-cog-selected.png") no-repeat left top;
	background-size: 16px 16px;
	color: #ff0;
}
.bi-cta .cta-inner.qt-profile {
	background: url("/assets/static/qt-profile.png") no-repeat left top;
	background-size: 16px 16px;
}
.quest-edit-bi {
	border-bottom: 5px solid #ff0;
	height: 55px;
}
.quest-edit-tasks.profile-section {
	border-top: 0px;
	padding-top: 40px;
}
.account-edit-bi {
	border-bottom: 5px solid #ff0;
	height: 55px;
}
.bi-cta-temp {
	padding: 8px 0;
	height: 10px;
	background: #174552;
}
.bi-cta-temp .cta-outer {
	float: left;
	display: block;
	width: 50%;
	text-align: center;
	text-decoration: none;
	padding-bottom: 0px;
	padding-top: 8px;
}
.bi-cta-temp .cta-outer:nth-child(1) {
	border-right: 1px solid #5a93a2;
	border-right: 1px solid rgba(96,191,215,0.3);
}
.bi-cta-temp .cta-inner {
	height: 16px;
	padding-left: 24px;
	padding-top: 1px;
	color: #000;
	font-size: 10px;
	line-height: 16px;
	display: inline-block;
	margin-bottom: 10px;
	font-weight: 900;
	color: #428fa3;
	text-transform: uppercase;
}
.bi-cta-temp .cta-inner span {
	font-weight: 400;
}
.bi-cta-temp .cta-inner.cog {
	background: url("/assets/static/quest-cog.png") no-repeat left top;
	background-size: 16px 16px;
}
.bi-cta-temp .cta-inner.bell {
	background: url("/assets/static/quest-bell.png") no-repeat left top;
	background-size: 16px 16px;
}
.bi-cta-temp .cta-inner.cog.selected {
	background: url("/assets/static/quest-cog-selected.png") no-repeat left top;
	background-size: 16px 16px;
	color: #ff0;
}
.bi-cta-temp .cta-inner.qt-profile {
	background: url("/assets/static/qt-profile.png") no-repeat left top;
	background-size: 16px 16px;
}
.quest-edit-bi {
	border-bottom: 5px solid #ff0;
	height: 55px;
}
.quest-edit-tasks.profile-section {
	border-top: 0px;
	padding-top: 40px;
}
.account-edit-bi {
	border-bottom: 5px solid #ff0;
	height: 55px;
}
