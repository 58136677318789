.c-notifications {
    background-color: #fff;
    position: relative;
    display: block;
    z-index: 990;
    width: 100%;
    padding: 10px 0;
}
.c-notifications__header {
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 24px;
}
.c-notifications__header h2 {
    text-transform: none;
    font-weight: 600;
    margin: 0;
    font-size: 22px;
    color: #2e3638;
}
.c-notifications__body {
    background-color: #fff;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 14px;
    height: 50rem;
    padding: 0;
}
@media (max-width: 768px) {
    .c-notifications__body {
        height: calc(100vh - 6rem);
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
   }
}
.c-notifications__body.is-loading {
    overflow: hidden;
}
.c-notifications .c-btn {
    border-radius: 0;
}
.c-notifications__item {
    text-decoration: none !important;
    padding: 15px 26px;
    position: relative;
    color: var(--outer-space);
    max-width: 100%;
    display: flex !important;
}
.c-notifications__item:hover {
    background-color: tealLightBg;
}
.c-notifications__item.is-new .c-notifications__item__avatar::before {
    content: '';
    width: 12px;
    height: 12px;
    background: $tealColor;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}
.c-notifications__item__avatar {
    flex-shrink: 0;
    position: relative;
}
.c-notifications__item__avatar .c-avatar {
    width: 40px;
    height: 40px;
}
.c-notifications__item__body {
    padding-right: 15px;
    padding-left: 15px;
    flex-grow: 1;
}
.c-notifications__item__icon {
    justify-content: center;
    padding-right: 10px;
    align-items: center;
    font-size: 18px;
    flex-shrink: 0;
    display: flex;
}
.c-notifications__item__icon .icon {
    display: block;
}
.c-notifications__item__status {
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
    margin-top: 4px;
    font-size: 12px;
}
.c-notifications__item__ttl {
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-weight: 700;
    font-size: 16px;
    color: #1f3034;
}
.c-notifications__item__desc {
    word-break: break-word;
    line-height: 1.35em;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
}
.c-notifications__item__time {
    color: $tealColor;
    font-size: 12px;
    font-weight: 700;
    display: block;
}
