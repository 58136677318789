.c-quest-progress {
    display: block;
}
.c-quest-progress__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100px;
    justify-content: flex-end;
    font-size: 14px;
    padding: 12px 15px;
    font-weight: 700;
    background: var(--milestone-task-bg);
    border-radius: 5px;
    margin-right: 10px;
    color: var(--bg-text-color);
}
@media (max-width: 600px) {
    .c-quest-progress__inner {
        flex-direction: column-reverse;
        margin-right: 0;
        width: 63px;
   }
}
.c-quest-progress__inner.recurring-icon {
    justify-content: center;
    align-items: center;
}
.c-quest-progress__progress {
    transform: rotate(-90deg);
    width: 30px;
    height: 30px;
}
.c-quest-progress__progress__meter {
    fill: var(--white-section-bg);
}
.c-quest-progress__progress__value, .c-quest-progress__progress__value-bg {
    fill: none;
}
.c-quest-progress__progress__meter {
    stroke: var(--progress-bar-uncovered);
}
.c-quest-progress__progress__value {
    stroke: var(--accent-dark-color);
    stroke-linecap: round;
    z-index: 3;
}
.c-quest-progress__progress__value-bg {
    stroke: #c5c9cb;
    stroke-linecap: round;
    position: relative;
    z-index: 1;
}
.c-quest-progress__progress.simple-mode {
    width: 100%;
    height: 100%;
}
.c-quest-progress__caption {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    font-size: 14px;
}
@media (max-width: 600px) {
    .c-quest-progress__caption {
        margin: 5px 0 0;
   }
}
.c-quest-progress__caption b {
    font-size: 16px;
}
