.e-circle-btn {
    position: relative;
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $casal;
    color: #fff;
    padding: 0;
    border: 0;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none !important;
}
.e-circle-btn.is-md {
    width: 50px;
    height: 50px;
    min-width: 50px;
}
.e-circle-btn.is-yellow {
    background-color: $golden-fizz;
    color: var(--petrol);
}
.e-circle-btn .fa-repeat {
    position: absolute;
    font-size: 46px;
    opacity: 0.1;
    width: 1em;
    height: 1em;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: scaleX(-1);
}
.e-circle-btn:hover {
    background-color: #006a86;
    color: #fff;
}
