.c-nl-community {
	padding-top: 2.5rem;
	background-color: $shakespeare;
	color: #fff;
}
@media (max-width: $screen-sm-max) {
	.c-nl-community {
		padding-top: 0;
   }
}
.c-nl-community__group {
	display: flex;
	flex-wrap: wrap;
	margin-left: -1.5rem;
	margin-right: -1.5rem;
	padding-bottom: 2.5rem;
	max-width: 80rem;
	margin: auto;
}
.c-nl-community__group.is-centered {
	justify-content: center;
}
@media (max-width: $screen-sm-max) {
	.c-nl-community__group {
		padding-bottom: 0;
		margin-bottom: -1.5rem;
   }
}
.c-nl-community__col {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	width: 25%;
}
@media (max-width: $screen-sm-max) {
	.c-nl-community__col {
		margin-bottom: 1.5rem;
		width: 50%;
   }
}
.c-nl-community__item__view {
	display: block;
	position: relative;
	width: 100%;
	max-width: 17rem;
	margin: 0 auto;
	height: 9rem;
	text-decoration: none !important;
}
.c-nl-community__item__view > img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}
.c-nl-community__item__ttl {
	display: block;
	text-align: center;
	font-size: 1.8rem;
	letter-spacing: 0.019rem;
	text-align: center;
	color: inherit;
	padding-top: 1rem;
}
.c-nl-community__item__ttl > a {
	display: block;
	color: inherit;
	text-decoration: none;
}
.c-nl-community__item__ttl > a:hover {
	color: inherit;
	text-decoration: underline;
}
