.snotifyToast {
	box-shadow: 0 4px 25px -10px #000;
	word-break: break-word;
}
.snotify-centerTop, .snotify-leftTop, .snotify-rightTop {
	top: 50px;
}
.snotifyToast__inner p {
	margin-bottom: 10px;
}
.snotifyToast__inner p:last-child {
	margin-bottom: 0;
}
