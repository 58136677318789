/*
* fontP: Path to fonts folder
* fileN: Files name without extension
* fontF: font-family
* fontW: font-weight
* fontS: font-style
* formats: woff2 woff truetype eot
*/
@mixin fontFace(
	$fontP,
	$fileN,
	$fontF, 
	$fontW: 400, 
	$fontS: normal
) {
	@font-face {
		font-family: $fontF;
		src: url($fontP + $fileN + ".woff");
		font-weight: $fontW;
		font-style: $fontS;
	}
}
	// @font-face
	// 	fonts = ()
	// 	path = fontP + fileN
	// 	font-family fontF
	// 	if eot in formats
	// 		src url(path + '.eot')
	// 	if woff2 in formats
	// 		push(fonts, 'url("' + path + '.woff2") format("woff2")')
	// 	if woff in formats
	// 		push(fonts, 'url("' + path + '.woff") format("woff")')
	// 	if truetype in formats
	// 		push(fonts, 'url("' + path + '.ttf") format("truetype")')
	// 	src unquote(join(", ", fonts))
	// 	font-weight fontW
	// 	font-style normal

@include fontFace("/assets/fonts/", 'ProximaNova-Thin', 'Proxima Nova', 100, normal);
@include fontFace("/assets/fonts/", 'ProximaNova-Light', 'Proxima Nova', 300, normal);
@include fontFace("/assets/fonts/", 'ProximaNova-Regular', 'Proxima Nova', 400, normal);
@include fontFace("/assets/fonts/", 'ProximaNova-Bold', 'Proxima Nova', 700, normal);
@include fontFace("/assets/fonts/", 'ProximaNova-Extrabld', 'Proxima Nova', 800, normal);
@include fontFace("/assets/fonts/", 'ProximaNova-Black', 'Proxima Nova', 900, normal);

@include fontFace("/assets/fonts/", 'ElloElloNormal', 'ElloEllo', 400, normal);

@include fontFace("/assets/fonts/datedropper/", 'datedropper', 'datedropper', normal, normal);