.c-dl-drop {
	position: relative;
	font-family: 'Proxima Nova';
}
@media (min-width: $screen-md-min) {
	.c-dl-drop {
		min-width: 20rem;
   }
}
.c-dl-drop__header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 2rem;
	min-height: 4rem;
	font-size: 14px;
	cursor: pointer;
	font-weight: 400;
	border-radius: 2rem;
	border: none;
	background-color: $tealColor;
	color: #fff;
	width: 100%;
}
.c-dl-drop__header:hover {
	background-color: lighten($tealColor, 5%);
}
.c-dl-drop__header > span {
	position: relative;
	display: block;
	padding-right: 1.1em;
}
.c-dl-drop__header > span:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0.4em 0.4em 0 0.4em;
	border-color: #fff transparent transparent transparent;
}
.c-dl-drop.open .c-dl-drop__header > span:after {
	border-width: 0 0.4em 0.4em 0.4em;
	border-color: transparent transparent #fff transparent;
}
.c-dl-drop__body {
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	font-size: 1.4rem;
	background: #fff;
	max-height: 22rem;
	overflow-x: hidden;
	overflow-y: auto;
	border: 1px solid pelorous;
	margin-top: 1px;
	display: none;
	border-radius: 0.8rem;
	border: solid 2px tower-gray;
	background-color: #fff;
	box-shadow: 0.2rem 0.7rem 2.1rem -2.2rem rgba(0,0,0,0.75);
	z-index: 2;
}
.c-dl-drop.open .c-dl-drop__body {
	display: block;
}
.c-dl-drop__inner {
	display: flex;
	flex-direction: column;
}
.c-dl-drop__item {
	display: block;
	padding: 1rem;
	text-decoration: none !important;
	color: pelorous;
	&:hover {
		background-color: lighten($tealColor, 90%);
	}
}
.c-dl-drop__item.is-active {
	background-color: $tealColor;
	color: #fff;
}
