.c-ticket-sheet {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 10px;
    font-weight: 300;
    color: var(--petrol);
    font-size: 14px;
}
.c-ticket-sheet__row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-top: 5px;
}
.c-ticket-sheet__row:not(:last-child) {
    border-bottom: 1px dashed $petrol-15;
}
.c-ticket-sheet__row:last-child {
    color: #000;
    font-weight: 800;
}
.c-ticket-sheet__ttl {
    display: block;
}
.c-ticket-sheet__caption {
    display: block;
}
