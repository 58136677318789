.c-subscription {
	position: relative;
	display: flex;
	justify-content: space-between;
	min-height: 65px;
	padding: 10px 15px 10px 50px;
	align-items: center;
	line-height: 1.2;
	font-size: 14px;
	color: #2e3638;
	cursor: pointer;
	text-decoration: none !important;
	width: 82%;
	border: 1px solid #e5eaed;
}
@media (max-width: 1025px) {
	.c-subscription {
		width: 75%;
   }
}
@media (max-width: 769px) {
	.c-subscription {
		width: 70%;
   }
}
@media (max-width: 426px) {
	.c-subscription {
		width: 55%;
   }
}
.c-subscription.is-cancelled {
	opacity: 0.8;
}
@media (min-width: breakpoint-sd1px) {
	.c-subscription {
		padding: 10px 35px 10px 80px;
   }
	.c-subscription:hover {
		background: rgba(0,81,102,0.15);
   }
}
.c-subscription--wrap {
	display: flex;
	flex-direction: row;
	border: 1px solid #e5eaed;
	border-radius: 5px;
	height: 69px;
}
.c-subscription--wrap:not(:last-child) {
	margin-bottom: 15px;
}
.c-subscription--btn_cont {
	background: #e5eaed;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 18%;
	padding: 20px;
}
@media (max-width: 1025px) {
	.c-subscription--btn_cont {
		width: 25%;
   }
}
@media (max-width: 769px) {
	.c-subscription--btn_cont {
		width: 30%;
   }
}
@media (max-width: 426px) {
	.c-subscription--btn_cont {
		width: 45%;
   }
}
.c-subscription__view {
	width: 45px;
	height: 45px;
	position: absolute;
	left: 0;
	top: 10px;
	border-radius: 50%;
	border: 1px solid #fff;
	overflow: hidden;
}
@media (min-width: breakpoint-sd1px) {
	.c-subscription__view {
		left: 20px;
   }
}
.c-subscription__title {
	display: flex;
	height: 32px;
	line-height: 1.2;
	align-items: center;
	padding-right: 10px;
}
.c-subscription__title span {
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	max-height: 32px;
}
.c-subscription__start-date {
	color: var(--outer-space);
	display: flex;
}
@media (max-width: 426px) {
	.c-subscription__start-date {
		font-size: 10px;
   }
}
.c-subscription__next-date {
	display: flex;
	margin-top: 3px;
}
@media (max-width: 426px) {
	.c-subscription__next-date {
		font-size: 10px;
   }
}
.c-subscription__tools {
	font-size: 12px;
	line-height: 1.2;
	text-transform: capitalize;
	white-space: nowrap;
	font-weight: 400;
}
.c-subscription__tools span {
	min-width: 65px;
}
.c-subscription__price {
	text-align: right;
	font-weight: 800;
	color: var(--orient);
}
.c-subscription__detail {
	line-height: 1.2;
	font-family: 'Proxima Nova';
}
.c-subscription__detail__header {
	font-size: 12px;
	font-weight: 900;
	text-transform: uppercase;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.c-subscription__detail__header span {
	color: $black-40;
}
.c-subscription__detail__title {
	font-size: 22px;
	text-transform: uppercase;
	font-weight: 900;
}
.c-subscription__detail__group {
	font-size: 12px;
	font-weight: 800;
}
.c-subscription__detail__group .horz-list-transactions li:nth-child(2) {
	padding-left: 0;
}
