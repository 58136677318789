.tribute-container {
	position: absolute;
	top: 0;
	left: 0;
	height: auto;
	max-height: 300px;
	max-width: 300px;
	overflow: auto;
	display: block;
	z-index: 999999;
	border-radius: 6px;
	box-shadow: 0 0 10px -8px #000;
	background-color: #fff;
}
.tribute-container ul {
	margin: 0;
	padding: 0;
	list-style: none;
	background: $white;
}
.tribute-container li {
	padding: 5px 5px;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
}
.tribute-container li.highlight {
	background: #e0faff;
}
.tribute-container li span {
	font-weight: bold;
}
.tribute-container li.no-match {
	cursor: default;
}
.tribute-container .menu-highlighted {
	font-weight: bold;
}
.tribute-container__avatar {
	font-size: 30px !important;
	flex-shrink: 0;
}
.tribute-container__ttl {
	padding-left: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
