.c-ticket-success {
	position: relative;
	color: var(--bg-text-color);
}
.c-ticket-success__title {
	margin-bottom: 25px;
	position: relative;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 42px;
}
.c-ticket-success__img {
	display: block;
	max-width: 250px;
	margin: 0 auto -20px;
}
.c-ticket-success__quest-title {
	display: block;
	margin-bottom: 20px;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
}
.c-ticket-success__info {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
}
.c-ticket-success__info p {
	margin-bottom: 20px;
}
.c-ticket-success__info p:last-child {
	margin-bottom: 0;
}
.c-ticket-success__share {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 15px 0;
}
.c-ticket-success__share__hr {
	margin: 15px 0;
	border: 0;
	border-top: 1px dashed #000;
	width: 100%;
	background: transparent;
}
.c-ticket-success__share .c-btn {
	margin: 0 0 3px;
}
.c-ticket-success__share a {
	color: var(--bg-text-color);
}
