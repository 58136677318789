.l-dl-explore {
	display: block;
	background-color: #fff;
	min-height: calc(100vh - 4rem);
}
.l-dl-explore.neg-mar-top {
	margin-top: -40px;
}
.l-dl-explore__tools {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: 3rem;
}
@media (max-width: $screen-sm-max) {
	.l-dl-explore__tools {
		justify-content: center;
		flex-wrap: wrap;
   }
}
.l-dl-explore__tool.mr-a {
	margin-right: auto;
	margin-bottom: 0;
	max-width: 50%;
}
@media (max-width: $screen-sm-max) {
	.l-dl-explore__tool.mr-a {
		order: 1;
		margin-top: 1rem;
		width: 100%;
		text-align: center;
		max-width: none;
   }
}
.l-dl-explore__tool:not(:last-child):not(.mr-a) {
	margin-right: 2rem;
}
.l-dl-explore__results {
	margin-bottom: 0;
	white-space: nowrap;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
	color: dove-gray;
}
@media (max-width: $screen-sm-max) {
	.l-dl-explore__results {
		margin-bottom: -2rem;
		text-overflow: initial;
		white-space: normal;
		font-size: 1.8rem;
   }
}
.l-dl-explore__footer {
	display: flex;
	justify-content: center;
}
.l-dl-explore__load {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 4rem;
	min-height: 4rem;
	font-size: 1.8rem;
	cursor: pointer;
	font-weight: 400;
	background: rgba(0,0,0,0.4);
	border-radius: 2rem;
	background-color: $tealColor;
	color: #fff;
	width: auto;
}
.l-dl-explore__collapse {
	overflow: hidden;
}
.l-dl-explore__collapse__inner .l-nl-article__title, .l-dl-explore__collapse__inner .l-nl-article__sub-ttl {
	text-align: left;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
