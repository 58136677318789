.c-field__group {
	display: flex;
	align-items: center;
}
.c-field__label {
	font-size: 14px;
	font-weight: 900;
	letter-spacing: 0.15px;
	text-transform: uppercase;
}
.c-field__label:first-child {
	padding-right: 10px;
}
.c-field__label:last-child {
	padding-left: 10px;
}
.c-field__control {
	flex-grow: 1;
}
.c-field__input {
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.15px;
	border-radius: 20px;
	background-color: bermuda;
	padding: 0 20px;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.15px;
	width: 100%;
	border: 2px solid bermuda;
	height: 30px;
	box-shadow: none !important;
}
.c-field__input::placeholder {
	color: rgba(71,121,133,0.8);
}
.c-field__select {
	position: relative;
}
.c-field__select__control {
	position: relative;
	display: flex;
	height: 30px;
	width: 100%;
	padding: 0 20px;
	border-radius: 20px;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.15px;
	background-color: bermuda;
}
.c-field__select.open .c-field__select__control {
	background: #fff;
	border-radius: 6px 6px 0 0;
}
.c-field__select__control > span {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 100%;
}
@media (min-width: breakpoint-xs1px) {
	.c-field__select__control > span {
		max-width: 100px;
   }
}
.c-field__select__control:after {
	content: '';
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 0;
	margin: auto;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 4px 0 4px;
	border-color: currentColor transparent transparent transparent;
}
.c-field__select.open .c-field__select__control:after {
	background: #fff;
	border-radius: 0 4px 6px 4px;
	border-color: transparent transparent currentColor transparent;
}
.c-field__select__body {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	border-radius: 6px;
	display: none;
	padding-bottom: 10px;
	padding-top: 10px;
	background-color: #fff;
	box-shadow: 0px 1px 4px -1px #000;
}
.c-field__select.open .c-field__select__body {
	display: block;
	border-radius: 0 0 6px 6px;
}
.c-field__select__label {
	width: 100%;
}
.c-field__select__label:last-child .c-field__select__checkbox {
	border-radius: 0 0 6px 6px;
}
.c-field__select__checkbox {
	position: relative;
	padding-left: 50px;
	padding-right: 5px;
	height: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.c-field__select__checkbox:hover {
	color: var(--orient);
	background-color: var(--backgroundOptionsGray);
}
.c-field__select__checkbox [type="checkbox"], .c-field__select__checkbox [type="radio"] {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	padding: 0;
	visibility: hidden;
	opacity: 0;
}
.c-field__select__checkbox [type="checkbox"]:checked ~ .c-field__select__checkbox__icon .icon, .c-field__select__checkbox [type="radio"]:checked ~ .c-field__select__checkbox__icon .icon {
	visibility: visible;
}
.c-field__select__checkbox__icon {
	position: absolute;
	top: 0;
	left: 15px;
	bottom: 0;
	width: 1em;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1em;
	font-size: 20px;
	border: 1px solid #c5c5c5;
	margin: auto;
}
.c-field__select__checkbox__icon .icon {
	visibility: hidden;
	font-size: 0.74em;
}
.c-field__select__checkbox__caption {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 900;
	letter-spacing: 0.22px;
	color: var(--outer-space);
}
.c-field__select__checkbox__caption .icon {
	margin-left: 10px;
	font-size: 1.1em;
}
