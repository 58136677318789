app-explore-card {
    display: block;
}
.c-thumbnail {
    position: relative;
    font-family: 'Proxima Nova';
    color: #fff;
    line-height: 1.2;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 2px 5px 5px rgba(0,0,0,0.15);
    background: #e5eaed;
}
.c-thumbnail__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.c-thumbnail__inner:after {
    content: '';
    display: block;
    height: 0;
    padding-bottom: 56%;
}
.c-thumbnail__image {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 64px);
    border-radius: 0 0 5px 5px;
    background-color: #29393b;
    background: #29393b url($questDefaultImageUrl) no-repeat;
    background-size: cover;
    background-position: center center;
}
.c-thumbnail__image__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.c-thumbnail__image__bg > .h-object-fit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.c-thumbnail__header {
    padding: 10px;
}
.c-thumbnail__interactions {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    padding: 0;
    justify-content: space-between;
    z-index: 9;
    top: 0;
    left: 0;
    line-height: 1;
}
.c-thumbnail__interactions__item {
    display: flex;
    align-items: center;
    margin: 0;
    border-radius: 5px;
    background: #fff;
    padding: 10px;
    color: #2e3638;
}
.c-thumbnail__interactions__item:not(:last-child) {
    margin-right: 14px;
}
.c-thumbnail__interactions__item svg {
    fill: var(--accent-color);
}
.c-thumbnail__interactions__item > span {
    position: relative;
    padding-left: 8px;
}
.c-thumbnail__interactions__item .icon-comment {
    font-size: 13px;
    margin-right: 2px;
}
.c-thumbnail__caption {
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--primary-bg);
}
.c-thumbnail__caption .c-quest-profile__ttl {
    background-color: transparent;
    width: calc(100% + 30px);
}
.c-thumbnail__caption .c-quest-profile__ttl span {
    text-overflow: ellipsis;
    overflow: hidden;
}
.c-thumbnail__caption .c-thumbnail__card-detail {
    padding: 10px;
    position: relative;
    border-radius: 0 0 5px 5px;
}
.c-thumbnail__caption .c-thumbnail__card-detail .bg {
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 5px 5px;
}
.c-thumbnail__caption .c-thumbnail__card-detail .bg.physical {
    background-color: #ff561a;
}
.c-thumbnail__caption .c-thumbnail__card-detail .bg.environmental {
    background-color: #c5ca4a;
}
.c-thumbnail__caption .c-thumbnail__card-detail .bg.occupational {
    background-color: #fc6798;
}
.c-thumbnail__caption .c-thumbnail__card-detail .bg.mental {
    background-color: #3cc5c5;
}
.c-thumbnail__caption .c-thumbnail__card-detail .bg.friends, .c-thumbnail__caption .c-thumbnail__card-detail .bg.social {
    background-color: #905cb4;
}
.c-thumbnail__caption .c-thumbnail__card-detail .bg.piggybank, .c-thumbnail__caption .c-thumbnail__card-detail .bg.financial {
    background-color: #ffb13f;
}
.c-thumbnail__caption .c-thumbnail__card-detail .icon {
    fill: #fff;
}
.c-thumbnail__caption .c-thumbnail__title-container {
    display: flex;
    align-items: center;
}
.c-thumbnail__category {
    position: relative;
    display: block;
    color: powder-blue;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    z-index: 2;
}
.c-thumbnail__category .icon {
    margin-right: 10px;
}
.c-thumbnail__title {
    position: relative;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    z-index: 2;
    margin-bottom: 0px;
}
.c-thumbnail__title > a {
    display: block;
    text-decoration: none !important;
    color: inherit;
}
.c-thumbnail__description {
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: max-height 800ms ease;
    z-index: 2;
}
.c-thumbnail:hover .c-thumbnail__description {
    max-height: 100px;
}
.c-thumbnail__description__inner {
    font-size: 12px;
    font-weight: 800;
    overflow: hidden;
    max-height: 100px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin-top: 8px;
}
.c-thumbnail__description__inner.h-typography {
    padding-top: 0;
}
.c-thumbnail__footer {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 0;
    background: var(--quest-highlight-bg);
    padding: 7px 10px;
}
.c-thumbnail__progress {
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    top: -2px;
    display: flex;
    background-color: powder-blue;
    border-radius: 4px;
    overflow: hidden;
    z-index: 2;
}
.c-thumbnail__progress span {
    height: 100%;
    background-color: $golden-fizz;
}
.c-thumbnail__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-indent: -1000px;
    overflow: hidden;
    z-index: 2;
}
.c-thumbnail__doer {
    position: relative;
    width: 100%;
    padding-right: 10px;
    z-index: 3;
    display: block;
    max-width: calc(100% - 63px);
}
.c-thumbnail__doer .c-quest__view__full {
    display: block;
    width: 100%;
}
.c-thumbnail__doer .c-quest__view__user {
    padding-left: 60px;
    height: 50px;
    color: #fff;
}
.c-thumbnail__doer .c-quest__view__user .c-quest__view__user__img {
    border-color: #fff;
    width: 50px;
    height: 50px;
}
.c-thumbnail__doer .c-quest__view__user__ttl br {
    display: none;
}
.c-thumbnail__trigger {
    margin: 0 0 0 auto;
    position: relative;
    z-index: 3;
    font-weight: 800;
    font-size: 18px;
    background-color: #fff;
    padding: 4px 10px;
    border-radius: 5px;
    color: var(--accent-color);
}
.c-thumbnail__profile .c-avatar {
    width: 34px;
    height: 34px;
    border-color: #fff;
}
.c-thumbnail__profile .c-quest-profile__inner {
    min-height: 45px;
    padding-left: 35px;
}
.c-thumbnail__profile .c-quest-profile__ttl {
    color: var(--bg-text-color);
}
.elloello {
    font-family: ElloEllo;
}
