.c-dl-milestones {
	display: block;
}
.c-dl-milestones__title {
	position: relative;
	margin-bottom: 1rem;
	font-size: 22px;
	font-weight: 600;
	color: var(--bg-text-color);
	text-transform: none;
	display: flex;
}
.c-dl-milestones__title__inner {
	max-width: 40rem;
	white-space: nowrap;
	overflow: hidden;
	font-size: 22px;
	font-weight: 600;
}
.c-dl-milestones__title__inner:not(.toTextarea) {
	text-overflow: ellipsis;
}
@media (max-width: 650px) {
	.c-dl-milestones__title__inner {
		max-width: 63vw;
   }
}
.c-dl-milestones__title__toggle {
	border: none;
	background: transparent;
	padding: 0 4px;
	margin: 0 0 0 4px;
}
.c-dl-milestones__title__toggle .icon-down-filled {
	width: 0.8em;
	height: 0.8em;
	transform: rotate(32deg);
}
.c-dl-milestones__title__toggle span {
	display: block;
	font-weight: 800;
	color: var(--orient);
	transition: transform 250ms ease-in-out;
}
.c-dl-milestones__title__toggle--expanded .icon-down-filled {
	transform: rotate(0deg);
}
.c-dl-milestones__group-body {
	margin-top: 20px;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestones__group-body {
		background: #fff;
		padding: 20px 25px;
		margin-top: 10px;
   }
}
.c-dl-milestones__group-body-content {
	box-shadow: none;
	background-color: transparent;
	transition: all 250ms ease-in-out;
}
.c-dl-milestones__group-body-content.cdk-drop-list {
	position: relative;
}
.c-dl-milestones__group-body-content.c-dl-milestones__group-body-content--modifiable:before, .c-dl-milestones__group-body-content.c-dl-milestones__group-body-content--modifiable:after {
	position: absolute;
	right: 5px;
	font-size: 1rem;
	color: juniper;
}
.c-dl-milestones__group-body-content.c-dl-milestones__group-body-content--modifiable:before {
	top: -15px;
	content: '▲';
}
.c-dl-milestones__group-body-content.c-dl-milestones__group-body-content--modifiable:after {
	bottom: -15px;
	content: '▼';
}
.c-dl-milestones__group-body-content.cdk-drop-list-dragging {
	box-shadow: 0 0 10px 10px var(--accent-bg-light-color);
	background-color: var(--accent-bg-light-color);
}
.c-dl-milestones__group-body-content.cdk-drop-list-receiving {
	box-shadow: 0 0 5px 5px powder-blue;
	background: powder-blue;
}
.c-dl-milestones__ttl-helpers {
	display: flex;
	position: absolute;
	left: 0;
	top: 100%;
	margin-top: 1.7rem;
}
.c-dl-milestones__ttl-helpers__item {
	white-space: nowrap;
}
.c-dl-milestones__ttl-helpers__item:not(:last-child) {
	margin-right: 0.5rem;
}
.c-dl-milestones__placeholder {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 6rem;
	margin-top: 3rem;
	text-align: center;
	font-size: 1.4rem;
	font-weight: 600;
	background-color: #ceeff6;
	color: dove-gray;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestones__placeholder {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
   }
}
.c-dl-milestones__footer {
	display: flex;
	justify-content: flex-end;
	padding-top: 2rem;
}
.c-dl-milestones__new {
	display: block;
}
.c-dl-milestones__body {
	margin-bottom: 2rem;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestones__body {
		margin-top: 1.5rem !important;
   }
}
.c-dl-milestones__tasks-block {
	padding: 0;
	border: 1px solid transparent;
	border-radius: 5px;
	background-color: transparent;
	transition: background-color 250ms ease-in-out;
	margin-bottom: 20px;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestones__tasks-block {
		margin: -5px -5px 15px -5px;
		padding: 5px 5px 5px 5px;
   }
}
.c-dl-milestones__tasks-block--bordered {
	background-color: #fff;
}
.c-dl-milestones__tasks-block--transient .c-dl-milestones__tasks-block-header {
	color: var(--orient);
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestones__tasks-block-header {
		padding: 0 25px;
   }
}
/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
	cursor: move;
}
/* Animate an item that has been dropped. */
.cdk-drag-animating {
	transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}
.cdk-drag-placeholder {
	opacity: 0;
}
