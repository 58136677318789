.c-forms__input.e-edit__textarea {
	border: none;
}
.c-forms__input.e-edit__textarea:focus {
	border: none;
	outline: none;
}
.e-edit__tool {
	display: none;
}
.e-edit__textarea {
	display: none;
}
.is-editable .e-edit__group {
	position: relative;
	padding-right: 1.6em;
}
.is-editable .e-edit__inner {
	display: block;
	background-color: $petrol-15;
	padding: 0.2em;
	border-radius: 3px 0 0 3px;
	word-break: break-word;
	min-height: 26px;
	min-width: 40px;
	text-align: center;
}
.is-editable .e-edit__inner.is-underline {
	text-decoration: underline;
}
.is-editable .e-edit__tool {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	background-color: $petrol-30;
	width: 1.6em;
	text-align: center;
	border-radius: 0 3px 3px 0;
}
.is-editable .e-edit__tool.is-submit {
	display: none;
}
.is-editable .e-edit__tool .icon {
	font-size: 0.8em;
}
.is-editable .e-edit__tool.is-loading {
	opacity: 1;
	pointer-events: none;
}
.is-editable .e-edit__tool.is-loading .icon {
	display: none;
}
.is-editable .e-edit__tool.is-loading:before {
	content: '';
	position: relative;
	width: 0.6em;
	height: 0.6em;
	border-radius: 50%;
	border: 2px solid currentColor;
	border-bottom-color: transparent;
	display: inline-block;
	vertical-align: middle;
	animation: rotating 2s linear infinite;
}
.is-editable .e-edit__textarea {
	display: none;
	min-height: 75px;
	max-width: 100%;
	min-width: 100%;
	border: 0;
}
.is-editable .e-edit__textarea:focus {
	outline: none;
}
.is-editable .e-edit__message {
	text-align: right;
	width: 100%;
}
.e-edit.is-editable {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.e-edit.is-editable.is-active .e-edit__group {
	width: 100%;
}
.e-edit.is-editable.is-active .e-edit__textarea {
	display: block;
	width: 100%;
}
.e-edit.is-editable.is-active .e-edit__tool.is-default, .e-edit.is-editable.is-active .e-edit__inner {
	display: none;
}
.e-edit.is-editable.is-active .e-edit__tool.is-submit {
	display: flex;
}
