.e-skip {
    position: absolute;
    top: 50px;
    left: 10px;
    transform: translateY(-100px);
    text-align: center;
    z-index: 12;
    padding: 10px;
    opacity: 0;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 14px -9px #000;
    transition-property: transform, opacity;
    transition-duration: 300ms;
    transition-timing-function: ease;
}
.e-skip:active, .e-skip:focus, .e-skip:hover {
    top: 40px;
    transform: none;
    opacity: 1;
}
