.ng-progress-inline {
	position: absolute !important;
	transition: opacity 300ms ease;
	opacity: 0;
	width: calc(100% - 20px);
	left: 10px;
	z-index: 2 !important;
	background: #ccc;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 2px 5px -2px rgba(0,0,0,0.3);
}
.ng-progress-inline .ng-progress {
	position: relative !important;
	height: 6px;
	overflow: hidden;
}
.ng-progress-inline .bar {
	height: 6px !important;
}
.ng-progress-inline .bar-shadow {
	display: none !important;
}
