.c-dl-explore-banner {
	display: block;
	overflow: hidden;
	backface-visibility: hidden;
}
.c-dl-explore-banner__not-authenticated {
	margin-top: 10rem;
}
@media (max-width: $screen-sm-max) {
	.c-dl-explore-banner__not-authenticated {
		margin-top: 0rem;
   }
}
.c-dl-explore-banner__image {
	position: relative;
	display: block;
	backface-visibility: hidden;
}
@media (max-width: $screen-sm-max) {
	.c-dl-explore-banner__image {
		margin: 0 -9rem;
   }
}
.c-dl-explore-banner__bg {
	display: block;
	backface-visibility: hidden;
}
.c-dl-explore-banner__logo {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex !important;
	align-items: center;
	justify-content: center;
	backface-visibility: hidden;
}
.c-dl-explore-banner__logo__inner {
	position: relative;
	width: 46%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__logo__img {
	display: block;
	backface-visibility: hidden;
}
.c-dl-explore-banner__logo__cat {
	position: absolute;
	bottom: 100%;
	right: 0;
	width: 8%;
	height: auto;
	backface-visibility: hidden;
}
.c-dl-explore-banner__after-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	backface-visibility: hidden;
}
.c-dl-explore-banner__layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__skater {
	position: absolute;
	width: 8%;
	left: 50% !important;
	top: 4% !important;
	margin-left: -26%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__workers {
	position: absolute;
	width: 15%;
	left: 50% !important;
	bottom: 4% !important;
	top: auto !important;
	margin-left: -46%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__table {
	position: absolute;
	width: 7%;
	left: 50% !important;
	bottom: 1% !important;
	top: auto !important;
	margin-left: -19%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__music {
	position: absolute;
	width: 7%;
	left: 50% !important;
	bottom: -30% !important;
	top: auto !important;
	margin-left: 25%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__man {
	position: absolute;
	width: 4%;
	left: 50% !important;
	bottom: 23% !important;
	top: auto !important;
	margin-left: 39%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__sky-white {
	position: absolute;
	width: 11%;
	left: 50% !important;
	top: 3% !important;
	margin-left: 1%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__sky-red {
	position: absolute;
	width: 5%;
	left: 50% !important;
	top: 11% !important;
	margin-left: 13%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__round {
	position: absolute;
	width: 3%;
	left: 52% !important;
	top: 22% !important;
	margin-left: -47%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__oval {
	position: absolute;
	width: 1.5%;
	left: 52% !important;
	top: 46% !important;
	margin-left: -49%;
	backface-visibility: hidden;
}
.c-dl-explore-banner__square {
	position: absolute;
	width: 1.5%;
	left: 50% !important;
	top: 40% !important;
	margin-left: 34%;
	backface-visibility: hidden;
}
