.c-landing__caption {
  padding: 40px 10px;
  text-align: center;
  color: var(--outer-space);
}
.c-landing__container {
  max-width: 670px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}
.c-landing__title {
  font-size: 36px;
  margin-bottom: 29px;
  text-transform: none;
}
.c-landing__desc {
  font-size: 20px;
}
@media (max-width: $breakpoint-sm) {
  .c-landing__desc {
    font-size: 16px;
 }
}
.c-landing__desc .c-btn {
  min-width: 330px;
}
.c-landing__faq a {
  font-weight: 800;
}
.c-landing__nav {
  color: #000;
  font-weight: 700;
  background-color: rgba(83,151,174,0.5);
  font-size: 14px;
}
.c-landing__nav ul {
  list-style-type: none;
  margin-bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
@media (max-width: $breakpoint-sm) {
  .c-landing__nav ul {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
 }
}
.c-landing__nav li {
  display: block;
}
.c-landing__nav a {
  display: block;
  color: inherit;
  text-decoration: underline !important;
  padding: 10px;
}
.c-landing__nav a:hover {
  color: inherit;
}
.c-landing-welcome {
  display: flex;
  color: #fff;
  height: 100vh;
  flex-direction: column;
  background-color: #ccc;
  padding: 0 15px 15px;
  overflow: hidden;
  transform: translate(0, 0);
  background: url("/assets/images/diem-lp-test.png") center center no-repeat;
  background-size: cover;
}
.c-landing-welcome__video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
  background-size: cover;
}
.c-landing-welcome__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.c-landing-welcome__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}
@media (max-width: $breakpoint-xm) {
  .c-landing-welcome__header {
    font-size: 14px;
 }
}
.c-landing-welcome__header > a {
  display: block;
  text-decoration: underline;
  color: inherit;
  background-color: rgba(0,0,0,0.702);
  padding: 8px;
  border-radius: 4px;
}
.c-landing-welcome__container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.c-landing-welcome__brand {
  width: 262px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@media (max-width: $breakpoint-xm) {
  .c-landing-welcome__brand {
    top: 30px;
    width: 130px;
 }
}
.c-landing-welcome__caption {
  display: block;
}
.c-landing-welcome__title {
  display: block;
  margin: 0 auto;
  font-size: 72px;
  text-align: center;
  font-weight: 100;
  text-transform: none;
  text-shadow: 0px 2px 7px rgba(0,0,0,0.2);
}
@media (max-width: $breakpoint-xm) {
  .c-landing-welcome__title {
    font-size: 43px;
    font-weight: 100;
 }
}
.c-landing-welcome__subTitle {
  display: block;
  margin: 0 auto;
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: $breakpoint-xm) {
  .c-landing-welcome__subTitle {
    font-size: 16px;
    font-weight: 400;
    margin-top: 4px;
    line-height: 1.4;
    max-width: 300px;
 }
}
.c-landing-welcome__arrow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 1em;
  height: 1em;
  font-size: 50px;
  color: inherit;
}
.c-landing-welcome__arrow:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
}
.c-purchase-flow {
  background: var(--primary-bg);
}
.c-purchase-flow__nav-header {
  display: block;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--theme-text-light);
  padding: 0 10px;
  background-color: var(--accent-color);
}
@media (max-width: 650px) {
  .c-purchase-flow__nav-header {
    padding: 0;
 }
}
.c-purchase-flow__nav-header__steps {
  display: flex;
  counter-reset: pagination;
  flex-direction: row;
  justify-content: space-between;
}
.c-purchase-flow__nav-header li {
  display: block;
  transition: max-width 300ms ease;
  display: flex;
  overflow: hidden;
  padding: 13px 5px 10px 5px;
  color: var(--btn-text-light);
  position: relative;
  letter-spacing: 1.25px;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  width: 100%;
}
.c-purchase-flow__nav-header li:before {
  counter-increment: pagination;
  content: counter(pagination);
  position: absolute;
  top: 13px;
  left: calc(50% - 12px);
  color: var(--nav-flow-number-color);
  background: var(--nav-flow-number-bg);
  border-radius: 60px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  z-index: 3;
}
.c-purchase-flow__nav-header li.is-active {
  border-bottom: solid 3px #fff;
  position: relative;
}
.c-purchase-flow__nav-header li.is-active::after {
  content: '';
  width: 100%;
  height: 100%;
  opacity: 0.25;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 0;
}
.c-purchase-flow__nav-header li.is-active * {
  z-index: 3;
}
.c-purchase-flow__nav-header li div, .c-purchase-flow__nav-header li a {
  display: block;
  cursor: pointer;
  text-decoration: none !important;
  color: inherit;
  position: relative;
  margin-top: 30px;
}
@media (max-width: 650px) {
  .c-purchase-flow__nav-header li div, .c-purchase-flow__nav-header li a {
    visibility: hidden;
    margin-top: -10px;
    min-height: 33px;
 }
}
.c-purchase-flow .section-title {
  display: none;
  background: tealLightColor;
  padding: 0 30px;
}
@media (max-width: 650px) {
  .c-purchase-flow .section-title {
    display: block;
 }
}
.c-purchase-flow .section-title span {
  display: none;
}
.c-purchase-flow .section-title span.is-active {
  display: block;
}
.c-purchase-flow .section-title span a {
  display: block;
  text-decoration: none !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1.25px;
}
