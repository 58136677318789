.c-my-friends {
    display: block;
    line-height: 1.2;
    font-family: 'Proxima Nova';
}
.c-my-friends__body {
    display: block;
}
.c-my-friends__inner {
    display: block;
}
.c-my-friends__content {
    display: block;
    height: calc(100vh - 104px);
    overflow-x: hidden;
    overflow-y: auto;
}
.friends-search-filter ~ .c-my-friends__inner .c-my-friends__content {
    height: calc(100vh - 165px);
}
@media (max-width: $breakpoint-sm) {
    .friends-search-filter ~ .c-my-friends__inner .c-my-friends__content {
        height: calc(100vh - 146px);
   }
}
.c-my-friends__item {
    display: flex;
    align-items: center;
    padding: 9px;
}
.c-my-friends__item:not(:last-child) {
    border-bottom: 1px solid rgba(96,191,215,0.3);
}
.c-my-friends__item.more-padding {
    padding: 30px 0;
}
.c-my-friends__item__caption {
    width: calc(100% - 50px);
    padding-left: 10px;
    display: flex;
    align-items: center;
    color: var(--bg-text-color);
}
.c-my-friends__item__img {
    position: relative;
    width: 50px;
    min-width: 50px;
    height: 50px;
}
.c-my-friends__item__img__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #fff;
}
.c-my-friends__item__img__price {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 13px;
    margin-top: 2px;
}
.c-my-friends__item__progress {
    display: block;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.c-my-friends__item__progress .c-quest-progress__inner {
    background: none;
    font-size: 50px;
}
.c-my-friends__item__progress .c-quest-progress__caption {
    display: none;
}
.c-my-friends__item__icon {
    color: #fff;
    background-color: var(--petrol);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    font-size: 20px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
}
.c-my-friends__item__icon .icon, .c-my-friends__item__icon .fa {
    font-size: 11px;
}
.c-my-friends__item__title {
    display: block;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    text-decoration: none !important;
    color: inherit;
}
@media (max-width: $breakpoint-xs) {
    .c-my-friends__item__title span {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
   }
}
.c-my-friends__item__tools {
    justify-content: flex-end;
    align-items: center;
    padding-left: 10px;
    display: flex;
}
.c-my-friends__item__tool {
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
}
.c-my-friends__item__tool .icon {
    font-size: 14px;
}
.c-my-friends__item__tool .e-oval {
    margin-right: 10px;
}
.c-my-friends__item__tool.is-huge {
    font-size: 17px;
}
.c-my-friends__item__tool.is-huge .icon-checked {
    font-size: 16px;
}
.c-my-friends__item__tool.is-green {
    color: olive-drab;
}
.c-my-friends__item__tool.is-red {
    color: $cinnabar;
}
.c-my-friends__item__tool.is-gray {
    color: cutty-sark;
}
.c-my-friends__item__tool.is-loading {
    position: relative;
}
.c-my-friends__item__tool.is-loading .icon {
    opacity: 0;
    visibility: hidden;
}
.c-my-friends__item__tool.is-loading:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 2px solid currentColor;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: rotating 2s linear infinite;
    font-size: 14px;
}
.c-my-friends__item .c-avatar__inner {
    font-size: 1em;
}
.c-my-friends__item .c-my-friends__item__tool span {
    display: none;
}
.c-my-friends__item .c-my-friends__item__img {
    width: 45px;
    height: 45px;
    min-width: 45px;
}
.c-my-friends__item .c-my-friends__item__img__inner {
    border: 0 none;
}
.c-my-friends__row:not(:last-child) {
    border-bottom: 1px solid rgba(96,191,215,0.3);
}
.c-my-friends__row:last-child {
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.c-my-friends__row__header {
    display: flex;
    align-items: baseline;
    text-transform: uppercase;
    font-size: 20px;
    padding: 10px 0;
}
.c-my-friends__row__header .fa {
    margin-right: 10px;
}
.c-my-friends__load {
    background-color: transparent !important;
}
.c-my-friends__empty {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    font-weight: 800;
    min-height: 200px;
}
.c-my-friends__profile-empty {
    position: relative;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    min-height: 56px;
    color: var(--bg-text-color);
    text-align: center;
}
@media (max-width: $breakpoint-sd) {
    .c-my-friends__profile-empty {
        padding: 15px;
        background: rgba(90,147,162,0.3);
        border-radius: 5px;
        text-align: center;
        align-items: center;
        font-size: 14px;
   }
}
.c-my-friends__profile-empty a {
    color: inherit;
}
.c-my-friends__profile-empty .icon {
    font-size: 50px;
    margin-bottom: 12px;
    opacity: 0.3;
    color: var(--petrol);
}
@media (max-width: $breakpoint-sd) {
    .c-my-friends__profile-empty .icon {
        position: absolute;
        right: 9px;
        top: 6px;
        font-size: 45px;
        margin: 0;
   }
}
@media (max-width: $breakpoint-6s) {
    .c-my-friends__profile-empty .icon {
        opacity: 0.1;
   }
}
.c-my-friends .c-explore__filter__inner {
    border-bottom: 1px solid rgba(96,191,215,0.6);
}
.search-items-header .c-my-friends__item__img {
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
}
