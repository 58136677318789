.c-leaderboard-new {
  background: var(--primary-bg-secondary);
}
  
app-leaderboard-main {
  display: block;
  margin: 0 0 26px 0;
}
.c-leaderboard__name, .c-leaderboard__score {
  color: #000;
  font-weight: 700;
}
.c-leaderboard__name {
  font-size: 12pt;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.c-leaderboard__name.pr-10 {
  padding-right: 10px;
}
.c-leaderboard__score {
  font-size: 15pt;
}
.c-leaderboard__score--disabled {
  color: cutty-sark;
}
.c-leaderboard__score__wrap {
  align-items: center;
  display: flex;
}
.c-leaderboard__info, .c-leaderboard__unit {
  font-size: 9pt;
  font-weight: 400;
  color: #035469;
}
.c-leaderboard-score-editor {
  align-items: center;
  display: flex;
}
.c-leaderboard-score-editor__submit, .c-leaderboard-score-editor__cancel, .c-leaderboard-score-editor__dots, .c-leaderboard-score-editor__trig {
  background: none;
  display: block;
  border: none;
  padding: 0;
}
.c-leaderboard-score-editor__submit .icon, .c-leaderboard-score-editor__cancel .icon, .c-leaderboard-score-editor__dots .icon, .c-leaderboard-score-editor__trig .icon {
  display: block;
}
.c-leaderboard-score-editor__trig {
  margin: 0 0 0 5px;
}
.c-leaderboard-score-editor__cancel {
  color: $tabasco;
}
.c-leaderboard-score-editor__submit {
  color: shamrock;
}
.c-leaderboard-score-editor__form {
  position: relative;
  z-index: 3;
}
.c-leaderboard-score-editor__form:not(.is-active) {
  display: none;
}
.c-leaderboard-score-editor__form.is-active {
  display: flex;
}
.c-leaderboard-score-editor__input {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #fff;
  text-align: center;
  display: block;
  padding: 2px;
  height: 24px;
  border: none;
  width: 90px;
}
.c-leaderboard-score-editor__tools {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  justify-content: space-around;
  background-color: #fff;
  padding-bottom: 4px;
  align-items: canter;
  position: absolute;
  padding-top: 4px;
  display: flex;
  top: 100%;
  right: 0;
  left: 0;
}
.c-leaderboard-score-editor__dropdown {
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  margin-top: 1px;
  padding: 4px;
  top: 100%;
  right: 0;
}
.c-leaderboard-score-editor__dropdown:not(.is-active) {
  display: none;
}
.c-leaderboard-score-editor__dropdown__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.c-leaderboard-score-editor__dropdown__item {
  white-space: nowrap;
  padding-bottom: 3px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  cursor: pointer;
}
.c-leaderboard-score-editor__dropdown__item:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.c-leaderboard-score-editor__dropdown__item:hover {
  background-color: $jungle-mist;
}
.c-leaderboard-score-editor__dropdown__item.is-active {
  background-color: $hippie-blue;
}
.c-leaderboard-score-editor__dropdown__item.is-active:hover {
  background-color: $jungle-mist;
}
