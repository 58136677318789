.c-dl-explore-filter {
	display: block;
	background-color: #295967;
	color: #fff;
}
.c-dl-explore-filter__inner {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 6.3rem;
	padding-bottom: 6.3rem;
	font-size: 3.2rem;
	letter-spacing: 0.44px;
	text-align: center;
	color: inherit;
	line-height: 1;
	font-weight: 800;
}
@media (max-width: $screen-md-max) {
	.c-dl-explore-filter__inner {
		flex-direction: column;
		font-size: 2.8rem;
		font-weight: 400;
		line-height: 1.1;
		width: 100%;
   }
}
@media (max-width: $screen-xs-max) {
	.c-dl-explore-filter__inner {
		min-height: 10rem;
		font-size: 5vw;
		line-height: 1.3;
		padding-top: 5rem;
		padding-bottom: 5rem;
   }
}
.c-dl-explore-filter__dropdown__close, .c-dl-explore-filter__clear {
	display: flex;
	align-items: center;
	background: $tealColor;
	color: #fff;
}
@media (max-width: $screen-xs-max) {
	.c-dl-explore-filter__dropdown__close, .c-dl-explore-filter__clear {
		font-size: 3rem;
		font-weight: 700;
   }
}
.c-dl-explore-filter__dropdown__close .icon-dl-close, .c-dl-explore-filter__clear .icon-dl-close {
	font-size: 0.4em;
}
.c-dl-explore-filter__clear {
	position: absolute;
	right: 2rem;
	top: 2rem;
}
@media (max-width: $screen-xs-max) {
	.c-dl-explore-filter__clear {
		top: 1rem;
		right: 1rem;
   }
}
.c-dl-explore-filter__part {
	position: relative;
	transition-property: filter;
	transition-timing-function: ease;
	transition-duration: 200ms;
}
.c-dl-explore-filter__part.is-select {
	position: relative;
	font-size: 1.2em;
	top: -0.06em;
	font-weight: 900;
	backface-visibility: hidden;
	-webkit-font-smoothing: subpixel-antialiased;
	cursor: pointer;
	transition-property: opacity, transform;
	white-space: nowrap;
	text-transform: lowercase;
	color: #176673;
}
@media (max-width: $screen-md-max) {
	.c-dl-explore-filter__part.is-select {
		max-width: 30rem;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 1em;
		top: 0.06em;
   }
}
@media (max-width: $screen-xs-max) {
	.c-dl-explore-filter__part.is-select {
		top: -0.04em;
   }
}
.c-dl-explore-filter__part.is-select:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-bottom: 2px solid currentColor;
}
@media (max-width: $screen-xs-max) {
	.c-dl-explore-filter__part.is-select:before {
		border-bottom-width: 1px;
		bottom: 1px;
   }
}
.c-dl-explore-filter__part.is-select:first-letter {
	text-transform: uppercase;
}
.c-dl-explore-filter__part.is-select:after {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--petrol);
	transform: scaleX(0);
	animation-fill-mode: forwards;
}
.c-dl-explore-filter__part.is-select.is-increase:after {
	animation: dl-explore-filter-select-increase 400ms linear;
	animation-timing-function: linear;
}
.c-dl-explore-filter__part.is-select.is-decrease:after {
	animation: dl-explore-filter-select-decrease 400ms linear;
	animation-timing-function: linear;
}
.c-dl-explore-filter__part.is-select.in-process {
	overflow: hidden;
	white-space: nowrap;
}
.c-dl-explore-filter__part.is-select:hover {
	transform: translateY(0.1em);
	transform: scale(1.02);
}
.c-dl-explore-filter__part.is-select.is-yellow {
	color: #ecfe36;
}
.c-dl-explore-filter__part.is-select.is-white {
	color: #fff;
}
.c-dl-explore-filter__form {
	display: flex;
	align-items: center;
	justify-content: center;
}
@media (max-width: $screen-md-max) {
	.c-dl-explore-filter__form {
		flex-wrap: wrap;
		width: 100%;
		height: 50%;
   }
	.c-dl-explore-filter__form .h-sm-none {
		display: none;
   }
}
@media (max-width: $screen-xs-max) {
	.c-dl-explore-filter__form {
		width: auto;
   }
}
.c-dl-explore-filter__form.has-selected-category .c-dl-explore-filter__part:not(.h-category) {
	opacity: 0.4;
}
.c-dl-explore-filter__form.has-selected-place .c-dl-explore-filter__part:not(.h-place) {
	opacity: 0.4;
}
.c-dl-explore-filter__row {
	display: flex;
	align-items: center;
	justify-content: center;
}
.c-dl-explore-filter__dropdown {
	position: relative;
	width: 100%;
	height: 0;
	overflow: hidden;
}
.is-mobile-mode .c-dl-explore-filter__dropdown {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100% !important;
	margin-top: 0;
	overflow-y: auto;
	opacity: 0;
	visibility: hidden;
	transition-property: opacity, visibility;
	transition-duration: 300ms;
	transition-timing-function: ease;
	-webkit-overflow-scrolling: touch;
	z-index: 101;
}
.is-mobile-mode .c-dl-explore-filter__dropdown.is-opened {
	opacity: 1;
	visibility: visible;
}
.is-mobile-mode .c-dl-explore-filter__dropdown__inner {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
	justify-content: flex-end;
	overflow: hidden;
	padding-left: 0;
	padding-right: 0;
}
.is-mobile-mode .c-dl-explore-filter__dropdown__inner .e-btn {
	opacity: 1 !important;
}
.c-dl-explore-filter__dropdown__inner > .e-btn {
	margin: 0.25em;
}
.is-mobile-mode .c-dl-explore-filter__dropdown__list {
	position: relative;
	display: block;
	transform: translateY(20rem);
	height: 50vh;
	background-color: #dcfaff;
	box-shadow: 0 3rem 4rem 0rem #000;
	overflow: auto;
	transition-property: transform;
	transition-duration: 300ms;
	transition-timing-function: ease;
}
.is-mobile-mode .c-dl-explore-filter__dropdown.is-opened .c-dl-explore-filter__dropdown__list {
	transform: none;
}
.is-mobile-mode .c-dl-explore-filter__dropdown.is-closing .c-dl-explore-filter__dropdown__list {
	transform: translateY(100%);
}
.c-dl-explore-filter__dropdown__list__inner {
	padding: 2rem 0;
}
.is-mobile-mode .c-dl-explore-filter__dropdown__list__inner {
	padding: 4rem 2rem;
	height: 100%;
}
.c-dl-explore-filter__dropdown__item {
	margin: 0.1em;
	background: #fff;
	color: #2e3638;
	border-radius: 20px;
}
.c-dl-explore-filter__dropdown__item.is-warning {
	background-color: geyser;
	border-color: geyser;
	color: #000;
}
.c-dl-explore-filter__dropdown__item.is-danger {
	background-color: geyser;
	border-color: geyser;
	color: $tabasco;
}
.is-mobile-mode .c-dl-explore-filter__dropdown__item {
	float: left;
	margin: 0.2em;
	text-transform: none;
	font-size: 1.8rem;
	transform: none !important;
	opacity: 1 !important;
}
.c-dl-explore-filter__dropdown__controls.is-visible-only-sm {
	display: none;
}
.is-mobile-mode .c-dl-explore-filter__dropdown__controls.is-visible-only-sm {
	display: block;
}
.c-dl-explore-filter__dropdown__bg {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.is-mobile-mode .c-dl-explore-filter__dropdown.is-opened .c-dl-explore-filter__dropdown__bg {
	display: block;
}
.c-dl-explore-filter__dropdown__close {
	display: none;
}
.is-mobile-mode .c-dl-explore-filter__dropdown__close {
	display: flex;
	position: absolute;
	top: 0;
	right: 2rem;
	margin-top: -0.5em;
	opacity: 0;
}
@media (max-width: $screen-xs-max) {
	.is-mobile-mode .c-dl-explore-filter__dropdown__close {
		right: 1rem;
   }
}
.c-dl-explore-filter__dropdown.is-opened .is-mobile-mode .c-dl-explore-filter__dropdown__close {
	opacity: 1;
}
.c-dl-explore-filter__dropdown.is-closing .is-mobile-mode .c-dl-explore-filter__dropdown__close {
	opacity: 0;
}
@media (max-width: $screen-md-max) {
	body.c-dl-explore-filter-is-open {
		overflow: hidden;
   }
}
@-moz-keyframes dl-explore-filter-select-increase {
	0% {
		transform: scaleX(0);
		transform-origin: 0 0;
   }
	25% {
		transform: none;
		transform-origin: 0 0;
   }
	75% {
		transform: none;
		transform-origin: 100% 0;
   }
	100% {
		transform: scaleX(0);
		transform-origin: 100% 0;
   }
}
@-webkit-keyframes dl-explore-filter-select-increase {
	0% {
		transform: scaleX(0);
		transform-origin: 0 0;
   }
	25% {
		transform: none;
		transform-origin: 0 0;
   }
	75% {
		transform: none;
		transform-origin: 100% 0;
   }
	100% {
		transform: scaleX(0);
		transform-origin: 100% 0;
   }
}
@-o-keyframes dl-explore-filter-select-increase {
	0% {
		transform: scaleX(0);
		transform-origin: 0 0;
   }
	25% {
		transform: none;
		transform-origin: 0 0;
   }
	75% {
		transform: none;
		transform-origin: 100% 0;
   }
	100% {
		transform: scaleX(0);
		transform-origin: 100% 0;
   }
}
@keyframes dl-explore-filter-select-increase {
	0% {
		transform: scaleX(0);
		transform-origin: 0 0;
   }
	25% {
		transform: none;
		transform-origin: 0 0;
   }
	75% {
		transform: none;
		transform-origin: 100% 0;
   }
	100% {
		transform: scaleX(0);
		transform-origin: 100% 0;
   }
}
@-moz-keyframes dl-explore-filter-select-decrease {
	0% {
		transform: scaleX(0);
		transform-origin: 100% 0;
   }
	25% {
		transform: none;
		transform-origin: 100% 0;
   }
	75% {
		transform: none;
		transform-origin: 0 0;
   }
	100% {
		transform: scaleX(0);
		transform-origin: 0 0;
   }
}
@-webkit-keyframes dl-explore-filter-select-decrease {
	0% {
		transform: scaleX(0);
		transform-origin: 100% 0;
   }
	25% {
		transform: none;
		transform-origin: 100% 0;
   }
	75% {
		transform: none;
		transform-origin: 0 0;
   }
	100% {
		transform: scaleX(0);
		transform-origin: 0 0;
   }
}
@-o-keyframes dl-explore-filter-select-decrease {
	0% {
		transform: scaleX(0);
		transform-origin: 100% 0;
   }
	25% {
		transform: none;
		transform-origin: 100% 0;
   }
	75% {
		transform: none;
		transform-origin: 0 0;
   }
	100% {
		transform: scaleX(0);
		transform-origin: 0 0;
   }
}
@keyframes dl-explore-filter-select-decrease {
	0% {
		transform: scaleX(0);
		transform-origin: 100% 0;
   }
	25% {
		transform: none;
		transform-origin: 100% 0;
   }
	75% {
		transform: none;
		transform-origin: 0 0;
   }
	100% {
		transform: scaleX(0);
		transform-origin: 0 0;
   }
}

