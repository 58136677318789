.c-dl-milestone {
	display: block;
}
.c-dl-milestone:not(:last-child) {
	margin-bottom: 20px;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone:not(:last-child) {
		margin-bottom: 0.5rem;
   }
}
.c-dl-milestone__new-preview {
	background: var(--white-section-bg);
	width: 100%;
	border-radius: 2px;
	padding: 20px;
	position: relative;
}
.c-dl-milestone__new-preview p {
	font-size: 14px;
}
.c-dl-milestone__new-preview h3, .c-dl-milestone__new-preview h4 {
	text-transform: none;
	margin-bottom: 0;
}
.c-dl-milestone__new-preview .menu-dots {
	position: absolute;
	right: 20px;
	top: 20px;
}
.c-dl-milestone__new-preview .menu-dots .icon {
	transform: rotate(90deg);
	font-size: 20px;
	cursor: pointer;
	margin-right: 5px;
}
.c-dl-milestone__new-preview .menu-dots__options {
	position: absolute;
	right: 0;
	top: 20px;
	background: #fff;
	box-shadow: 0px 0px 10px 1px #c5c5c5;
	padding: 10px 15px;
}
.c-dl-milestone__new-preview .menu-dots__option {
	cursor: pointer;
	font-size: 14px;
}
.c-dl-milestone__new-preview .task-link-attach-container .add-link {
	width: 51%;
}
.c-dl-milestone__new-preview .task-link-attach-container .add-image {
	width: 45%;
}
.c-dl-milestone__new-preview .task-link-attach-container .add-image .image-upload-box {
	border: solid 1px #c5c9cb;
	background: #fff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	max-height: 87%;
	min-height: 102px;
	width: 100%;
}
.c-dl-milestone__new-preview .task-link-attach-container .add-image .image-upload-box .image-preview {
	max-height: 80px;
}
.c-dl-milestone__new-preview .task-link-attach-container label {
	font-size: 14px;
	margin-bottom: 6px;
	display: block;
}
.c-dl-milestone__new-preview__btn-container {
	text-align: right;
	margin-top: 20px;
}
.c-dl-milestone__inner {
	display: flex;
	min-height: 12rem;
	background: var(--bg-milestone);
	text-decoration: none !important;
	color: inherit;
	margin-bottom: 20px;
	border-radius: 5px;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__inner {
		position: relative;
		height: auto;
   }
}
.c-dl-milestone__inner.preview-ml {
	height: auto;
	min-height: auto;
}
.c-dl-milestone.cdk-drag-preview .c-dl-milestone__inner, .c-dl-milestone:active .c-dl-milestone__inner {
	box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2);
}
.c-dl-milestone.cdk-drag-preview .c-dl-milestone__inner {
	opacity: 0.8;
}
.c-dl-milestone__inner .task-container {
	width: 100%;
}
.c-dl-milestone__inner .task-container .mb-0 {
	margin-bottom: 0;
}
.c-dl-milestone__new-inner {
	padding-bottom: 40px;
	border-bottom: solid 1px $greyDarkColor;
}
.c-dl-milestone__new-inner-bg {
	background: var(--milestone-task-bg);
}
.c-dl-milestone__new-inner .task-container {
	width: 100%;
	padding: 30px;
	border-radius: 2px;
}
.c-dl-milestone__new-inner .task-container .mb-0 {
	margin-bottom: 0;
}
.c-dl-milestone__new-inner .task-container .task-list {
	border-bottom: solid 1px $greyDarkColor;
	margin-bottom: 30px;
}
.c-dl-milestone__new-inner .task-settings {
	padding: 5px 0 0 0;
	margin-bottom: 40px;
}
@media (max-width: 650px) {
	.c-dl-milestone__new-inner .task-settings {
		padding: 5px 10px 20px 10px;
   }
}
@media (max-width: 769px) {
	.c-dl-milestone__new-inner .task-settings .task-link-attach-container {
		flex-direction: column !important;
   }
}
.c-dl-milestone__new-inner .task-settings .task-link-attach-container .add-link {
	width: 51%;
}
@media (max-width: 769px) {
	.c-dl-milestone__new-inner .task-settings .task-link-attach-container .add-link {
		width: 100%;
   }
}
.c-dl-milestone__new-inner .task-settings .task-link-attach-container .add-image {
	width: 45%;
}
@media (max-width: 769px) {
	.c-dl-milestone__new-inner .task-settings .task-link-attach-container .add-image {
		width: 100%;
		margin-top: 15px;
   }
}
.c-dl-milestone__new-inner .task-settings .task-link-attach-container .add-image .image-upload-box {
	border: solid 1px #c5c9cb;
	background: #fff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 87%;
	min-height: 102px;
	width: 100%;
}
.c-dl-milestone__new-inner .task-settings .task-link-attach-container .add-image .image-upload-box .image-preview {
	max-height: 80px;
}
.c-dl-milestone__new-inner .task-settings .task-link-attach-container .add-image .image-upload-box svg {
	margin-bottom: 11px;
}
.c-dl-milestone__new-inner .task-settings .task-link-attach-container .add-image .image-upload-box span {
	font-size: 12px;
}
.c-dl-milestone__new-inner .task-settings .task-link-attach-container .or-text {
	font-size: 14px;
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: center;
}
.c-dl-milestone__new-inner .task-settings .task-link-attach-container label {
	font-size: 14px;
	margin-bottom: 6px;
	display: block;
}
.c-dl-milestone__count {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 14%;
	font-size: 32px;
	font-weight: 700;
	background-color: $lightGreyColor;
	color: #fff;
	border-radius: 5px;
	position: relative;
	cursor: default;
	overflow: hidden;
	z-index: 1;
}
@media (min-width: $screen-sm-min) {
	.c-dl-milestone__count {
		width: 9.1rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__count {
		position: absolute;
		top: 0;
		left: 0;
		width: auto;
		line-height: 1;
		font-size: 1.8rem;
		padding: 0.5rem;
   }
}
.c-dl-milestone__count:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transform: scaleX(0);
	transform-origin: 0 0;
	transition: transform 300ms ease;
	background-color: $tealColor;
}
.c-dl-milestone__count > span {
	position: relative;
}
.c-dl-milestone__count.is-active:before {
	transform: none;
}
.c-dl-milestone__view {
	position: relative;
	width: 190px;
	padding-left: 0;
	padding-right: 0;
	border-radius: 5px;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__view {
		width: 60px;
   }
}
.c-dl-milestone__view__picture {
	display: block;
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 5px;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__view__picture > .is-md {
		display: none;
   }
}
.c-dl-milestone__view__picture > .is-sm {
	display: none;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__view__picture > .is-sm {
		display: block;
   }
}
.c-dl-milestone__view__empty {
	display: block;
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 5px;
	background-color: $lightGreyColor;
}
.c-dl-milestone__view__link {
	height: 100%;
	position: relative;
	display: flex;
	align-items: flex-end;
}
.c-dl-milestone__view__link__picture {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	background: url("/assets/images/useful/milestones-url-placeholder-lg.jpg") no-repeat center center;
}
.c-dl-milestone__view__link__caption {
	position: relative;
	display: block;
	background: rgba(244,244,244,0.5);
	padding: 0.5rem 1rem;
	width: 100%;
	max-width: 100%;
	z-index: 1;
}
.c-dl-milestone__view__link__title {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
}
.c-dl-milestone__view__link__desc {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.c-dl-milestone__view__icon {
	font-size: 4rem;
	position: absolute;
	background: #fff;
	position: absolute;
	top: 1rem;
	right: 1rem;
	margin: auto;
	box-shadow: 0 0 12px -10px #000;
	z-index: 1;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__view__icon {
		font-size: 3rem;
   }
}
.c-dl-milestone__body {
	width: 70%;
	padding: 0;
	border-radius: 5px 5px 0 0;
}
@media (min-width: $screen-sm-min) {
	.c-dl-milestone__body {
		flex-grow: 1;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__body {
		width: 67%;
		flex-grow: 1;
   }
}
.c-dl-milestone__body__inner {
	position: relative;
	display: flex;
	flex-direction: row;
	height: 100%;
	padding: 20px;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__body__inner {
		padding: 20px 10px;
   }
}
.c-dl-milestone__body__loader {
	width: 3rem;
	height: 3rem;
	position: absolute;
	z-index: 2;
	right: 0;
}
.c-dl-milestone__desc {
	font-size: 1.4rem;
	font-weight: 700;
	color: var(--bg-text-color);
	margin-bottom: 0;
	word-break: break-word;
}
@media (max-width: $screen-md-max) {
	.c-dl-milestone__desc {
		font-size: 1.2rem;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__desc {
		font-size: 1.1rem;
   }
}
.c-dl-milestone__desc.with-field-styles {
	background: transparent;
	border-color: transparent;
}
.c-dl-milestone__desc h4 {
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 900;
}
.c-dl-milestone__control-parent {
	width: 100%;
	padding-right: 20px;
}
.c-dl-milestone__tools {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 0 0 0 20px;
	border-left: solid 1px #c5c9cb;
	transition: opacity 300ms ease;
}
.c-dl-milestone__tools.is-disabled {
	opacity: 0.2;
	pointer-events: none;
}
@media (max-width: $screen-md-max) {
	.c-dl-milestone__tools {
		padding: 0.5rem;
   }
}
.c-dl-milestone__tools__left {
	margin-right: auto;
}
.c-dl-milestone__tools__left.is-red {
	color: $tabasco;
}
.c-dl-milestone__tools .icon {
	fill: var(--btn-text-light);
}
.c-dl-milestone__tool {
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px #000;
	border-radius: 5px;
	width: 30px;
	height: 30px;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__tool {
		width: 20px;
		height: 20px;
   }
}
.c-dl-milestone__tool:not(.is-disabled) {
	cursor: pointer;
}
.c-dl-milestone__tool:not(.is-disabled):hover {
	background-color: var(--accent-color);
}
.c-dl-milestone__tool:not(.is-disabled):hover .icon {
	fill: var(--btn-text-light);
}
.c-dl-milestone__tool:not(.is-disabled) .icon {
	display: block;
}
.c-dl-milestone__tool.is-active {
	background-color: #000;
	color: #fff;
}
.c-dl-milestone__tool.is-active:not(.is-disabled) {
	cursor: pointer;
}
.c-dl-milestone__tool.is-active:not(.is-disabled):hover {
	background-color: #000;
	color: var(--accent-color);
}
.c-dl-milestone__tool.is-disabled {
	opacity: 0.2;
	cursor: default;
}
.c-dl-milestone__tool:not(:first-child) {
	margin-top: 1rem;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__tool:not(:first-child) {
		margin-top: 0.5rem;
   }
}
.c-dl-milestone__tool .icon {
	font-size: 2rem;
	pointer-events: none;
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__tool .icon {
		font-size: 1.2rem;
   }
}
.c-dl-milestone__control {
	overflow: auto;
	margin: 0px;
	min-height: 8.5rem;
	max-height: 8.5rem;
	resize: none;
}
@media (max-width: $screen-md-max) {
	.c-dl-milestone__control {
		min-height: 5.7rem;
		max-height: none;
   }
}
@media (max-width: $screen-sm-max) {
	.c-dl-milestone__control {
		font-size: 1.1rem;
		margin: -5px -4px 0;
   }
}
.c-dl-milestone__new__footer {
	justify-content: stretch;
	align-items: flex-end;
}
.c-dl-milestone__new__footer display flex {
	flex-direction: column;
}
.c-dl-milestone__new__footer > .e-btn:not(:last-child) {
	margin-right: 1rem;
}
.task-group__header {
	background: var(--accent-dark-color);
	color: var(--btn-text-light);
	font-size: 24px;
	font-weight: 800;
	border-radius: 2px;
	line-height: 36px;
}
.task-group__header__title {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 20px 10px 30px;
	justify-content: space-between;
}
.task-group__header__title-name {
	max-width: 80%;
	flex: 1;
}
.task-group__header__title-btn {
	width: 25px;
	height: 25px;
	cursor: pointer;
}
