body .e-btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 13px 16px;
	margin: 0;
	border: 0.2rem solid var(--accent-color);
	border-radius: 0.6rem;
	vertical-align: baseline;
	text-transform: uppercase;
	font-size: 1.4rem;
	line-height: 1.5rem;
	font-weight: bold;
	font-family: inherit;
	background: var(--accent-color);
	color: var(--btn-text-light);
	cursor: pointer;
	-webkit-appearance: none;
	appearance: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition-property: background-color, border-color, color;
	transition-duration: 200ms;
	transition-timing-function: ease;
	text-decoration: none !important;
	min-width: 150px;

	&.mw-auto {
		min-width: auto;
	}
}
body .e-btn:active, body .e-btn:hover {
	border-color: var(--accent-color);
	background: var(--accent-color);
	color: var(--btn-text-color);
}
body .e-btn:active .icon, body .e-btn:hover .icon {
	fill: var(--btn-text-color);
}
body .e-btn.is-disabled, body .e-btn:disabled {
	opacity: 0.3;
	pointer-events: none;
}
@media (max-width: 650px) {
	body .e-btn {
		min-width: 100px;
   }
}
body .e-btn > span {
	position: relative;
	top: 0.1em;
}
body .e-btn > .icon {
	margin-right: 10px;
}
body .e-btn_label {
	position: relative;
	text-align: center;
}
body .e-btn_label > input {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	border: 0;
	padding: 0;
	margin: 0;
	visibility: hidden;
	opacity: 0;
}
body .e-btn_xs {
	padding: 0.2rem 1rem 0.3rem;
	font-size: 1.2rem;
}
body .e-btn_sm {
	padding: 0.5rem 2rem;
}
body .e-btn_md {
	padding: 7px 32px;
}
body .e-btn_full {
	width: 100%;
}
body .e-btn_dark-revert {
	border-color: var(--accent-color);
	background: transparent;
	color: var(--accent-color);
}
body .e-btn_dark-revert .icon {
	fill: var(--accent-color);
}
body .e-btn_dark-revert:active, body .e-btn_dark-revert:hover {
	border-color: var(--accent-color);
	background: var(--accent-color);
	color: var(--btn-text-light);
}
body .e-btn_dark-revert:active .icon, body .e-btn_dark-revert:hover .icon {
	fill: var(--btn-text-light);
}
body .e-btn_dark {
	border-color: var(--accent-color);
	background: var(--accent-color);
	color: var(--btn-text-light);

	.icon {
		fill: var(--btn-text-light);
	}
}
body .e-btn_dark:active, body .e-btn_dark:hover {
	border-color: var(--accent-bg-light-color);
	background: var(--accent-bg-light-color);
	color: var(--btn-text-light);
}
body .e-btn_dark-gray {
	border-color: $lightGreyColor;
	background: $lightGreyColor;
	color: $greyDarkColor;
}
body .e-btn_dark-gray:active, body .e-btn_dark-gray:hover {
	color: var(--btn-text-light);
	border-color: lighten($lightGreyColor, 5%);
	background: lighten($lightGreyColor, 5%);
}
body .e-btn_dark-white {
	border-color: var(--accent-color);
	background: var(--white);
	color: var(--outer-space);

	.icon {
		fill: var(--accent-color);
	}
}
body .e-btn_dark-white:active, body .e-btn_dark-white:hover {
	border-color: var(--accent-bg-light-color);
	background: var(--accent-bg-light-color);
	color: var(--btn-text-light);
}
body .e-btn_dark-yellow {
	border-color: $casal;
	background: $casal;
	color: $bright-yellow;
}
body .e-btn_dark-yellow:active, body .e-btn_dark-yellow:hover {
	color: $bright-yellow;
	border-color: lighten($casal, 5%);
	background: lighten($casal, 5%);
}
body .e-btn_yellow {
	border-color: $bright-yellow;
	background: $bright-yellow !important;
	color: var(--outer-space);
}
body .e-btn_yellow:active, body .e-btn_yellow:hover {
	color: var(--outer-space);
	border-color: lighten($bright-yellow, 5%);
	background: lighten($bright-yellow, 5%);
}
body .e-btn__primary-transparent {
	background: transparent;
	color: var(--btn-text-color);
}
body .e-btn__primary-transparent .icon {
	fill: var(--accent-color);
}
body .e-btn__primary-transparent:hover {
	color: var(--btn-text-light);
}
body .e-btn__primary-transparent:hover .icon {
	fill: var(--btn-text-light);
}
body .e-btn__plain {
	background: transparent;
	color: $greyDarkColor;
	border-color: transparent;
}
body .e-btn__plain:hover {
	background: transparent;
	color: var(--greyColor);
	border-color: transparent;
}
body .e-btn.is-loading {
	opacity: 1;
	pointer-events: none;
}
body .e-btn.is-loading .fa, body .e-btn.is-loading .icon {
	display: none;
}
body .e-btn.is-loading span {
	opacity: 0;
}
body .e-btn.is-loading:before {
	content: '';
	position: absolute;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	border: 2px solid currentColor;
	border-bottom-color: transparent;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	animation: rotating 2s linear infinite;
}
body .e-btn.icon-only {
	width: 30px;
	height: 30px;
	padding: 0;
	min-width: auto;
}
body .e-btn.icon-only .icon {
	width: 20px;
	height: 20px;
}

