.dropdown-menu {
	box-shadow: 0 0 8px -7px #000;
	padding: 0;
	border: 0;
}
.dropdown-menu > li > a {
	padding: 10px 22px;
	color: var(--accent-color);
}
.dropdown-menu > li > a:hover {
	color: var(--accent-color);
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
	background-color: var(--orient);
	color: #fff;
}
.bootstrap-select .dropdown-menu li a {
	font-weight: 800;
}
