.e-label {
    border: none;
    padding: 0;
    margin-bottom: 0;
    border-radius: 0;
    width: auto;
    text-decoration: none !important;
    background-color: transparent;
    color: inherit;
    position: relative;
    padding-right: 40px;
    min-height: 30px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Proxima Nova';
    font-weight: 900;
    text-align: left;
    font-size: 14px;

    // .icon {
    //     color: alpha($golden-fizz, 0.5);
    // }
}
.e-label:disabled {
    background: transparent;
    // color: alpha(white, 0.5);
}
.e-label span {
    display: block;
}
.e-label .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 30px;
    color: $golden-fizz;
}
.e-label .fa {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 30px;
    margin-top: -0.5em;
    color: $golden-fizz;
}
.e-label_xs {
    padding-right: 25px;
    min-height: 18px;
}
.e-label_xs .icon {
    font-size: 16px;
}


